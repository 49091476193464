<template>
  <div class="col-12">
    <div class="row mt-4">
      <div class="col-8 px-5">
        <h3> CORE Program </h3>
      </div>
      <hr class="horizontal dark" />
      <div class="col-8 px-5 mt-2">
        <h6 class="px-1">{{ $t("List of created Lines") }}</h6>
        <label class="text-sm"> {{ $t("Sort by") }}: &nbsp;</label>
        <select
          id="choices-category"
          v-model="order_by_attribute"
          name="order-by-choices"
          :class="`bg-transparent border-0 text-sm`"
        >
          <option
            v-for="order_by_option in order_by_options"
            :key="order_by_option"
            :value="order_by_option"
            class="text-dark text-sm"
          >
            <span class="d-sm-inline d-none">
              {{ $t(order_by_option) }}
            </span>
          </option>
        </select>
      </div>
      <div class="col-4 px-5 text-end mt-2">
        <div
          class="input-group"
          style="width: 250px; float: right"
        >
          <span class="input-group-text text-body">
            <i
              class="fas fa-search"
              aria-hidden="true"
            ></i>
          </span>
          <input
            v-model="search_keyword"
            type="text"
            class="form-control"
            :placeholder="$t('Search line here') + '...'"
          />
        </div>
      </div>
    </div>
    <div v-if="line_list.length">
      <br />
      <line-card-on-site
        v-for="line in OrderByAttribute(FilteredLineList)"
        :key="line.id"
        :line-name="line.name"
        :line-id="String(line.id)"
        :date-time="dateToString(line.created_at)"
        :line-layout="line.pistols_layout"
      />
    </div>
    <div v-else>
      <p>{{ $t("No Lines Created") }}.</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { orderBy } from "lodash";
import LineCardOnSite from "../../components/LineCardOnSite.vue";

export default {
  name: "LineListOnSite",
  components: {
    LineCardOnSite,
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      line_list: [],
      order_by_attribute: "Line Last Used Date",
      order_by_options: ["Name", "Line Creation Date", "Line Updated Date", "Line Last Used Date"],
      search_keyword: "",
    };
  },
  computed: {
    FilteredLineList: {
      get() {
        if (this.search_keyword == "") {
          return this.line_list;
        } else {
          const keyword = this.search_keyword.toLowerCase();
          return this.line_list.filter(line => line.name.toLowerCase().includes(keyword));
        }
      },
    },
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    }
  },
  mounted() {
    this.getLineList();
  },
  methods: {
    OrderByName(array) {
      return orderBy(array, [array => array.name.toLowerCase()], ["asc"]);
    },
    OrderByDateUpdated(array) {
      return orderBy(array, ["updated_at", "created_at"], ["desc"]);
    },
    OrderByLineUsedDate(array) {
      return orderBy(array, ["used_at", "created_at"], ["desc"]);
    },
    OrderByAttribute(array) {
      if (this.order_by_attribute == "Line Last Used Date") {
        return this.OrderByLineUsedDate(array);
      } else if (this.order_by_attribute == "Name") {
        return this.OrderByName(array);
      } else if (this.order_by_attribute == "Line Updated Date") {
        return this.OrderByDateUpdated(array);
      } else if (this.order_by_attribute == "Line Creation Date") {
        return this.OrderByDateCreated(array);
      }
    },
    OrderByDateCreated(array) {
      return orderBy(array, ["created_at"], ["desc"]);
    },
    getLineList() {
      axios
        .get("/api/v1/fp/line/")
        .then(response => {
          this.line_list = response.data;
          this.$store.state.isLoading = false;
        })
        .catch(error => {
          console.error(error);
          setTimeout(
            function () {
              this.getLineList();
            }.bind(this),
            5000,
          );
        });
    },

    dateToString(date) {
      const date_object = new Date(date);
      if (this.$store.state.user_data.unit_system == "metric") {
        return date_object.toLocaleDateString("en-GB");
      } else if (this.$store.state.user_data.unit_system == "imperial") {
        return date_object.toLocaleDateString("en-US");
      } else {
        return null;
      }
    },
  },
};
</script>

<i18n>
  {
    "EN": {
        "Lines": "Lines",
        "List of created Lines": "List of created Lines",
        "No Lines Created": "No Lines Created",
        "New Line": "New Line",
        "Sort by": "Sort by",
        "Name": "Name",
        "Line Creation Date": "Line Creation Date",
        "Line Updated Date": "Line Updated Date",
        "Line Last Used Date": "Line Last Used Date",
        "Search line here": "Search line here",
        "Are you sure you want to delete Line": "Are you sure you want to delete Line",
        "You will not be able to revert this!": "You will not be able to revert this!",
        "Yes, delete it": "Yes, delete it",
        "No, cancel": "No, cancel",
        "Deleted": "Deleted",
        "Your line has been deleted.": "Your line has been deleted.",
    },
    "DE": {
        "Lines": "Anlagen",
        "List of created Lines": "Liste der erstellten Linien",
        "No Lines Created": "Keine Linien erstellt",
        "New Line": "Neue Linie",
        "Sort by": "Sortieren nach",
        "Name": "Name",
        "Line Creation Date": "Linien Erstellungsdatum",
        "Line Updated Date": "Linien Aktualisierungsdatum",
        "Line Last Used Date": "Zeile Zuletzt verwendetes Datum",
        "Search line here": "Suche Linie",
        "Are you sure you want to delete Line": "Sind Sie sicher, dass Sie die Linie löschen möchten",
        "You will not be able to revert this!": "Sie können dies nicht rückgängig machen!",
        "Yes, delete it": "Ja, lösche es",
        "No, cancel": "Nein, abbrechen",
        "Deleted": "Gelöscht",
        "Your line has been deleted.": "Ihre Linie wurde gelöscht.",
    },
    "ES": {
        "Lines": "Líneas",
        "List of created Lines": "Lista de líneas creadas",
        "No Lines Created": "No existe ninguna línea",
        "New Line": "Nueva línea",
        "Sort by": "Ordenar por",
        "Name": "Nombre",
        "Line Creation Date": "Fecha de creación",
        "Line Updated Date": "Fecha de actualización",
        "Line Last Used Date": "Fecha de último uso",
        "Search line here": "Buscar línea",
        "Are you sure you want to delete Line": "¿Estás seguro de que quieres eliminar la línea",
        "You will not be able to revert this!": "¡No podrás deshacer esto!",
        "Yes, delete it": "Sí, bórrala",
        "No, cancel": "No, cancelar",
        "Deleted": "Eliminada",
        "Your line has been deleted.": "Tu línea ha sido eliminada.",
    },
    "FR": {
        "Lines": "Lignes",
        "List of created Lines": "Liste des lignes créées",
        "No Lines Created": "Aucune ligne créée",
        "New Line": "Nouvelle ligne",
        "Sort by": "Trier par",
        "Name": "Nom",
        "Line Creation Date": "Date de création",
        "Line Updated Date": "Date de mise à jour",
        "Line Last Used Date": "Date de la dernière utilisation",
        "Search line here": "Rechercher une ligne",
        "Are you sure you want to delete Line": "Voulez-vous vraiment supprimer la ligne",
        "You will not be able to revert this!": "Vous ne pourrez pas revenir en arrière!",
        "Yes, delete it": "Oui, supprimez-le",
        "No, cancel": "Non, annuler",
        "Deleted": "Supprimé",
        "Your line has been deleted.": "Votre ligne a été supprimée.",
    },
    "IT": {
        "Lines": "Linee",
        "List of created Lines": "Elenco delle linee create",
        "No Lines Created": "Nessuna linea creata Per creare una linea, contattaci a",
        "New Line": "Nuova linea",
        "Sort by": "Ordina per",
        "Name": "Nome",
        "Line Creation Date": "Data di creazione",
        "Line Updated Date": "Data di aggiornamento",
        "Line Last Used Date": "Linea Data ultimo utilizzo",
        "Search line here": "Cerca linea",
        "Are you sure you want to delete Line": "Sei sicuro di voler eliminare la linea",
        "You will not be able to revert this!": "Non sarai in grado di annullare questa operazione!",
        "Yes, delete it": "Sì, cancellalo",
        "No, cancel": "No, annulla",
        "Deleted": "Eliminato",
        "Your line has been deleted.": "La tua linea è stata eliminata.",
    },
    "PL": {
        "Lines": "Linie",
        "List of created Lines": "Lista utworzonych linii",
        "No Lines Created": "Nie utworzono żadnych linii Aby utworzyć linię, skontaktuj się z nami pod adresem",
        "New Line": "Nowa linia",
        "Sort by": "Sortuj według",
        "Name": "Nazwa",
        "Line Creation Date": "Data utworzenia",
        "Line Updated Date": "Data aktualizacji",
        "Line Last Used Date": "Linia Data ostatniego użycia",
        "Search line here": "Szukaj linii",
        "Are you sure you want to delete Line": "Czy na pewno chcesz usunąć linię",
        "You will not be able to revert this!": "Nie będziesz mógł tego cofnąć!",
        "Yes, delete it": "Tak, usuń to",
        "No, cancel": "Nie, anuluj",
        "Deleted": "Usunięty",
        "Your line has been deleted.": "Twoja linia została usunięta.",
    },
    "TR": {
        "Lines": "Hatlar",
        "List of created Lines": "Oluşturulan Hatların Listesi",
        "No Lines Created": "Hat oluşturulmadı Bir hat oluşturmak için bizimle iletişime geçin",
        "New Line": "Yeni Hat",
        "Sort by": "Göre sırala",
        "Name": "İsim",
        "Line Creation Date": "Hat Oluşturma Tarihi",
        "Line Updated Date": "Hat Güncelleme Tarihi",
        "Line Last Used Date": "Hat Son Kullanım Tarihi",
        "Search line here": "Hat ara",
        "Are you sure you want to delete Line": "Hatı silmek istediğinizden emin misiniz",
        "You will not be able to revert this!": "Bunu geri alamayacaksın!",
        "Yes, delete it": "Evet, sil",
        "No, cancel": "Hayır, iptal et",
        "Deleted": "Silindi",
        "Your line has been deleted.": "Hatınız silindi.",
    },
    "ZH": {
        "Lines": "线",
        "List of created Lines": "创建的线列表",
        "No Lines Created": "未创建线。要创建线，请联系我们",
        "New Line": "新线",
        "Sort by": "排序方式",
        "Name": "名称",
        "Line Creation Date": "创建日期",
        "Line Updated Date": "更新日期",
        "Line Last Used Date": "行 最后使用日期",
        "Search line here": "搜索线",
        "Are you sure you want to delete Line": "您确定要删除该行吗",
        "You will not be able to revert this!": "您将无法撤消此操作！",
        "Yes, delete it": "是的，删除它",
        "No, cancel": "不，取消",
        "Deleted": "已删除",
        "Your line has been deleted.": "您的线已被删除。",
    },
  }
</i18n>
