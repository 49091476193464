<template>
  <div class="py-1 container-fluid">
    <div class="row">
      <div class="col-12 ms-auto">
        <div class="card">
          <div class="card-body pt-4 mb-0">
            <div v-if="OnsiteFrontend">
              <h5 class="font-weight-bolder">Calibrated Spray Pattern Details</h5>
            </div>
            <div v-else>
              <h5 class="font-weight-bolder">{{ $t("Powder Model Details") }}</h5>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="mt-2 mb-2 h6">{{ OnsiteFrontend? "Pattern name":$t("Name") }}</label>
                <div class="mt-2 mb-2 mx-2">
                  {{ powdermodel.name }}
                </div>
              </div>
              <div v-if="OnsiteFrontend" class="col-4">
                <label class="mt-2 mb-2 h6">
                  Powder name
                </label>
                <div class="mt-2 mb-2 mx-2">
                  {{ powder.name }}
                </div>
              </div>
              <div v-else class="col-4">
                <label class="mt-2 mb-2 h6">
                  {{ $t("Calibration type") }}
                </label>
                <div class="mt-2 mb-2 mx-2">
                  {{ capitalize(CalibrationType) }}
                </div>
              </div>
              <div
                v-if="isCalibratedPowderModel"
                class="col-4"
              >
                <label class="mt-2 mb-2 h6">{{ $t("Calibrated line") }}</label>
                <div class="mt-2 mb-2 mx-2">
                  {{ line.name }}
                </div>
              </div>
              <div
                v-else-if="isGeneralPreCalibratedPowderModel"
                class="col-4"
              >
                <label class="mt-2 mb-2">{{ $t("Powder Model Type") }}</label>
                <div class="mt-2 mb-2 mx-2">
                  {{ $t("Pre-Calibrated Powder Model") }}
                </div>
              </div>
            </div>
            <div
              v-if="isCalibratedPowderModel"
              class="accordion"
            >
              <div class="accordion-item mb-0">
                <h5 id="panelsStayOpen-headingOne">
                  <button
                    class="accordion-button collapsed mb-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseParams"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseParams"
                    style="padding: 0"
                  >
                    <p
                      class="font-weight-bolder mt-2"
                      style="font-weight: 700 !important; font-size: 1.05rem; vertical-align: middle; margin-bottom: 0"
                    >
                      {{ $t("Calibration Parameters") }}
                      <i
                        class="fa fa-chevron-down"
                        aria-hidden="true"
                      ></i>
                      <i
                        class="fa fa-chevron-up"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </button>
                </h5>
                <div
                  id="panelsStayOpen-collapseParams"
                  class="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div
                    class="accordion-body"
                    style="padding: 0"
                  >
                    <div class="row">
                      <div
                        v-if="!isAdvancedCalibrationPowderModel && LoadedCalibrationMeasures"
                        class="col-4"
                      >
                        <label class="mt-2 mb-2">
                          {{ $t("Pistol to substrate distance") }}
                        </label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}]
                        </div>
                      </div>
                      <div
                        v-if="isAdvancedCalibrationPowderModel"
                        class="col-4"
                      >
                        <label class="mt-2 mb-2">
                          {{ $t("MIN") }} & {{ $t("MAX") }} {{ $t("Pistol to substrate distance") }}
                        </label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ minPistolToSubstrateDistance }} & {{ maxPistolToSubstrateDistance }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}]
                        </div>
                      </div>
                      <div
                        v-if="!isAdvancedCalibrationPowderModel && LoadedCalibrationMeasures"
                        class="col-4"
                      >
                        <label class="mt-2 mb-2">
                          {{ LoadedLine ? `${line.air_parameter_name} ${line.air_parameter_units}` : "Air parameter" }}
                        </label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ powdermodel_calibration_measures.max_air_parameter_measurements }}
                          {{ line.air_parameter_units }}
                        </div>
                      </div>
                      <div
                        v-else
                        class="col-4"
                      >
                        <label class="mt-2 mb-2">
                          {{ LoadedLine ? `Min and Max ${line.air_parameter_name}` : "Air parameter" }}
                        </label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ powdermodel_calibration_measures.min_air_parameter_measurements }} &
                          {{ powdermodel_calibration_measures.max_air_parameter_measurements }}
                          {{ line.air_parameter_units }}
                        </div>
                      </div>
                      <div class="col-4">
                        <label class="mt-2 mb-2">{{ $t("Line speed") }}</label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ calibrationLineSpeed }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].velocity_minutes
                          }}]
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-4">
                        <label class="mt-2 MB-2">
                          {{ $t("Powder Amount") }}
                          <i
                            :title="$t('Quantity of powder dispensed by the gun per unit time')"
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                        </label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ powderAmount }}
                        </div>
                      </div>
                      <div class="col-4">
                        <label class="mt-2 MB-2">{{ $t("Nozzle Type") }}</label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ nozzleType }}
                        </div>
                      </div>
                      <div class="col-4">
                        <label class="mt-2 MB-2">{{ $t("Booth side used for calibration") }}</label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ $t(boothSide) }}
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-4">
                        <label class="mt-2 MB-2">{{ $t("Powder Manufacturer") }}</label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ powderManufacturer }}
                        </div>
                      </div>
                      <div class="col-4">
                        <label class="mt-2 MB-2">
                          {{ powderLabel }}
                        </label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ powderArticleId }}
                        </div>
                      </div>
                      <div
                        v-if="this.$store.state.flightpath_pro_license"
                        class="col-4"
                      >
                        <label class="mt-2 MB-2">{{ $t("Batch ID") }}</label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ batchId }}
                        </div>
                      </div>
                      <div
                        v-else
                        class="col-4"
                      >
                        <label class="mt-2 MB-2">{{ $t("Additional Info") }}</label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ additionalInfo }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="this.$store.state.flightpath_pro_license"
                      class="row mt-2"
                    >
                      <div class="col-4">
                        <label class="mt-2 MB-2">{{ $t("Booth Temperature") }}</label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ boothTemperature }}
                          {{ $store.state.units_system[$store.state.user_data.unit_system].temperature }}
                        </div>
                      </div>
                      <div class="col-4">
                        <label class="mt-2 MB-2">{{ $t("Booth Humidity") }}</label>
                        <div class="mt-2 mb-2 mx-2">{{ boothHumidity }} %</div>
                      </div>
                      <div class="col-4">
                        <label class="mt-2 MB-2">{{ $t("Guns Spacing") }}</label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ $t(gunsSpacing) }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="this.$store.state.flightpath_pro_license"
                      class="row mt-2"
                    >
                      <div class="col-4">
                        <label class="mt-2 MB-2">{{ operationalHoursLabel }}</label>
                        <div class="mt-2 mb-2 mx-2">{{ pumpOperationalHours }} {{ $t("hours") }}</div>
                      </div>
                      <div class="col-8">
                        <label class="mt-2 MB-2">{{ $t("Additional Info") }}</label>
                        <div class="mt-2 mb-2 mx-2">
                          {{ additionalInfo }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item mb-0">
                <h5 id="panelsStayOpen-headingOne">
                  <button
                    class="accordion-button collapsed mb-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseMeasurements"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseMeasurements"
                    style="padding: 0"
                  >
                    <p
                      class="font-weight-bolder"
                      style="font-weight: 700 !important; font-size: 1.05rem; vertical-align: middle; margin-bottom: 0"
                    >
                      {{ $t("Calibration Measurements") }}
                      <i
                        class="fa fa-chevron-down"
                        aria-hidden="true"
                      ></i>
                      <i
                        class="fa fa-chevron-up"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </button>
                </h5>
                <div
                  id="panelsStayOpen-collapseMeasurements"
                  class="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div
                    class="accordion-body"
                    style="padding: 0"
                  >
                    <div
                      v-if="!isStaticCalibrationPowderModel"
                      class="row"
                    >
                      <h6>{{ $t("Experiment") }} 1</h6>
                      <div class="table-responsive w-90">
                        <table class="table table-sm table-bordered mt-1 mb-4">
                          <tbody>
                            <tr>
                              <th>{{ $t("Point") }} 1</th>
                              <th>{{ $t("Point") }} 2</th>
                              <th>{{ $t("Point") }} 3</th>
                              <th>{{ $t("Point") }} 4</th>
                              <th>{{ $t("Point") }} 5</th>
                              <th>{{ $t("Point") }} 6</th>
                              <th>{{ $t("Point") }} 7</th>
                            </tr>
                            <tr>
                              <td style="border-width: 1px">
                                {{ experiment1Point1 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ experiment1Point2 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ experiment1Point3 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ experiment1Point4 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ experiment1Point5 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ experiment1Point6 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ experiment1Point7 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <h6>{{ $t("Experiment") }} 2</h6>
                      <div class="table-responsive w-90">
                        <table class="table table-sm table-bordered mt-1 mb-4">
                          <tbody>
                            <tr>
                              <th>{{ $t("Point") }} 1</th>
                              <th>{{ $t("Point") }} 2</th>
                              <th>{{ $t("Point") }} 3</th>
                              <th>{{ $t("Point") }} 4</th>
                              <th>{{ $t("Point") }} 5</th>
                              <th>{{ $t("Point") }} 6</th>
                              <th>{{ $t("Point") }} 7</th>
                            </tr>
                            <tr>
                              <td style="border-width: 1px">
                                {{ experiment2Point1 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ experiment2Point2 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ experiment2Point3 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ experiment2Point4 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ experiment2Point5 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ experiment2Point6 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ experiment2Point7 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-if="isAdvancedCalibrationPowderModel">
                        <h6>{{ $t("Experiment") }} 3</h6>
                        <div class="table-responsive w-90">
                          <table class="table table-sm table-bordered mt-1 mb-4">
                            <tbody>
                              <tr>
                                <th>{{ $t("Point") }} 1</th>
                                <th>{{ $t("Point") }} 2</th>
                                <th>{{ $t("Point") }} 3</th>
                                <th>{{ $t("Point") }} 4</th>
                                <th>{{ $t("Point") }} 5</th>
                                <th>{{ $t("Point") }} 6</th>
                                <th>{{ $t("Point") }} 7</th>
                              </tr>
                              <tr>
                                <td style="border-width: 1px">
                                  {{ experiment3Point1 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment3Point2 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment3Point3 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment3Point4 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment3Point5 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment3Point6 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment3Point7 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <h6>{{ $t("Experiment") }} 4</h6>
                        <div class="table-responsive w-90">
                          <table class="table table-sm table-bordered mt-1 mb-4">
                            <tbody>
                              <tr>
                                <th>{{ $t("Point") }} 1</th>
                                <th>{{ $t("Point") }} 2</th>
                                <th>{{ $t("Point") }} 3</th>
                                <th>{{ $t("Point") }} 4</th>
                                <th>{{ $t("Point") }} 5</th>
                                <th>{{ $t("Point") }} 6</th>
                                <th>{{ $t("Point") }} 7</th>
                              </tr>
                              <tr>
                                <td style="border-width: 1px">
                                  {{ experiment4Point1 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment4Point2 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment4Point3 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment4Point4 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment4Point5 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment4Point6 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment4Point7 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <h6>{{ $t("Experiment") }} 5</h6>
                        <div class="table-responsive w-90">
                          <table class="table table-sm table-bordered mt-1 mb-4">
                            <tbody>
                              <tr>
                                <th>{{ $t("Point") }} 1</th>
                                <th>{{ $t("Point") }} 2</th>
                                <th>{{ $t("Point") }} 3</th>
                                <th>{{ $t("Point") }} 4</th>
                                <th>{{ $t("Point") }} 5</th>
                                <th>{{ $t("Point") }} 6</th>
                                <th>{{ $t("Point") }} 7</th>
                              </tr>
                              <tr>
                                <td style="border-width: 1px">
                                  {{ experiment5Point1 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment5Point2 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment5Point3 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment5Point4 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment5Point5 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment5Point6 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment5Point7 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <h6>{{ $t("Experiment") }} 6</h6>
                        <div class="table-responsive w-90">
                          <table class="table table-sm table-bordered mt-1 mb-4">
                            <tbody>
                              <tr>
                                <th>{{ $t("Point") }} 1</th>
                                <th>{{ $t("Point") }} 2</th>
                                <th>{{ $t("Point") }} 3</th>
                                <th>{{ $t("Point") }} 4</th>
                                <th>{{ $t("Point") }} 5</th>
                                <th>{{ $t("Point") }} 6</th>
                                <th>{{ $t("Point") }} 7</th>
                              </tr>
                              <tr>
                                <td style="border-width: 1px">
                                  {{ experiment6Point1 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment6Point2 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment6Point3 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment6Point4 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment6Point5 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment6Point6 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment6Point7 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <h6>{{ $t("Experiment") }} 7</h6>
                        <div class="table-responsive w-90">
                          <table class="table table-sm table-bordered mt-1 mb-4">
                            <tbody>
                              <tr>
                                <th>{{ $t("Point") }} 1</th>
                                <th>{{ $t("Point") }} 2</th>
                                <th>{{ $t("Point") }} 3</th>
                                <th>{{ $t("Point") }} 4</th>
                                <th>{{ $t("Point") }} 5</th>
                                <th>{{ $t("Point") }} 6</th>
                                <th>{{ $t("Point") }} 7</th>
                              </tr>
                              <tr>
                                <td style="border-width: 1px">
                                  {{ experiment7Point1 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment7Point2 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment7Point3 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment7Point4 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment7Point5 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment7Point6 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment7Point7 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <h6>{{ $t("Experiment") }} 8</h6>
                        <div class="table-responsive w-90">
                          <table class="table table-sm table-bordered mt-1 mb-4">
                            <tbody>
                              <tr>
                                <th>{{ $t("Point") }} 1</th>
                                <th>{{ $t("Point") }} 2</th>
                                <th>{{ $t("Point") }} 3</th>
                                <th>{{ $t("Point") }} 4</th>
                                <th>{{ $t("Point") }} 5</th>
                                <th>{{ $t("Point") }} 6</th>
                                <th>{{ $t("Point") }} 7</th>
                              </tr>
                              <tr>
                                <td style="border-width: 1px">
                                  {{ experiment8Point1 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment8Point2 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment8Point3 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment8Point4 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment8Point5 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment8Point6 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                                <td style="border-width: 1px">
                                  {{ experiment8Point7 }} [{{
                                    $store.state.units_system[$store.state.user_data.unit_system].thickness
                                  }}]
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="row"
                    >
                      <h6>{{ $t("Calibration Plate Measurements") }}</h6>
                      <div class="table-responsive w-90">
                        <table class="table table-sm table-bordered mt-1 mb-4">
                          <tbody>
                            <tr>
                              <th>{{ $t("Point") }} 1</th>
                              <th>{{ $t("Point") }} 2</th>
                              <th>{{ $t("Point") }} 3</th>
                              <th>{{ $t("Point") }} 4</th>
                              <th>{{ $t("Point") }} 5</th>
                            </tr>
                            <td style="border-width: 1px">
                              {{ measurement1 }} [{{
                                $store.state.units_system[$store.state.user_data.unit_system].thickness
                              }}]
                            </td>
                            <td style="border-width: 1px">
                              {{ measurement2 }} [{{
                                $store.state.units_system[$store.state.user_data.unit_system].thickness
                              }}]
                            </td>
                            <td style="border-width: 1px">
                              {{ measurement3 }} [{{
                                $store.state.units_system[$store.state.user_data.unit_system].thickness
                              }}]
                            </td>
                            <td style="border-width: 1px">
                              {{ measurement4 }} [{{
                                $store.state.units_system[$store.state.user_data.unit_system].thickness
                              }}]
                            </td>
                            <td style="border-width: 1px">
                              {{ measurement5 }} [{{
                                $store.state.units_system[$store.state.user_data.unit_system].thickness
                              }}]
                            </td>
                            <tr>
                              <th>{{ $t("Point") }} 6</th>
                              <th>{{ $t("Point") }} 7</th>
                              <th>{{ $t("Point") }} 8</th>
                              <th>{{ $t("Point") }} 9</th>
                              <th>{{ $t("Point") }} 10</th>
                            </tr>
                            <tr>
                              <td style="border-width: 1px">
                                {{ measurement6 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement7 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement8 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement9 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement10 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                            </tr>
                            <tr>
                              <th>{{ $t("Point") }} 11</th>
                              <th>{{ $t("Point") }} 12</th>
                              <th>{{ $t("Point") }} 13</th>
                              <th>{{ $t("Point") }} 14</th>
                              <th>{{ $t("Point") }} 15</th>
                            </tr>
                            <tr>
                              <td style="border-width: 1px">
                                {{ measurement11 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement12 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement13 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement14 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement15 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                            </tr>
                            <tr>
                              <th>{{ $t("Point") }} 16</th>
                              <th>{{ $t("Point") }} 17</th>
                              <th>{{ $t("Point") }} 18</th>
                              <th>{{ $t("Point") }} 19</th>
                              <th>{{ $t("Point") }} 20</th>
                            </tr>
                            <tr>
                              <td style="border-width: 1px">
                                {{ measurement16 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement17 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement18 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement19 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement20 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                            </tr>
                            <tr>
                              <th>{{ $t("Point") }} 21</th>
                              <th>{{ $t("Point") }} 22</th>
                              <th>{{ $t("Point") }} 23</th>
                              <th>{{ $t("Point") }} 24</th>
                              <th>{{ $t("Point") }} 25</th>
                            </tr>
                            <tr>
                              <td style="border-width: 1px">
                                {{ measurement21 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement22 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement23 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement24 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                              <td style="border-width: 1px">
                                {{ measurement25 }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].thickness
                                }}]
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="card">
        <div class="card-header text-center">
          <h5 class="font-weight-bolder mb-0 pb-0">{{ $t("PowderModel visualization") }}</h5>
        </div>
        <div class="card-body pt-0 d-flex justify-content-center">
          <div class="mr-4">
            <canvas
              id="powdermodelcanvas"
              class="w-100"
              width="500"
              height="500"
              style="max-width: 500px"
            ></canvas>
            <div class="row">
              <label>
                {{ $t("The scale of this simulation is") }} {{ lastSimulatedPowderDiameter }}x{{
                  lastSimulatedPowderDiameter
                }}
                [{{ $store.state.units_system[$store.state.user_data.unit_system].distance }}]</label
              >
            </div>
          </div>
          <div class="mx-4 col-3">
            <label class="h6">
              {{ $t("Visualization size") }} &nbsp; [{{
                $store.state.units_system[$store.state.user_data.unit_system].distance
              }}]
            </label>
            <input
              v-model="simulatedPowderDiameter"
              class="form-control"
              type="number"
              step="0.1"
              @keydown="numericOnly"
            />
            <div v-if="isAdvancedCalibrationPowderModel">
              <div class="mb-4">
                <label
                  for="range-1"
                  class="form-label"
                  >{{ $t("Pistol to substrate distance") }}: {{ pistolToSubstrateDistance }} [{{
                    $store.state.units_system[$store.state.user_data.unit_system].distance
                  }}]</label
                >
                <input
                  id="range-1"
                  v-model.lazy="pistolToSubstrateDistance"
                  type="range"
                  class="form-range"
                  step="0.1"
                  :max="maxPistolToSubstrateDistance"
                  :min="minPistolToSubstrateDistance"
                  :disabled="disable_ranges"
                />
              </div>
              <div>
                <label
                  for="range-2"
                  class="form-label"
                  >{{ $t("Air parameter") }}: {{ powdermodel_visulization_params_form.air_parameter_value }}
                  {{ powdermodel.air_parameter_units }}</label
                >
                <input
                  id="range-2"
                  v-model="powdermodel_visulization_params_form.air_parameter_value"
                  type="range"
                  class="form-range"
                  step="0.01"
                  :max="powdermodel_visulization_params_form.max_air_parameter_value"
                  :min="powdermodel_visulization_params_form.min_air_parameter_value"
                  :disabled="disable_ranges"
                />
              </div>
            </div>
            <p v-if="isSimpleCalibrationPowderModel || isStaticCalibrationPowderModel">
              {{ $t("Distance and Air sliders only available for advanced powder models.") }}
            </p>
            <div
              v-if="PowderModelVisParamsUpdatedForm && !disable_ranges"
              class="text-right mt-3"
              style="float: right !important"
            >
              <button
                type="button"
                class="mt-2 mb-0 btn bg-gradient-success"
                @click="drawDistribution"
              >
                {{ $t("Compute") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 ms-auto my-3 text-center">
          <div
            v-if="powdermodelType == 'calibrated'"
            class="d-flex justify-content-center gap-8"
          >
            <div v-if="!OnsiteFrontend">
              <button
                type="button"
                class="mt-2 mb-0 btn bg-gradient-success"
                style="width: 300px; height: 60px"
                @click="
                  $store.state.isLoading = true;
                  routeToNewOptimization();
                "
              >
                {{ $t("Continue to Create Optimization") }}
              </button>
            </div>
            <button
              type="button"
              class="mt-2 mb-0 btn bg-gradient-success"
              style="width: 300px; height: 60px"
              @click="$router.go(-1);"
            >
            <i class="fa fa-undo px-1" :style="`scale: 1`"></i>
              {{ $t("Go Back") }}
            </button>
            <button
              type="button"
              class="mt-2 mb-0 btn bg-gradient-success"
              style="width: 300px; height: 60px"
              @click="$router.push('/')"
            >
              <i class="fa fa-home px-1"></i>
              Go Home
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "PowderModelDetail",
  components: {},
  props: {
    powdermodelId: {
      type: String,
      default: null,
    },
    powdermodelType: {
      type: String,
      default: null,
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      powdermodel: [],
      powdermodel_calibration_measures: [],
      line: {name: ""},
      visit: {name: ""},
      powder: {name: ""},
      powdermodelcanvas: null,
      distribution_imgData: null,
      disable_ranges: false,
      powdermodel_visulization_params_form: {
        simulated_spray_diameter: 60,
        min_pistol_to_substrate_distance: 10,
        max_pistol_to_substrate_distance: 60,
        pistol_to_substrate_distance: 20,
        min_air_parameter_value: 1,
        max_air_parameter_value: 10,
        air_parameter_value: 2,
      },
      last_powdermodel_visualization_params: {
        simulated_spray_diameter: 60,
        min_pistol_to_substrate_distance: 10,
        max_pistol_to_substrate_distance: 60,
        pistol_to_substrate_distance: 20,
        min_air_parameter_value: 1,
        max_air_parameter_value: 10,
        air_parameter_value: 2,
      },
      max_thickness_metric_decimals: 0,
      max_distance_metric_decimals: 1,
      max_line_speed_metric_decimals: 1,
      max_imperial_decimals: 2,
      max_temperature_decimals: 1,
    };
  },

  computed: {
    isSimpleCalibrationPowderModel() {
      return this.powdermodel_calibration_measures.calibration_type === "simple";
    },
    isStaticCalibrationPowderModel() {
      return this.powdermodel_calibration_measures.calibration_type === "static";
    },
    isAdvancedCalibrationPowderModel() {
      return this.powdermodel_calibration_measures.calibration_type === "advanced";
    },
    OnsiteFrontend() {
      return window.location.pathname.includes("onsite");
    },
    CalibrationType() {
      if (this.powdermodelType == "generalprecalibrated") {
        return this.$t("Pre-Calibrated Powder Model");
      } else if (this.isSimpleCalibrationPowderModel) {
        return this.$t("simple");
      } else if (this.isStaticCalibrationPowderModel) {
        return this.$t("static");
      } else if (this.isAdvancedCalibrationPowderModel) {
        return this.$t("advanced");
      } else {
        return "";
      }
    },
    isCalibratedPowderModel() {
      return this.powdermodelType === "calibrated";
    },
    isGeneralPreCalibratedPowderModel() {
      return this.powdermodelType === "generalprecalibrated";
    },
    LoadedCalibrationMeasures() {
      return Object.prototype.hasOwnProperty.call(this.powdermodel_calibration_measures, "powdermodel");
    },
    LoadedLine: function () {
      return Object.prototype.hasOwnProperty.call(this.line, "id");
    },
    PowderModelVisParamsUpdatedForm: function () {
      if (
        JSON.stringify(this.last_powdermodel_visualization_params) !==
        JSON.stringify(this.powdermodel_visulization_params_form)
      ) {
        this.fillCanvasBlue();
        return true;
      } else {
        if (this.distribution_imgData != null) {
          this.powdermodelcanvas.putImageData(this.distribution_imgData, 0, 0);
        }
        return false;
      }
    },
    powderManufacturer: {
      get() {
        if (
          this.powdermodel_calibration_measures.powder_manufacturer == null ||
          this.powdermodel_calibration_measures.powder_manufacturer == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.powder_manufacturer;
        }
      },
    },
    powderAmount: {
      get() {
        if (
          this.powdermodel_calibration_measures.powder_amount == null ||
          this.powdermodel_calibration_measures.powder_amount == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.powder_amount;
        }
      },
    },
    powderArticleId: {
      get() {
        if (
          this.powdermodel_calibration_measures.powder_article_id == null ||
          this.powdermodel_calibration_measures.powder_article_id == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.powder_article_id;
        }
      },
    },
    boothSide: {
      get() {
        if (
          this.powdermodel_calibration_measures.booth_side == null ||
          this.powdermodel_calibration_measures.booth_side == ""
        ) {
          return "-";
        } else {
          return `${this.powdermodel_calibration_measures.booth_side} booth side`;
        }
      },
    },
    boothTemperature: {
      get() {
        if (
          this.powdermodel_calibration_measures.booth_temperature == null ||
          this.powdermodel_calibration_measures.booth_temperature == ""
        ) {
          return "-";
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.booth_temperature * this.$store.state.conversion_factors.c_to_f +
              this.$store.state.conversion_factors.c_to_f_offset
            ).toFixed(this.max_temperature_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.booth_temperature.toFixed(this.max_temperature_decimals),
          );
        } else {
          return null;
        }
      },
    },
    boothHumidity: {
      get() {
        if (
          this.powdermodel_calibration_measures.booth_humidity == null ||
          this.powdermodel_calibration_measures.booth_humidity == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.booth_humidity;
        }
      },
    },
    batchId: {
      get() {
        if (
          this.powdermodel_calibration_measures.batch_id == null ||
          this.powdermodel_calibration_measures.batch_id == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.batch_id;
        }
      },
    },
    gunsSpacing: {
      get() {
        if (
          this.powdermodel_calibration_measures.guns_spacing == null ||
          this.powdermodel_calibration_measures.guns_spacing == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.guns_spacing;
        }
      },
    },
    pumpOperationalHours: {
      get() {
        if (
          this.powdermodel_calibration_measures.pump_operational_hours == null ||
          this.powdermodel_calibration_measures.pump_operational_hours == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.pump_operational_hours;
        }
      },
    },
    nozzleType: {
      get() {
        if (
          this.powdermodel_calibration_measures.nozzles_type == null ||
          this.powdermodel_calibration_measures.nozzles_type == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.nozzles_type;
        }
      },
    },
    additionalInfo: {
      get() {
        if (
          this.powdermodel_calibration_measures.additional_info == null ||
          this.powdermodel_calibration_measures.additional_info == ""
        ) {
          return "-";
        } else {
          return this.powdermodel_calibration_measures.additional_info;
        }
      },
    },
    calibrationLineSpeed: {
      get() {
        if (this.isStaticCalibrationPowderModel) {
          return 0;
        }
        if (
          this.powdermodel_calibration_measures.calibration_line_speed == null ||
          this.powdermodel_calibration_measures.calibration_line_speed == ""
        ) {
          return "-";
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.calibration_line_speed *
              this.$store.state.conversion_factors.m_to_ft
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.calibration_line_speed.toFixed(this.max_line_speed_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.calibration_line_speed = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.calibration_line_speed =
              value / this.$store.state.conversion_factors.m_to_ft;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.calibration_line_speed = value;
          } else {
            this.powdermodel_calibration_measures.calibration_line_speed = null;
          }
        }
      },
    },
    maxPistolToSubstrateDistance: {
      get() {
        if (
          this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance == null ||
          this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance == ""
        ) {
          return this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance *
              this.$store.state.conversion_factors.cm_to_in
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance.toFixed(
              this.max_distance_metric_decimals,
            ),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance =
              value / this.$store.state.conversion_factors.cm_to_in;
          } else {
            this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance = value;
          }
        }
      },
    },
    minPistolToSubstrateDistance: {
      get() {
        if (
          this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance == null ||
          this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance == ""
        ) {
          return this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance *
              this.$store.state.conversion_factors.cm_to_in
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance.toFixed(
              this.max_distance_metric_decimals,
            ),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance =
              value / this.$store.state.conversion_factors.cm_to_in;
          } else {
            this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance = value;
          }
        }
      },
    },
    simulatedPowderDiameter: {
      get() {
        if (
          this.powdermodel_visulization_params_form.simulated_spray_diameter == null ||
          this.powdermodel_visulization_params_form.simulated_spray_diameter == ""
        ) {
          return this.powdermodel_visulization_params_form.simulated_spray_diameter;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_visulization_params_form.simulated_spray_diameter *
              this.$store.state.conversion_factors.cm_to_in
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_visulization_params_form.simulated_spray_diameter.toFixed(
              this.max_distance_metric_decimals,
            ),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_visulization_params_form.simulated_spray_diameter = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_visulization_params_form.simulated_spray_diameter =
              value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_visulization_params_form.simulated_spray_diameter = value;
          } else {
            this.powdermodel_visulization_params_form.simulated_spray_diameter = null;
          }
        }
      },
    },
    lastSimulatedPowderDiameter: {
      get() {
        if (
          this.last_powdermodel_visualization_params.simulated_spray_diameter == null ||
          this.last_powdermodel_visualization_params.simulated_spray_diameter == ""
        ) {
          return this.last_powdermodel_visualization_params.simulated_spray_diameter;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.last_powdermodel_visualization_params.simulated_spray_diameter *
              this.$store.state.conversion_factors.cm_to_in
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.last_powdermodel_visualization_params.simulated_spray_diameter.toFixed(
              this.max_distance_metric_decimals,
            ),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.last_powdermodel_visualization_params.simulated_spray_diameter = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.last_powdermodel_visualization_params.simulated_spray_diameter =
              value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.last_powdermodel_visualization_params.simulated_spray_diameter = value;
          } else {
            this.last_powdermodel_visualization_params.simulated_spray_diameter = null;
          }
        }
      },
    },
    pistolToSubstrateDistance: {
      get() {
        if (
          this.powdermodel_visulization_params_form.pistol_to_substrate_distance == null ||
          this.powdermodel_visulization_params_form.pistol_to_substrate_distance == ""
        ) {
          return this.powdermodel_visulization_params_form.pistol_to_substrate_distance;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            parseFloat(
              this.powdermodel_visulization_params_form.pistol_to_substrate_distance *
                this.$store.state.conversion_factors.cm_to_in,
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            parseFloat(this.powdermodel_visulization_params_form.pistol_to_substrate_distance).toFixed(
              this.max_distance_metric_decimals,
            ),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_visulization_params_form.pistol_to_substrate_distance = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_visulization_params_form.pistol_to_substrate_distance =
              value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_visulization_params_form.pistol_to_substrate_distance = value;
          } else {
            this.powdermodel_visulization_params_form.pistol_to_substrate_distance = null;
          }
        }
      },
    },
    operationalHoursLabel: {
      get() {
        if (this.line.pump_type == "injector_venturi") {
          return this.$t("Operational Hours Injector");
        } else if (this.line.pump_type == "dense_phase") {
          return this.$t("Operational Hours Since Last Maintanance");
        } else {
          return this.$t("Pump Operational Hours");
        }
      },
    },
    powderLabel: {
      get() {
        if (
          this.powdermodel_calibration_measures.powder_manufacturer == "AkzoNobel" &&
          this.$store.state.flightpath_pro_license
        ) {
          return "Interpon ID";
        } else {
          return this.$t("Powder ID");
        }
      },
    },
    experiment1Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_1 = value;
          }
        }
      },
    },
    experiment1Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_2 = value;
          }
        }
      },
    },
    experiment1Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_3 = value;
          }
        }
      },
    },
    experiment1Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_4 = value;
          }
        }
      },
    },
    experiment1Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_5 = value;
          }
        }
      },
    },
    experiment1Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_6 = value;
          }
        }
      },
    },
    experiment1Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_7 = value;
          }
        }
      },
    },
    experiment2Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_1 = value;
          }
        }
      },
    },
    experiment2Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_2 = value;
          }
        }
      },
    },
    experiment2Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_3 = value;
          }
        }
      },
    },
    experiment2Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_4 = value;
          }
        }
      },
    },
    experiment2Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_5 = value;
          }
        }
      },
    },
    experiment2Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_6 = value;
          }
        }
      },
    },
    experiment2Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_7 = value;
          }
        }
      },
    },
    experiment3Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_1 = value;
          }
        }
      },
    },
    experiment3Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_2 = value;
          }
        }
      },
    },
    experiment3Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_3 = value;
          }
        }
      },
    },
    experiment3Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_4 = value;
          }
        }
      },
    },
    experiment3Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_5 = value;
          }
        }
      },
    },
    experiment3Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_6 = value;
          }
        }
      },
    },
    experiment3Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_7 = value;
          }
        }
      },
    },
    experiment4Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_1 = value;
          }
        }
      },
    },
    experiment4Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_2 = value;
          }
        }
      },
    },
    experiment4Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_3 = value;
          }
        }
      },
    },
    experiment4Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_4 = value;
          }
        }
      },
    },
    experiment4Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_5 = value;
          }
        }
      },
    },
    experiment4Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_6 = value;
          }
        }
      },
    },
    experiment4Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_7 = value;
          }
        }
      },
    },
    experiment5Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_1 = value;
          }
        }
      },
    },
    experiment5Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_2 = value;
          }
        }
      },
    },
    experiment5Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_3 = value;
          }
        }
      },
    },
    experiment5Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_4 = value;
          }
        }
      },
    },
    experiment5Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_5 = value;
          }
        }
      },
    },
    experiment5Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_6 = value;
          }
        }
      },
    },
    experiment5Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_7 = value;
          }
        }
      },
    },
    experiment6Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_1 = value;
          }
        }
      },
    },
    experiment6Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_2 = value;
          }
        }
      },
    },
    experiment6Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_3 = value;
          }
        }
      },
    },
    experiment6Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_4 = value;
          }
        }
      },
    },
    experiment6Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_5 = value;
          }
        }
      },
    },
    experiment6Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_6 = value;
          }
        }
      },
    },
    experiment6Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_7 = value;
          }
        }
      },
    },
    experiment7Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_1 = value;
          }
        }
      },
    },
    experiment7Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_2 = value;
          }
        }
      },
    },
    experiment7Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_3 = value;
          }
        }
      },
    },
    experiment7Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_4 = value;
          }
        }
      },
    },
    experiment7Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_5 = value;
          }
        }
      },
    },
    experiment7Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_6 = value;
          }
        }
      },
    },
    experiment7Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_7 = value;
          }
        }
      },
    },
    experiment8Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_1 = value;
          }
        }
      },
    },
    experiment8Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_2 = value;
          }
        }
      },
    },
    experiment8Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_3 = value;
          }
        }
      },
    },
    experiment8Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_4 = value;
          }
        }
      },
    },
    experiment8Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_5 = value;
          }
        }
      },
    },
    experiment8Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_6 = value;
          }
        }
      },
    },
    experiment8Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_7 = value;
          }
        }
      },
    },
    measurement1: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_1 == null ||
          this.powdermodel_calibration_measures.measurement_1 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_1 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_1 = value;
          }
        }
      },
    },
    measurement2: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_2 == null ||
          this.powdermodel_calibration_measures.measurement_2 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_2 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_2 = value;
          }
        }
      },
    },
    measurement3: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_3 == null ||
          this.powdermodel_calibration_measures.measurement_3 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_3 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_3 = value;
          }
        }
      },
    },
    measurement4: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_4 == null ||
          this.powdermodel_calibration_measures.measurement_4 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_4 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_4 = value;
          }
        }
      },
    },
    measurement5: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_5 == null ||
          this.powdermodel_calibration_measures.measurement_5 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_5 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_5 = value;
          }
        }
      },
    },
    measurement6: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_6 == null ||
          this.powdermodel_calibration_measures.measurement_6 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_6 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_6 = value;
          }
        }
      },
    },
    measurement7: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_7 == null ||
          this.powdermodel_calibration_measures.measurement_7 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_7 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_7 = value;
          }
        }
      },
    },
    measurement8: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_8 == null ||
          this.powdermodel_calibration_measures.measurement_8 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_8;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_8 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_8.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_8 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_8 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_8 = value;
          }
        }
      },
    },
    measurement9: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_9 == null ||
          this.powdermodel_calibration_measures.measurement_9 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_9;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_9 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_9.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_9 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_9 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_9 = value;
          }
        }
      },
    },
    measurement10: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_10 == null ||
          this.powdermodel_calibration_measures.measurement_10 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_10;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_10 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_10.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_10 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_10 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_10 = value;
          }
        }
      },
    },
    measurement11: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_11 == null ||
          this.powdermodel_calibration_measures.measurement_11 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_11;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_11 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_11.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_11 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_11 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_11 = value;
          }
        }
      },
    },
    measurement12: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_12 == null ||
          this.powdermodel_calibration_measures.measurement_12 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_12;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_12 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_12.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_12 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_12 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_12 = value;
          }
        }
      },
    },
    measurement13: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_13 == null ||
          this.powdermodel_calibration_measures.measurement_13 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_13;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_13 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_13.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_13 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_13 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_13 = value;
          }
        }
      },
    },
    measurement14: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_14 == null ||
          this.powdermodel_calibration_measures.measurement_14 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_14;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_14 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_14.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_14 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_14 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_14 = value;
          }
        }
      },
    },
    measurement15: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_15 == null ||
          this.powdermodel_calibration_measures.measurement_15 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_15;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_15 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_15.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_15 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_15 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_15 = value;
          }
        }
      },
    },
    measurement16: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_16 == null ||
          this.powdermodel_calibration_measures.measurement_16 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_16;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_16 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_16.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_16 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_16 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_16 = value;
          }
        }
      },
    },
    measurement17: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_17 == null ||
          this.powdermodel_calibration_measures.measurement_17 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_17;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_17 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_17.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_17 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_17 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_17 = value;
          }
        }
      },
    },
    measurement18: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_18 == null ||
          this.powdermodel_calibration_measures.measurement_18 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_18;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_18 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_18.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_18 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_18 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_18 = value;
          }
        }
      },
    },
    measurement19: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_19 == null ||
          this.powdermodel_calibration_measures.measurement_19 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_19;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_19 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_19.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_19 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_19 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_19 = value;
          }
        }
      },
    },
    measurement20: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_20 == null ||
          this.powdermodel_calibration_measures.measurement_20 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_20;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_20 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_20.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_20 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_20 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_20 = value;
          }
        }
      },
    },
    measurement21: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_21 == null ||
          this.powdermodel_calibration_measures.measurement_21 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_21;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_21 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_21.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_21 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_21 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_21 = value;
          }
        }
      },
    },
    measurement22: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_22 == null ||
          this.powdermodel_calibration_measures.measurement_22 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_22;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_22 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_22.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_22 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_22 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_22 = value;
          }
        }
      },
    },
    measurement23: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_23 == null ||
          this.powdermodel_calibration_measures.measurement_23 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_23;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_23 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_23.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_23 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_23 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_23 = value;
          }
        }
      },
    },
    measurement24: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_24 == null ||
          this.powdermodel_calibration_measures.measurement_24 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_24;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_24 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_24.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_24 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_24 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_24 = value;
          }
        }
      },
    },
    measurement25: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_25 == null ||
          this.powdermodel_calibration_measures.measurement_25 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_25;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_25 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_25.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_25 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_25 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.measurement_25 = value;
          }
        }
      },
    },
    isStaticLineLayout() {
      return this.line.pistols_layout == "s" || this.line.pistols_layout == "staticmixed";
    },
  },
  watch: {
    "$store.state.user_data.unit_system"() {
      this.drawDistribution();
    },
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },

  mounted() {
    this.$store.state.isLoading = true;
    var canvas = document.getElementById("powdermodelcanvas");
    var ctx = canvas.getContext("2d");
    this.canvas_width = canvas.width;
    this.canvas_height = canvas.height;
    this.powdermodelcanvas = ctx;
    this.getData();
    setTooltip(this.$store.state.bootstrap);
  },
  updated() {
    setTooltip(this.$store.state.bootstrap);
  },
  methods: {
    routeToNewOptimization() {
      if (this.isStaticLineLayout) {
        this.$router.push("/applications/flightpath/staticlineoptimization/" + this.line.id);
      } else {
        this.$router.push("/applications/flightpath/optimization/" + this.line.id);
      }
    },
    async getData() {
      await Promise.all([this.getPowderModel(), this.getPowderCalibrationMeasures()]);
      if (this.powdermodel.powder !== null) {
        this.getPowder(this.powdermodel.powder);
      }

      this.getLine();
      await this.computeInitialDistanceAndAir();
      await this.assignMinMaxSliderRanges();
      await this.drawDistribution();
      this.$store.state.isLoading = false;
    },
    async getPowderModel() {
      try {
        if (this.powdermodelType == "calibrated") {
          let response = await axios.get("/api/v1/fp/powdermodels/" + this.powdermodelId);
          this.powdermodel = response.data;
        } else if (this.powdermodelType == "generalprecalibrated") {
          let response = await axios.get("/api/v1/fp/generalprecalibratedpowdermodels/" + this.powdermodelId);
          this.powdermodel = response.data;
        }
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getPowderModel();
          }.bind(this),
          5000,
        );
      }
    },
    async getLine() {
      try {
        if (this.powdermodelType == "calibrated") {
          let line_response = await axios.get("/api/v1/fp/line/" + this.powdermodel.line);
          this.line = line_response.data;
        }
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getLine();
          }.bind(this),
          5000,
        );
      }
    },
    getPowder(powder_id) {
      axios
        .get("/api/v1/onsite/powder/" + powder_id)
        .then((response) => {
          this.powder = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getPowderCalibrationMeasures() {
      try {
        if (this.powdermodelType == "calibrated") {
          let response = await axios.get("/api/v1/fp/powdercalibrationmeasures/" + this.powdermodelId + "/");

          response.data.max_pistol_to_substrate_distance_measurements *= 100;
          response.data.min_pistol_to_substrate_distance_measurements *= 100;
          response.data.calibration_line_speed *= 60;

          this.powdermodel_calibration_measures = response.data;
        }
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getPowderCalibrationMeasures();
          }.bind(this),
          5000,
        );
      }
    },
    async assignMinMaxSliderRanges() {
      if (this.LoadedCalibrationMeasures) {
        this.powdermodel_visulization_params_form.max_pistol_to_substrate_distance =
          this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements;
        this.powdermodel_visulization_params_form.min_pistol_to_substrate_distance =
          this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements;

        this.last_powdermodel_visualization_params = Object.assign({}, this.powdermodel_visulization_params_form);
      }
    },
    async computeInitialDistanceAndAir() {
      if (this.powdermodelType == "calibrated" && this.LoadedCalibrationMeasures) {
        if (
          this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements != null &&
          this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements != null
        ) {
          this.powdermodel_visulization_params_form.pistol_to_substrate_distance = parseFloat(
            (
              (this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements +
                this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements) /
              2
            ).toFixed(2),
          );
        }
        if (
          this.powdermodel_calibration_measures.max_air_parameter_measurements != null &&
          this.powdermodel_calibration_measures.min_air_parameter_measurements != null
        ) {
          this.powdermodel_visulization_params_form.air_parameter_value = parseFloat(
            (
              (this.powdermodel_calibration_measures.max_air_parameter_measurements +
                this.powdermodel_calibration_measures.min_air_parameter_measurements) /
              2
            ).toFixed(2),
          );
        }
        this.last_powdermodel_visualization_params = Object.assign({}, this.powdermodel_visulization_params_form);
      }
    },
    async drawDistribution() {
      this.$store.state.isLoading = true;
      this.disable_ranges = true;
      this.fillCanvasBlue();

      var distribution = await this.getGeneralizedGaussianDistribution();
      this.distribution_imgData = new ImageData(
        Uint8ClampedArray.from(distribution.values()),
        this.canvas_width,
        this.canvas_height,
      );
      this.powdermodelcanvas.putImageData(this.distribution_imgData, 0, 0);

      this.last_powdermodel_visualization_params = Object.assign({}, this.powdermodel_visulization_params_form);

      this.disable_ranges = false;
      this.$store.state.isLoading = false;
    },
    numericOnly(event) {
      let key = event.key;
      if (
        [
          "Delete",
          "Backspace",
          "Tab",
          "Enter",
          "ArrowUp",
          "ArrowRight",
          "ArrowDown",
          "ArrowLeft",
          ".",
          "Shift",
          "Control",
          ",",
          "-",
        ].includes(key) ||
        ((event.ctrlKey || event.metaKey) && ["a", "c", "v", "x", "z", "y"].includes(key.toLowerCase()))
      ) {
        return;
      }
      if (!/\d/.test(key)) {
        event.preventDefault();
      }
    },
    capitalize(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    fillCanvasBlue() {
      this.powdermodelcanvas.fillStyle = "#000080";
      this.powdermodelcanvas.fillRect(0, 0, this.canvas_width, this.canvas_height);
    },
    async getGeneralizedGaussianDistribution() {
      var distribution_window_pixel_size = this.canvas_width;
      var pixels_per_meter = Math.round(
        distribution_window_pixel_size / (this.powdermodel_visulization_params_form.simulated_spray_diameter / 100),
      );

      var body = {
        powdermodel_id: this.powdermodel.id,
        powdermodel_type: this.powdermodelType,
        pistol_to_substrate_distance: parseFloat(
          this.powdermodel_visulization_params_form.pistol_to_substrate_distance / 100,
        ),
        air_parameter_value: parseFloat(this.powdermodel_visulization_params_form.air_parameter_value),
        distribution_window_pixel_size: parseFloat(distribution_window_pixel_size),
        pixels_per_meter: pixels_per_meter,
      };

      var response = await axios.post("/api/v1/fp/computegeneralizedgaussiandistribution/", body);

      return response.data["distribution"];
    },
  },
};
</script>

<i18n>
  {
    "EN": {
        "Name":"Name",
        "Experiment":"Experiment",
        "Pistol to substrate distance": "Pistol to substrate distance",
        "Line speed": "Line speed",
        "Air parameter": "Air parameter",
        "Booth side used for calibration": "Booth side used for calibration",
        "right": "right",
        "left": "left",
        "Nozzle Type": "Nozzle Type",
        "Powder Amount": "Powder Amount",
        "Powder Manufacturer": "Powder Manufacturer",
        "Powder ID": "Powder ID",
        "Additional Info": "Additional Info",
        "Measurements": "Measurements",
        "Point": "Point",
        "Powder Model Details": "Powder Model Details",
        "Calibration type": "Calibration type",
        "Powder Model": "Powder Model",
        "static": "static",
        "advanced": "advanced",
        "simple": "simple",
        "Calibrated line": "Calibrated line",
        "Powder Model Type": "Powder Model Type",
        "Pre-Calibrated Powder Model": "Pre-Calibrated Powder Model",
        "Calibration Parameters": "Calibration Parameters",
        "MIN": "MIN",
        "MAX": "MAX",
        "Calibration Measurements": "Calibration Measurements",
        "PowderModel visualization": "PowderModel visualization",
        "The scale of this simulation is": "The scale of this simulation is",
        "Visualization size": "Visualization size",
        "Distance and Air sliders only available for advanced powder models.": "Distance and Air sliders only available for advanced powder models.",
        "Compute": "Compute",
        "Continue to Create Optimization": "Continue to Create Optimization",
        "Go Back": "Go Back",
        "Calibration Plate Measurements": "Calibration Plate Measurements",
        "-":"-",
        "left booth side": "left booth side",
        "right booth side": "right booth side",
        "Home":"Home",
        "evenly spaced": "evenly spaced",
        "unevenly spaced": "unevenly spaced",
        "Booth Humidity": "Booth Humidity",
        "Booth Temperature": "Booth Temperature",
        "Pump Operational Hours": "Pump Operational Hours",
        "Batch ID": "Batch ID",
        "Guns Spacing": "Guns Spacing",
        "Other": "Other",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Airflow or air volume selected in the powder coating machine",
        "Quantity of powder dispensed by the gun per unit time": "Quantity of powder dispensed by the gun per unit time",
        "Operational Hours Since Last Maintanance": "Operational Hours Since Last Maintanance",
        "Operational Hours Injector": "Operational Hours Injector",
        "hours": "hours",
    },
    "DE": {
        "Name":"Name",
        "Experiment":"Experiment",
        "Pistol to substrate distance": "Abstand zwischen Pistole und Substrat",
        "Line speed": "Kettengeschwindigkeit",
        "Air parameter": "Luftparameter",
        "Booth side used for calibration": "Kabinenseite für die Kalibrierung",
        "right": "rechts",
        "left": "links",
        "Nozzle Type": "Düsentyp",
        "Powder Amount": "Pulvermenge",
        "Powder Manufacturer": "Pulverhersteller",
        "Powder ID": "Pulver ID",
        "Additional Info": "Zusätzliche Informationen",
        "Measurements": "Messungen",
        "Point": "Punkt",
        "Powder Model Details": "Pulvermodell Details",
        "Calibration type": "Kalibrierungstyp",
        "Powder Model": "Pulvermodell",
        "static": "statisch",
        "advanced": "fortgeschritten",
        "simple": "einfach",
        "Calibrated line": "Kalibrierte Linie",
        "Powder Model Type": "Pulvermodelltyp",
        "Pre-Calibrated Powder Model": "Vorkalibriertes Pulvermodell",
        "Calibration Parameters": "Kalibrierungsparameter",
        "MIN": "MIN",
        "MAX": "MAX",
        "Calibration Measurements": "Kalibrierungsmessungen",
        "PowderModel visualization": "Pulvermodell Visualisierung",
        "The scale of this simulation is": "Die Skala dieser Simulation ist",
        "Visualization size": "Visualisierungsgröße",
        "Distance and Air sliders only available for advanced powder models.": "Abstands und Luftregler nur für fortgeschrittene Pulvermodelle verfügbar.",
        "Compute": "Berechnen",
        "Continue to Create Optimization": "Weiter zur Erstellung der Optimierung",
        "Go Back":"Zurück",
        "Calibration Plate Measurements": "Kalibrierplattenmessungen",
        "-":"-",
        "left booth side": "linke Kabinenseite",
        "right booth side": "rechte Kabinenseite",
        "Home":"Hauptseite",
        "evenly spaced": "gleichmäßig verteilt",
        "unevenly spaced": "ungleichmäßig verteilt",
        "Booth Humidity": "Kabinenfeuchtigkeit",
        "Booth Temperature": "Kabinentemperatur",
        "Pump Operational Hours": "Betriebsstunden der Pumpe",
        "Batch ID": "Chargen-ID",
        "Guns Spacing": "Pistolenabstand",
        "Other": "Andere",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Luftstrom oder Luftvolumen, das in der Pulverbeschichtungsmaschine ausgewählt wurde",
        "Quantity of powder dispensed by the gun per unit time": "Menge an Pulver, die pro Zeiteinheit von der Pistole abgegeben wird",
        "Operational Hours Since Last Maintanance": "Betriebsstunden seit der letzten Wartung",
        "Operational Hours Injector": "Betriebsstunden Injektor",
        "hours": "Stunden",
    },
    "ES": {
        "Name":"Nombre",
        "Experiment":"Experimento",
        "Pistol to substrate distance": "Distancia de la pistola al sustrato",
        "Line speed": "Velocidad de la línea",
        "Air parameter": "Parámetro de flujo aire",
        "Booth side used for calibration": "Lado de la cabina utilizado para la calibración",
        "right": "derecho",
        "left": "izquierdo",
        "Nozzle Type": "Tipo de boquilla",
        "Powder Amount": "Cantidad de pintura en polvo",
        "Powder Manufacturer": "Fabricante de pintura en polvo",
        "Powder ID": "ID de pintura en polvo",
        "Additional Info": "Información adicional",
        "Measurements": "Mediciones",
        "Point": "Punto",
        "Powder Model Details": "Detalles del modelo de pintura en polvo",
        "Calibration type": "Tipo de calibración",
        "Powder Model": "Modelo de pintura en polvo",
        "static": "estático",
        "advanced": "avanzado",
        "simple": "simple",
        "Calibrated line": "Línea calibrada",
        "Powder Model Type": "Tipo de modelo de pintura en polvo",
        "Pre-Calibrated Powder Model": "Modelo de pintura en polvo pre-calibrado",
        "Calibration Parameters": "Parámetros de calibración",
        "MIN": "MIN",
        "MAX": "MAX",
        "Calibration Measurements": "Mediciones de calibración",
        "PowderModel visualization": "Visualización del modelo de pintura en polvo",
        "The scale of this simulation is": "La escala de esta simulación es",
        "Visualization size": "Tamaño de la visualización",
        "Distance and Air sliders only available for advanced powder models.": "Los controles de distancia y aire solo están disponibles para modelos de pintura en polvo avanzados.",
        "Compute": "Calcular",
        "Continue to Create Optimization": "Continuar para crear optimización",
        "Go Back":"Volver a",
        "Calibration Plate Measurements": "Mediciones de la placa de calibración",
        "-":"-",
        "left booth side": "lado izquierdo de la cabina",
        "right booth side": "lado derecho de la cabina",
        "Home":"Inicio",
        "evenly spaced": "equidistantes",
        "unevenly spaced": "no equidistantes",
        "Booth Humidity": "Humedad de la cabina",
        "Booth Temperature": "Temperatura de la cabina",
        "Pump Operational Hours": "Horas de funcionamiento de la bomba",
        "Batch ID": "ID del lote",
        "Guns Spacing": "Espaciado de las pistolas",
        "Other": "Otro",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Flujo de aire o volumen de aire seleccionado en la máquina de recubrimiento en polvo",
        "Quantity of powder dispensed by the gun per unit time": "Cantidad de pintura en polvo dispensada por la pistola por unidad de tiempo",
        "Operational Hours Since Last Maintanance": "Horas de funcionamiento desde el último mantenimiento",
        "Operational Hours Injector": "Horas de funcionamiento del inyector",
        "hours": "horas",
    },
    "FR": {
        "Name":"Nom",
        "Experiment":"Expérience",
        "Pistol to substrate distance": "Distance entre le pistolet et le substrat",
        "Line speed": "Vitesse de la ligne",
        "Air parameter": "Paramètre d'air",
        "Booth side used for calibration": "Côté de la cabine utilisé pour l'étalonnage",
        "right": "droite",
        "left": "gauche",
        "Nozzle Type": "Type de buse",
        "Powder Amount": "Quantité de poudre",
        "Powder Manufacturer": "Fabricant de poudre",
        "Powder ID": "Poudre ID",
        "Additional Info": "Informations supplémentaires",
        "Measurements": "Mesures",
        "Point": "Point",
        "Powder Model Details": "Détails du modèle de poudre",
        "Calibration type": "Type d'étalonnage",
        "Powder Model": "Modèle de poudre",
        "static": "statique",
        "advanced": "avancé",
        "simple": "simple",
        "Calibrated line": "Ligne calibrée",
        "Powder Model Type": "Type de modèle de poudre",
        "Pre-Calibrated Powder Model": "Modèle de poudre pré-étalonné",
        "Calibration Parameters": "Paramètres d'étalonnage",
        "MIN": "MIN",
        "MAX": "MAX",
        "Calibration Measurements": "Mesures d'étalonnage",
        "PowderModel visualization": "Visualisation du modèle de poudre",
        "The scale of this simulation is": "L'échelle de cette simulation est",
        "Visualization size": "Taille de la visualisation",
        "Distance and Air sliders only available for advanced powder models.": "Les curseurs de distance et d'air ne sont disponibles que pour les modèles de poudre avancés.",
        "Compute": "Calculer",
        "Continue to Create Optimization": "Continuer à créer l'optimisation",
        "Go Back":"Arrière",
        "Calibration Plate Measurements": "Mesures de la plaque de calibrage",
        "-":"-",
        "left booth side": "côté cabine gauche",
        "right booth side": "côté cabine droit",
        "Home":"Page d'accueil",
        "evenly spaced": "uniformément espacés",
        "unevenly spaced": "espacés de manière inégale",
        "Booth Humidity": "Humidité de la cabine",
        "Booth Temperature": "Température de la cabine",
        "Pump Operational Hours": "Heures de fonctionnement de la pompe",
        "Batch ID": "ID du lot",
        "Guns Spacing": "Espacement des pistolets",
        "Other": "Autre",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Débit d'air ou volume d'air sélectionné dans la machine de revêtement en poudre",
        "Quantity of powder dispensed by the gun per unit time": "Quantité de poudre distribuée par le pistolet par unité de temps",
        "Operational Hours Since Last Maintanance": "Heures de fonctionnement depuis la dernière maintenance",
        "Operational Hours Injector": "Heures de fonctionnement de l'injecteur",
        "hours": "heures",
    },
    "IT": {
        "Name":"Nome",
        "Experiment":"Esperimento",
        "Pistol to substrate distance": "Distanza pistola-substrato",
        "Line speed": "Velocità di linea",
        "Air parameter": "Parametro dell'aria",
        "Booth side used for calibration": "Lato cabina utilizzato per la calibrazione",
        "right": "destra",
        "left": "sinistra",
        "Nozzle Type": "Tipo di ugello",
        "Powder Amount": "Quantità di polvere",
        "Powder Manufacturer": "Produttore di polvere",
        "Powder ID": "Polvere ID",
        "Additional Info": "Informazioni aggiuntive",
        "Measurements": "Misure",
        "Point": "Punto",
        "Powder Model Details": "Dettagli del modello di polvere",
        "Calibration type": "Tipo di calibrazione",
        "Powder Model": "Modello di polvere",
        "static": "statico",
        "advanced": "avanzato",
        "simple": "semplice",
        "Calibrated line": "Linea calibrata",
        "Powder Model Type": "Tipo di modello di polvere",
        "Pre-Calibrated Powder Model": "Modello di polvere pre-calibrato",
        "Calibration Parameters": "Parametri di calibrazione",
        "MIN": "MIN",
        "MAX": "MAX",
        "Calibration Measurements": "Misure di calibrazione",
        "PowderModel visualization": "Visualizzazione del modello di polvere",
        "The scale of this simulation is": "La scala di questa visualizzazione è",
        "Visualization size": "Dimensione della visualizzazione",
        "Distance and Air sliders only available for advanced powder models.": "I cursori di distanza e aria sono disponibili solo per modelli di polvere avanzati.",
        "Compute": "Calcolare",
        "Continue to Create Optimization": "Continua a creare l'ottimizzazione",
        "Go Back":"Indietro",
        "Calibration Plate Measurements": "Misure della piastra di calibrazione",
        "-":"-",
        "left booth side": "lato cabina sinistra",
        "right booth side": "lato cabina destra",
        "Home":"Pagina iniziale",
        "evenly spaced": "uniformemente spaziati",
        "unevenly spaced": "spaziati in modo non uniforme",
        "Booth Humidity": "Umidità della cabina",
        "Booth Temperature": "Temperatura della cabina",
        "Pump Operational Hours": "Ore operative della pompa",
        "Batch ID": "ID batch",
        "Guns Spacing": "Spaziatura delle pistole",
        "Other": "Altro",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Flusso d'aria o volume d'aria selezionato nella macchina di verniciatura a polvere",
        "Quantity of powder dispensed by the gun per unit time": "Quantità di polvere erogata dalla pistola per unità di tempo",
        "Operational Hours Since Last Maintanance": "Ore operative dall'ultima manutenzione",
        "Operational Hours Injector": "Ore operative dell'iniettore",
        "hours": "ore",
    },
    "PL": {
        "Name":"Nazwa",
        "Experiment":"Eksperyment",
        "Pistol to substrate distance": "Odległość pistoletu od podłoża",
        "Line speed": "Prędkość linii",
        "Air parameter": "Parametr powietrza",
        "Booth side used for calibration": "Strona kabiny użyta do kalibracji",
        "right": "prawo",
        "left": "lewo",
        "Nozzle Type": "Typ dyszy",
        "Powder Amount": "Ilość proszku",
        "Powder Manufacturer": "Producent proszku",
        "Powder ID": "Proszku ID",
        "Additional Info": "Dodatkowe informacje",
        "Measurements": "Pomiary",
        "Point": "Punkt",
        "Powder Model Details": "Szczegóły modelu proszku",
        "Calibration type": "Typ kalibracji",
        "Powder Model": "Model proszku",
        "static": "statyczny",
        "advanced": "zaawansowany",
        "simple": "prosty",
        "Calibrated line": "Linia skalibrowana",
        "Powder Model Type": "Typ modelu proszku",
        "Pre-Calibrated Powder Model": "Wstępnie skalibrowany model proszku",
        "Calibration Parameters": "Parametry kalibracji",
        "MIN": "MIN",
        "MAX": "MAX",
        "Calibration Measurements": "Pomiary kalibracyjne",
        "PowderModel visualization": "Wizualizacja modelu proszku",
        "The scale of this simulation is": "Skala tej symulacji to",
        "Visualization size": "Rozmiar wizualizacji",
        "Distance and Air sliders only available for advanced powder models.": "Suwaki odległości i powietrza dostępne są tylko dla zaawansowanych modeli proszkowych.",
        "Compute": "Obliczać",
        "Continue to Create Optimization": "Kontynuuj tworzenie optymalizacji",
        "Go Back":"Powrót",
        "Calibration Plate Measurements": "Pomiary płyty kalibracyjnej",
        "-":"-",
        "left booth side": "lewa strona kabiny",
        "right booth side": "prawa strona kabiny",
        "Home":"Strona główna",
        "evenly spaced": "równomiernie rozłożone",
        "unevenly spaced": "unevenly spaced",
        "Booth Humidity": "Wilgotność kabiny",
        "Booth Temperature": "Temperatura kabiny",
        "Pump Operational Hours": "Godziny pracy pompy",
        "Batch ID": "ID partii",
        "Guns Spacing": "Rozstawienie pistoletów",
        "Other": "Inne",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Przepływ powietrza lub objętość powietrza wybrana w maszynie do malowania proszkowego",
        "Quantity of powder dispensed by the gun per unit time": "Ilość proszku wydawana przez pistolet na jednostkę czasu",
        "Operational Hours Since Last Maintanance": "Godziny pracy od ostatniego przeglądu",
        "Operational Hours Injector": "Godziny pracy wtryskiwacza",
        "hours": "godziny",
    },
    "TR": {
        "Name":"İsim",
        "Experiment":"Deney",
        "Pistol to substrate distance": "Tabancadan alt tabakaya mesafe",
        "Line speed": "Hat hızı",
        "Air parameter": "Hava parametresi",
        "Booth side used for calibration": "Kalibrasyon için kullanılan kabin tarafı",
        "right": "sağ",
        "left": "sol",
        "Nozzle Type": "Nozul tipi",
        "Powder Amount": "Toz miktarı",
        "Powder Manufacturer": "Toz üreticisi",
        "Powder ID": "Toz ID",
        "Additional Info": "Ek bilgi",
        "Measurements": "Ölçümler",
        "Point": "Nokta",
        "Powder Model Details": "Toz Modeli Detayları",
        "Calibration type": "Kalibrasyon tipi",
        "Powder Model": "Toz Modeli",
        "static": "statik",
        "advanced": "ileri",
        "simple": "basit",
        "Calibrated line": "Kalibre edilmiş çizgi",
        "Powder Model Type": "Toz Modeli Tipi",
        "Pre-Calibrated Powder Model": "Önceden Kalibre Edilmiş Toz Modeli",
        "Calibration Parameters": "Kalibrasyon Parametreleri",
        "MIN": "MIN",
        "MAX": "MAX",
        "Calibration Measurements": "Kalibrasyon Ölçümleri",
        "PowderModel visualization": "Toz Modeli görselleştirme",
        "The scale of this simulation is": "Bu simülasyonun ölçeği",
        "Visualization size": "Görselleştirme boyutu",
        "Distance and Air sliders only available for advanced powder models.": "Mesafe ve Hava kaydırıcıları yalnızca gelişmiş toz modelleri için kullanılabilir.",
        "Compute": "Hesaplamak",
        "Continue to Create Optimization": "Optimizasyon Oluşturmaya Devam Et",
        "Go Back": "Geri dön",
        "Calibration Plate Measurements": "Kalibrasyon Plakası Ölçümleri",
        "-":"-",
        "left booth side": "sol kabin tarafı",
        "right booth side": "sağ kabin tarafı",
        "Home":"Ana Sayfa",
        "evenly spaced": "eşit aralıklı",
        "unevenly spaced": "eşit olmayan aralıklı",
        "Booth Humidity": "Kabin Nem",
        "Booth Temperature": "Kabin Sıcaklığı",
        "Pump Operational Hours": "Pompa Çalışma Saatleri",
        "Batch ID": "Toplu Kimlik",
        "Guns Spacing": "Tabancaların aralığı",
        "Other": "Diğer",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Toz kaplama makinesinde seçilen hava akışı veya hava hacmi",
        "Quantity of powder dispensed by the gun per unit time": "Tabanca tarafından birim zamanda verilen toz miktarı",
        "Operational Hours Since Last Maintanance": "Son Bakımdan Bu Yana Çalışma Saatleri",
        "Operational Hours Injector": "Enjektör Çalışma Saatleri",
        "hours": "saat",
    },
    "ZH": {
        "Name":"名称",
        "Experiment":"实验",
        "Pistol to substrate distance": "手枪到基板的距离",
        "Line speed": "线速度",
        "Air parameter": "空气参数",
        "Booth side used for calibration": "用于校准的展位侧",
        "right": "对",
        "left": "左",
        "Nozzle Type": "喷嘴类型",
        "Powder Amount": "粉末量",
        "Powder Manufacturer": "粉末制造商",
        "Powder ID": "粉末ID",
        "Additional Info": "额外信息",
        "Measurements": "测量",
        "Point": "点",
        "Powder Model Details": "粉末模型细节",
        "Calibration type": "校准类型",
        "Powder Model": "粉末模型",
        "static": "静态",
        "advanced": "高级",
        "simple": "简单",
        "Calibrated line": "校准线",
        "Powder Model Type": "粉末模型类型",
        "Pre-Calibrated Powder Model": "预校准粉末模型",
        "Calibration Parameters": "校准参数",
        "MIN": "最小",
        "MAX": "最大",
        "Calibration Measurements": "校准测量",
        "PowderModel visualization": "粉末模型可视化",
        "The scale of this simulation is": "此模拟的比例是",
        "Visualization size": "可视化大小",
        "Distance and Air sliders only available for advanced powder models.": "距离和空气滑块仅适用于高级粉末模型。",
        "Compute": "计算",
        "Continue to Create Optimization": "继续创建优化",
        "Go Back": "转回去",
        "Calibration Plate Measurements": "校准板测量",
        "-":"-",
        "left booth side": "左展位侧",
        "right booth side": "右展位侧",
        "Home":"主页",
        "evenly spaced": "均匀间隔",
        "unevenly spaced": "不均匀间隔",
        "Booth Humidity": "展位湿度",
        "Booth Temperature": "展位温度",
        "Pump Operational Hours": "泵操作小时",
        "Batch ID": "批次ID",
        "Guns Spacing": "枪支间距",
        "Other": "其他",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "粉末涂层机中选择的气流或空气体积",
        "Quantity of powder dispensed by the gun per unit time": "枪每单位时间分配的粉末量",
        "Operational Hours Since Last Maintanance": "上次维护以来的运行时间",
        "Operational Hours Injector": "喷油嘴运行小时",
        "hours": "小时",
    },
  }
</i18n>
