<template>
  <div class="py-5 container-fluid">
    <div class="row mt-4 justify-content-center">
      <div class="col-10">
        <div class="card">
          <div class="card-header pb-0 text-center my-3">
            <h4 class="font-weight-bolder">{{ $t("Health Check") }}</h4>
          </div>
          <div
            class="card-body mb-0 pb-3"
            style="padding-top: 0.5rem"
          >
            <div class="row">
              <div
                class="col mt-5"
                style="margin-left: 3%"
              >
                <div
                  class="row"
                  style="display: flex; align-items: center"
                >
                  <div class="col-xl-6 col-lg-7 col-md-8 col-sm-9">
                    <h6>Gun to gun distance OK?</h6>
                  </div>
                  <div class="col">
                    <input
                      id="gun_to_gun_distance_check"
                      v-model="gun_to_gun_distance_check_form"
                      type="checkbox"
                      style="display: none"
                    />
                    <label
                      for="gun_to_gun_distance_check"
                      style="
                        display: inline-block;
                        width: 2rem;
                        height: 2rem;
                        border-radius: 50%;
                        border: 2px solid #ccc;
                        position: relative;
                        cursor: pointer;
                      "
                    >
                      <span
                        v-if="gun_to_gun_distance_check_form"
                        style="
                          display: block;
                          width: 1.5rem;
                          height: 1.5rem;
                          border-radius: 50%;
                          background: #070;
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                        "
                      ></span>
                    </label>
                  </div>
                </div>
                <div
                  class="row mt-4"
                  style="display: flex; align-items: center"
                >
                  <div class="col-xl-6 col-lg-7 col-md-8 col-sm-10">
                    <h6 style="margin-right: 10px">Venturi Nozzles OK?</h6>
                  </div>
                  <div class="col-4 mt-2">
                    <input
                      id="nozzles_check"
                      v-model="nozzles_check_form"
                      type="checkbox"
                      style="display: none"
                    />
                    <label
                      for="nozzles_check"
                      style="
                        display: inline-block;
                        width: 2rem;
                        height: 2rem;
                        border-radius: 50%;
                        border: 2px solid #ccc;
                        position: relative;
                        cursor: pointer;
                      "
                    >
                      <span
                        v-if="nozzles_check_form"
                        style="
                          display: block;
                          width: 1.5rem;
                          height: 1.5rem;
                          border-radius: 50%;
                          background: #070;
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                        "
                      ></span>
                    </label>
                  </div>
                </div>
                <div class="row mt-4">
                  <h6>Venturi Nozzles Last change</h6>
                  &nbsp;&nbsp;
                  <input
                    v-model="nozzle_last_changed_date_form"
                    class="form-control mt-2"
                    type="date"
                    style="width: 50%"
                  />
                </div>
              </div>
              <div class="col">
                <div class="row justify-content-center text-center">
                  <h6>Notes</h6>
                  <textarea
                    v-model="notes_form"
                    class="form-control my-2"
                    placeholder="Enter notes here"
                    type="text"
                    style="height: 240px; width: 80%; min-height: 40px"
                  ></textarea>
                </div>
              </div>
              <div class="col-12 text-center mt-4 mb-4">
                <button
                  :class="`btn ${formUpdated() ? 'btn-success' : 'btn-secondary'}`"
                  :disabled="!formUpdated()"
                  style="width: 90%"
                  @click="saveHealthCheck()"
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </div>
        <stepper-line-visit
          :line-id="lineId"
          :visit="visit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import StepperLineVisit from "./components/StepperLineVisit.vue";
import { useGetVisit } from "./utils/utils.js";

export default {
  name: "HealthCheck",
  components: {
    StepperLineVisit,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    visitId: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { visit, getVisit } = useGetVisit();
    return { visit, getVisit };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      gun_to_gun_distance_check_received: false,
      gun_to_gun_distance_check_form: false,
      nozzles_check_received: false,
      nozzles_check_form: false,
      nozzle_last_changed_date_received: "",
      nozzle_last_changed_date_form: "",
      notes_received: "",
      notes_form: "",
    };
  },
  computed: {},
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    this.getData();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getData() {
      await Promise.all([this.getVisit(this.visitId), this.getHealthCheck()]);
    },
    async saveHealthCheck() {
      const formData = {
        gun_to_gun_distance_check: this.gun_to_gun_distance_check_form,
        nozzles_check: this.nozzles_check_form,
        nozzle_last_changed_date: new Date(this.nozzle_last_changed_date_form).toISOString(),
        notes: this.notes_form,
        progress_status: this.getFormProgressStatus(),
      };

      try {
        await axios.patch("/api/v1/onsite/healthcheckstage/" + this.visitId + "/", formData);

        await this.getVisit(this.visitId);

        this.$swal({
          title: this.$t("Changes saved"),
          text: this.$t("Health Check Data") + " " + this.$t("was saved successfully"),
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          this.getHealthCheck();
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getHealthCheck() {
      try {
        let response = await axios.get("/api/v1/onsite/healthcheckstage/" + this.visitId);

        this.gun_to_gun_distance_check_received = response.data.gun_to_gun_distance_check;
        this.gun_to_gun_distance_check_form = response.data.gun_to_gun_distance_check;

        this.nozzles_check_received = response.data.nozzles_check;
        this.nozzles_check_form = response.data.nozzles_check;

        if (response.data.nozzle_last_changed_date === null) {
          const today = new Date().toISOString().split("T")[0];
          this.nozzle_last_changed_date_received = today;
          this.nozzle_last_changed_date_form = today;
        } else {
          this.nozzle_last_changed_date_received = new Date(response.data.nozzle_last_changed_date)
            .toISOString()
            .split("T")[0];
          this.nozzle_last_changed_date_form = new Date(response.data.nozzle_last_changed_date)
            .toISOString()
            .split("T")[0];
        }

        this.notes_received = response.data.notes;

        if (response.data.notes === null) {
          this.notes_received = "";
          this.notes_form = "";
        } else {
          this.notes_received = response.data.notes;
          this.notes_form = response.data.notes;
        }
      } catch (error) {
        console.error(error);
      }
    },
    formUpdated() {
      return (
        this.gun_to_gun_distance_check_received !== this.gun_to_gun_distance_check_form ||
        this.nozzles_check_received !== this.nozzles_check_form ||
        this.nozzle_last_changed_date_received !== this.nozzle_last_changed_date_form ||
        this.notes_received !== this.notes_form
      );
    },
    getFormProgressStatus() {
      const PROGRESS_STATUS_PENDING = "Pending";
      const PROGRESS_STATUS_IN_PROGRESS = "In Progress";
      const PROGRESS_STATUS_COMPLETED = "Completed";

      if (
        this.gun_to_gun_distance_check_form === true &&
        this.nozzles_check_form === true &&
        this.nozzle_last_changed_date_form !== ""
      ) {
        return PROGRESS_STATUS_COMPLETED;
      } else if (
        this.gun_to_gun_distance_check_form === true ||
        this.nozzles_check_form === true ||
        this.nozzle_last_changed_date_form !== "" ||
        this.notes_form !== ""
      ) {
        return PROGRESS_STATUS_IN_PROGRESS;
      } else {
        return PROGRESS_STATUS_PENDING;
      }
    },
  },
};
</script>

<i18n>
  {
    "EN": {
      "Health Check": "Health Check",
    },
    "DE": {
      "Health Check": "Gesundheitscheck",
    },
    "ES": {
      "Health Check": "Chequeo de salud",
    },
    "FR": {
      "Health Check": "Vérification de santé",
    },
    "IT": {
      "Health Check": "Controllo di salute",
    },
    "PL": {
      "Health Check": "Badanie lekarskie",
    },
    "TR": {
      "Health Check": "Sağlık kontrolü",
    },
    "ZH": {
      "Health Check": "健康检查",
    }
  }
</i18n>
