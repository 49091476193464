<template>
  <div
    v-if="visit != null"
    class="row card mt-4"
  >
    <div class="col-12">
      <div
        class="table-responsive"
        style="margin: 0px"
      >
        <table
          id="order-list"
          class="table table-flush"
        >
          <thead class="thead-light">
            <tr>
              <th class="text-center">Date</th>
              <th class="text-center">Benchmark &amp; Target</th>
              <th class="text-center">Health Check</th>
              <th class="text-center">Powder Output</th>
              <th class="text-center">Spray Pattern</th>
              <th class="text-center">Reciprocator</th>
              <th class="text-center">Powder Amount Saving</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="visit.date">
              <visit-row
                :date-time="visit ? formattedDate(visit.created_at) : formattedDate(new Date())"
                :benchmark-checked="visit.benchmarkstage ? visit.benchmarkstage.progress_status : 'Pending'"
                :health-checked="visit.healthcheckstage ? visit.healthcheckstage.progress_status : 'Pending'"
                :powder-output-checked="visit.powderoutputstage ? visit.powderoutputstage.progress_status : 'Pending'"
                :spray-pattern-checked="visit.spraypatternstage ? visit.spraypatternstage.progress_status : 'Pending'"
                :reciprocator-checked="visit.reciprocatorstage ? visit.reciprocatorstage.progress_status : 'Pending'"
                :powderamountsaving-checked="
                  visit.powderamountsavingstage ? visit.powderamountsavingstage.progress_status : 'Pending'
                "
                :line-id="lineId"
                :visit-id="visit.id"
              ></visit-row>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import VisitRow from "../../components/VisitRow.vue";

export default {
  name: "StepperLineVisit",
  components: {
    VisitRow,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    visit: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      visit_v: {
        date: "2021-09-01",
        benchmarkstage: { progress_status: "Completed" },
        healthcheckstage: { progress_status: "Completed" },
        powderoutputstage: { progress_status: "Completed" },
        spraypatternstage: { progress_status: "Completed" },
        reciprocatorstage: { progress_status: "Completed" },
        powderamountsavingstage: { progress_status: "Completed" },
      },
    };
  },
  methods: {
    formattedDate(date) {
      return new Date(date).toLocaleDateString();
    },
  },
};
</script>
