<template>
  <div class="py-6 container-fluid">
    <div id="topinfo">
      <div class="row mb-3">
        <div class="card bg-gradient-info">
          <div class="p-3 card-body">
            <div class="row">
              <div class="my-auto col-6">
                <div class="numbers">
                  <h5 class="mb-0 text-white font-weight-bolder">Monthly Usage Report Generator</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-4">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <p>Select month</p>
              <select
                id="choices-category"
                v-model="month"
                class="form-control"
                name="line-manufacturer-choices"
                style="appearance: listbox"
              >
                <option
                  disabled
                  value=""
                >
                  Please select one
                </option>
                \
                <option
                  v-for="month_option in month_options"
                  :key="month_option.value"
                  :value="month_option.value"
                >
                  {{ month_option.text }}
                </option>
              </select>
            </div>
            <div class="col-6">
              <p>Select year</p>
              <select
                id="choices-category"
                v-model="year"
                class="form-control"
                name="line-manufacturer-choices"
                style="appearance: listbox"
                @change="generateMonthOptions()"
              >
                <option
                  disabled
                  value=""
                >
                  Please select one
                </option>
                <option
                  v-for="year_option in year_options"
                  :key="year_option"
                  :value="year_option"
                >
                  {{ year_option }}
                </option>
              </select>
            </div>
          </div>
          <button
            :class="`mt-4 btn bg-gradient-${month == null ? 'gray' : 'success'}`"
            @click="generateUsageReport()"
          >
            <i class="fa fa-rocket"></i>
            &nbsp; Generate usage Report
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import logo from "@/assets/img/logo-ct-white.png";

export default {
  name: "GenerateMonthlyUsageReport",
  components: {},
  data() {
    return {
      logo,
      month: new Date().getMonth() + 1,
      month_options: [],
      year: new Date().getFullYear(),
      year_options: [],
    };
  },
  computed: {},
  watch: {
    "$store.state.user_data"() {
      if (this.$store.state.user_data.internal_user == false) {
        router.push({ name: "/" });
      }
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    this.$store.state.isLoading = false;
    this.generateYearOptions();
    this.generateMonthOptions();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    generateYearOptions() {
      let current_year = new Date().getFullYear();
      for (let year = 2024; year <= current_year; year++) {
        this.year_options.push(year);
      }
    },
    generateMonthOptions() {
      this.month_options = [];

      let current_month = new Date().getMonth() + 1;
      let all_month_options = [
        { value: 1, text: "January" },
        { value: 2, text: "February" },
        { value: 3, text: "March" },
        { value: 4, text: "April" },
        { value: 5, text: "May" },
        { value: 6, text: "June" },
        { value: 7, text: "July" },
        { value: 8, text: "August" },
        { value: 9, text: "September" },
        { value: 10, text: "October" },
        { value: 11, text: "November" },
        { value: 12, text: "December" },
      ];

      for (let month_option of all_month_options) {
        if (!(month_option.value > current_month && this.year == new Date().getFullYear())) {
          this.month_options.push(month_option);
        }
      }

      if (this.month > this.month_options.length) {
        this.month = new Date().getMonth() + 1;
      }
    },
    async generateUsageReport() {
      console.log("generateUsageReport");
      let response = await axios({
        url: "/api/v1/fp/generatemonthlyusagereport/",
        method: "POST",
        responseType: "blob",
        data: { month: this.month, year: this.year },
      });

      const blob = new Blob([response.data]);
      const link = document.createElement("a");

      link.href = window.URL.createObjectURL(blob);
      link.download = "FLIGHPATH USAGE REPORT.xlsx";
      link.click();
    },
  },
};
</script>
