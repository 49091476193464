<template>
  <div class="container-fluid py-1">
    <div class="card bg-gradient-secondary" style="text-align: center">
      <div class="px-5 bg-cover card-body z-index-1">
        <img
          src="/img/logo-ct-white.fbdb6245.png"
          alt="CoatingAI Logo"
          style="width: 50%; max-width: 400px; min-width: 120px;"
        />
        <h5 class="mt-3" style="color: white">Please select an active license application</h5>
        <div class="row justify-content-between mb-2 mt-4">
          <div v-if="$store.state.total_active_licenses == 0" class="col px-10">
            <div class="alert alert-warning text-white" role="alert">
              Your user has no active licenses.
            </div>
          </div>
          <div v-if="!$store.state.flightpath_deactivated_license" class="col">
            <button
              class="btn py-4 mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0"
              type="button"
              style="background-color: rgb(241, 241, 241); word-wrap: normal; font-size: min(0.9rem, max(1vw, 0.7rem))"
              @click="$router.push({ name: 'FlightPath' })"
            >
              <i class="fa fa-globe fa-3x" aria-hidden="true"></i>
              <br />
              FlightPath application
            </button>
          </div>
          <div v-if="$store.state.onsite_service_license" class="col">
            <button
              class="btn py-4 mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0"
              type="button"
              style="background-color: rgb(241, 241, 241); word-wrap: normal; font-size: min(0.9rem, max(1vw, 0.7rem))"
              @click="$router.push({ name: 'FlightPathOnSite' })"
            >
              <font-awesome-icon :icon="['fas', 'diamond']" size="3x" />
              <br />
              CORE application
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDiamond } from '@fortawesome/free-solid-svg-icons'

library.add(faDiamond)


export default {
  name: "Dashboard",
  components: {
    FontAwesomeIcon,
},
  mounted() {
    setTooltip(this.$store.state.bootstrap);
  },
  methods: {},
};
</script>
