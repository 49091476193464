import { ref } from "vue";
import axios from "axios";

export function useGetVisit() {
  // Reactive variable to hold the visit data
  const visit = ref({
    benchmarkstage: {},
    healthcheckstage: {},
    id: null,
    line: null,
    powderamountsavingstage: {},
    powderoutputstage: {},
    reciprocatorstage: {},
    spraypatternstage: {},
  });

  const getVisit = async visitId => {
    try {
      const response = await axios.get("/api/v1/onsite/visit/" + visitId);
      visit.value = response.data;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    visit,
    getVisit,
  };
}

export function useGetBenchmark() {
  const received_data = ref({
    minimum_target_thickness: null,
    maximum_target_thickness: null,
    reciprocator_period: null,
    guns_movement_range: null,
    line_speed: null,
    coated_height: null,
    nozzle_angle: null,
    gun_settings: null,
    measurements: Array(5).fill(null),
  });

  const getBenchmarkData = async (visitId, totalPistols) => {
    try {
      let response = await axios.get(`/api/v1/onsite/benchmarkstage/` + visitId);
      if (response.data !== null) {
        received_data.value.minimum_target_thickness = response.data.minimum_target_thickness;
        received_data.value.maximum_target_thickness = response.data.maximum_target_thickness;
        received_data.value.reciprocator_period = response.data.reciprocator_period;
        received_data.value.guns_movement_range = response.data.guns_movement_range;
        received_data.value.line_speed = response.data.line_speed;
        received_data.value.coated_height = response.data.coated_height;
        received_data.value.nozzle_angle = response.data.nozzle_angle;
        received_data.value.measurements = JSON.parse(response.data.measurements);

        if (received_data.value.measurements === null || received_data.value.measurements === "") {
          received_data.value.measurements = Array(5).fill(null);
        } else if (received_data.value.measurements.length < 5) {
          received_data.value.measurements = received_data.value.measurements.concat(
            Array(5 - received_data.value.measurements.length).fill(null),
          );
        }
        if (response.data.gun_settings !== null) {
          received_data.value.gun_settings = response.data.gun_settings;
        } else {
          received_data.value.gun_settings = Array.from({ length: totalPistols }, () => ({
            voltage: "",
            amps: "",
            air: "",
            distance: "",
            powder: "",
          }));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    received_data,
    getBenchmarkData,
  };
}

export function getThicknessMeasurementsMean(thickness_measurements) {
  if (thickness_measurements === null || thickness_measurements === "" || thickness_measurements.length === 0) {
    return 0;
  }

  let filtered_thickness_measurements = thickness_measurements.filter(m => m !== null && m !== "");

  if (filtered_thickness_measurements.length === 0) {
    return 0;
  }

  let mean = parseFloat(
    (filtered_thickness_measurements.reduce((a, b) => a + b, 0) / filtered_thickness_measurements.length).toFixed(1),
  );
  return mean;
}

export function getThicknessMeasurementsStd(thickness_measurements) {
  if (thickness_measurements === null || thickness_measurements === "" || thickness_measurements.length === 0) {
    return 0;
  }
  const mean = getThicknessMeasurementsMean(thickness_measurements);

  let filtered_thickness_measurements = thickness_measurements.filter(m => m !== null && m !== "");

  return parseFloat(
    Math.sqrt(
      filtered_thickness_measurements.reduce((sq, n) => sq + Math.pow(n - mean, 2), 0) /
        (filtered_thickness_measurements.length - 1),
    ).toFixed(1),
  );
}

export function measurementsMin(thickness_measurements) {
  if (thickness_measurements === null || thickness_measurements === "" || thickness_measurements.length === 0) {
    return 0;
  }
  return parseFloat(Math.min(...thickness_measurements.filter(m => m !== null && m !== "")).toFixed(1));
}

export function measurementsMax(thickness_measurements) {
  if (thickness_measurements === null || thickness_measurements === "" || thickness_measurements.length === 0) {
    return 0;
  }
  return parseFloat(Math.max(...thickness_measurements.filter(m => m !== null && m !== "")).toFixed(1));
}

export function showStatistics(measurements) {
  return getThicknessMeasurementsMean(measurements) > 0 && getThicknessMeasurementsStd(measurements) > 0;
}

export function numericOnly(event) {
  let key = event.key;
  if (
    [
      "Delete",
      "Backspace",
      "Tab",
      "Enter",
      "ArrowUp",
      "ArrowRight",
      "ArrowDown",
      "ArrowLeft",
      ".",
      "Shift",
      "Control",
      ",",
      "-",
    ].includes(key) ||
    ((event.ctrlKey || event.metaKey) && ["a", "c", "v", "x", "z", "y"].includes(key.toLowerCase()))
  ) {
    return;
  }
  if (!/\d/.test(key)) {
    event.preventDefault();
  }
}
