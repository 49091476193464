<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-250 border-radius-xl"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
      <div style="display: flex; align-items: center; justify-content: center">
        <div style="position: absolute; left: 50%; transform: translateX(-50%); top: 37%">
          <h3 ref="titlespray" style="color: rgb(255, 255, 255); text-align: center">Spray Pattern</h3>
        </div>
      </div>
    </div>
    <div class="mx-4 overflow-auto card card-body mt-n6">
      <div class="row mt-3 text-center">
        <div class="text-center">
          <button
            type="button"
            class="w-60px mb-0 btn ms-lg-auto me-lg-0 me-auto mt-lg-0"
            data-bs-toggle="modal"
            data-bs-target="#modal-form"
            style="width: 200px; height: 100px; background-color: rgb(241, 241, 241); font-size: 0.9rem"
          >
            New Powder
          </button>
          <div
            id="modal-form"
            ref="modal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modal-form"
            aria-hidden="true"
            style="z-index: 1050 !important"
          >
            <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
              <div class="modal-content" style="text-align: left !important">
                <div class="modal-body p-0">
                  <div class="card card-plain">
                    <div class="card-body justify-content-left">
                      <button
                        ref="dismissButton"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label=""
                      ></button>
                      <form role="form" class="">
                        <label>Powder name</label>
                        <div class="input-group mb-3">
                          <input
                            v-model="newPowderName"
                            type="text"
                            :class="powder_name_input_error ? `form-control is-invalid` : `form-control`"
                            placeholder="Powder name"
                            @input="powder_name_input_error = false"
                          />
                        </div>
                        <label>Powder Manufacturer</label>
                        <div class="input-group mb-3">
                          <input
                            v-model="newPowderManufacturer"
                            type="text"
                            :class="powder_manufacturer_input_error ? `form-control is-invalid` : `form-control`"
                            placeholder="Powder Manufacturer"
                            @input="powder_manufacturer_input_error = false"
                          />
                        </div>
                        <label>Powder ID (Optional)</label>
                        <div class="input-group mb-3">
                          <input v-model="newPowderId" type="text" class="form-control" placeholder="Powder ID" />
                        </div>
                        <div class="text-center">
                          <button
                            type="button"
                            class="btn bg-gradient-success btn-lg w-100 mt-4 mb-0"
                            @click="createPowder();"
                          >
                            Create Powder
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row gx-4">
        <div class="row">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="card-body">
                  <h4 class="mb-2 px-2">Powders List</h4>
                  <div v-if="powderlist.length">
                    <div class="table-responsive">
                      <table id="datatable-search" class="table table-flush table-hover">
                        <thead class="thead-light">
                          <tr>
                            <th
                              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                              style="padding: 0.5rem 0.5rem"
                            >
                              Name
                            </th>
                            <th
                              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                              style="padding: 0.5rem 0.5rem"
                            >
                              Powder Manufacturer
                            </th>
                            <th
                              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                              style="padding: 0.5rem 0.5rem"
                            >
                              Powder ID
                            </th>
                            <th
                              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                              style="padding: 0.5rem 0.5rem"
                            >
                              Created
                            </th>
                            <th
                              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                              style="padding: 0.5rem 0.5rem"
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="powder in OrderByDateUpdated(
                              powderlist.filter(item => item.line == parseInt(lineId)),
                            )"
                            :key="powder.id"
                          >
                            <td
                              class="text-sm font-weight-normal clickable"
                              style="cursor: pointer; vertical-align: middle"
                              @click="$router.push(`/applications/onsite/powdermodels/${lineId}/${visitId}/${powder.id}`)"
                            >
                              {{ powder.name }}
                            </td>
                            <td
                              class="text-sm font-weight-normal clickable"
                              style="cursor: pointer; vertical-align: middle"
                              @click="$router.push(`/applications/onsite/powdermodels/${lineId}/${visitId}/${powder.id}`)"
                            >
                              {{ powder.powder_manufacturer }}
                            </td>
                            <td
                              class="text-sm font-weight-normal clickable"
                              style="cursor: pointer; vertical-align: middle"
                              @click="$router.push(`/applications/onsite/powdermodels/${lineId}/${visitId}/${powder.id}`)"
                            >
                              {{ powder.powder_id }}
                            </td>
                            <td
                              class="text-sm font-weight-normal clickable"
                              style="cursor: pointer; vertical-align: middle"
                              @click="$router.push(`/applications/onsite/powdermodels/${lineId}/${visitId}/${powder.id}`)"
                            >
                              {{ dateToString(powder.created_at) }}
                            </td>
                            <td
                              class="text-sm font-weight-normal clickable"
                              style="vertical-align: middle"
                            >
                              <a
                                class="btn btn-link text-danger text-gradient px-3 mb-0 my-auto"
                                @click="deletePowder(powder.id, powder.name)"
                              >
                                <i class="far fa-trash-alt me-2" aria-hidden="true"> </i>
                                Delete
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div v-else>
                    <p class="px-2">{{ $t("No Powders created.") }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        
        
      </div>
    </div>
    <stepper-line-visit :line-id="lineId" :visit="visit" />
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import { orderBy } from "lodash";
import { useGetVisit } from "./utils/utils";
import StepperLineVisit from './components/StepperLineVisit.vue';

export default {
  name: "SprayPattern",
  components: {
    StepperLineVisit,
  },
  props: {
    lineId: {
      type: String,
      default: null,
    },
    visitId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { visit, getVisit } = useGetVisit();
    return { visit, getVisit };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    {
      return {
        newPowderName: "",
        powder_name_input_error: false,
        newPowderManufacturer: "",
        powder_manufacturer_input_error: false,
        newPowderId: "",
        line: [],
        powderlist: [],
        selected_powderlist: [],
        selected_powder_modals: [],
        visit: null,
        // from db and selected SprayPatterns
        selectedPowders: [
          {
            powder_id: 1,
            spray_pattern_id: 1,
            visit: this.visitId,
          }
        ],
      };
    }
  },
  computed: {},
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    }
  },
  beforeMount() {
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    this.getData();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getData() {
      await this.getVisit(this.visitId);
      await this.getPowders();
      await this.getLine();
    },
    async getLine() {
      try {
        let response = await axios.get("/api/v1/fp/line/" + this.lineId);
        this.line = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getLine();
          }.bind(this),
          5000,
        );
      }
    },
    checkFilledCreatePowderForm() {
      if (this.newPowderName == "") {
        this.powder_name_input_error = true;
      }
      if (this.newPowderManufacturer == "") {
        this.powder_manufacturer_input_error = true;
      }
      if (this.powder_name_input_error || this.powder_manufacturer_input_error) {
        this.$swal({
          title: "Please fill in all required fields",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
        return false;
      }
      return true;
    },
    createPowder() {
      if (!this.checkFilledCreatePowderForm()) {
        return;
      }
      axios.post("/api/v1/onsite/powder/", {
        line: this.lineId,
        name: this.newPowderName,
        powder_manufacturer: this.newPowderManufacturer,
        powder_id: this.newPowderId,
      })
      .then(() => {
        this.newPowderName = "";
        this.newPowderManufacturer = "";
        this.newPowderId = "";
        this.getPowders();
      })
      .catch((error) => {
        console.error(error);
      });
      this.closeModal();
    },
    async getPowders() {
      try {
        let response = await axios.get("/api/v1/onsite/powder/");
        this.powderlist = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error(error);
      }
    },
    async getSelectedSprayPatternData() {
      try {
        let response = await axios.get("/api/v1/onsite/spraypattern/" + this.visitId);
        console.log(response.data);
        this.selected_powder_modals = JSON.parse(JSON.stringify(response.data.selected_powder_modals));
      } catch (error) {
        console.error(error);
      }
    },
    closeModal() {
      const dismissButton = this.$refs.dismissButton;
        if (dismissButton) {
          dismissButton.click();
        }
    },
    OrderByDateUpdated(array) {
      return orderBy(array, ["updated_at", "created_at"], ["desc"]);
    },
    dateToString(date) {
      const date_object = new Date(date);
      if (this.$store.state.user_data.unit_system == "metric") {
        return date_object.toLocaleString("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      } else if (this.$store.state.user_data.unit_system == "imperial") {
        return date_object.toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      } else {
        return null;
      }
    },
    deletePowder(id, powder_name) {
      this.$swal({
        title: `Are you sure you want to delete Powder <i>${powder_name}</i>?`,
        text: "This will also delete this Powder's calibrations",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          axios.delete("/api/v1/onsite/powder/" + id)
          .then(() => {
            this.getPowders();
          })
          .catch((error) => {
            console.error(error);
          });
        }
      })
    },
  },
};
</script>

<i18n>
  {
    "EN": {
    "Powders Library":"Powders Library",
    "No Powders created.":"No Powders created.",
    "Powders List":"Powders List",
    "New Powder":"New Powder",
    },
    "DE": {
    "Powders List":"Powders List",
    "No Powders created.":"No Powders created.",
    "Powders Library":"Pulverbibliothek",
    "New Powder":"Neues Pulver",
    },
    "ES": {
    "Powders List":"Powders List",
    "No Powders created.":"No Powders created.",
    "Powders Library":"Lista de Pinturas en Polvo",
    "New Powder":"Nueva Pintura en Polvo",
    },
    "FR": {
    "Powders List":"Powders List",
    "No Powders created.":"No Powders created.",
    "Powders Library":"Bibliothèque de Poudres",
    "New Powder":"Nouvelle Poudre",
    },
    "IT": {
    "Powders List":"Powders List",
    "No Powders created.":"No Powders created.",
    "Powders Library":"Libreria di Polveri",
    "New Powder":"Nuova Polvere",
    },
    "PL": {
    "Powders List":"Powders List",
    "No Powders created.":"No Powders created.",
    "Powders Library":"Biblioteka Proszków",
    "New Powder":"Nowy Proszek",
    },
    "TR": {
    "Powders List":"Powders List",
    "No Powders created.":"No Powders created.",
    "Powders Library":"Tozlar Kütüphanesi",
    "New Powder":"Yeni Toz",
    },
    "ZH": {
    "Powders List":"Powders List",
    "No Powders created.":"No Powders created.",
    "Powders Library":"粉末库",
    "New Powder":"新粉末",
    }
  }
</i18n>
