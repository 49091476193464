<template>
  <div class="py-1 container-fluid">
    <div id="topinfo">
      <div class="row mt-4">
        <div class="col">
          <div class="card">
            <div id="compute-optimal-settings-view" class="accordion-body">
              <h5>{{ $t('Powder Amount Calibration') }}</h5>
              <div class="row mb-3">
                <div class="col w-20">
                  <label class="mt-2 mb-2">{{ $t('Name')}}</label>
                  <div class="mt-2 mb-2 ">
                    <input
                      id="name"
                      v-model="powder_amount_name"
                      :class="name_input_error ? 'form-control is-invalid':'form-control'"
                      type="text"
                      :disabled="powderAmountCalibrationCreated"
                      @input="name_input_error = false"
                    >
                  </div>
                </div>
                <div class="col w-20">
                  <label class="mt-2 mb-2">{{ $t('Number of Measures') }}</label>
                  <div class="mt-2 mb-2 ">
                    <select
                      id="powder-amount-measures"
                      v-model="number_of_powder_amount_measures"
                      class="form-control"
                      style="appearance: listbox;"
                      :disabled="powderAmountCalibrationCreated"
                      @change="updatePowderAmountParametersFromRange()"
                      >
                      <option disabled value="">{{ $t('Please select one') }}</option>
                      <option
                        v-for="n in [2, 3, 4]"
                        :key="n"
                        :value="parseInt(n)"
                      >
                        {{ n }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col w-20">
                  <label class="mt-2 mb-2">{{ $t('Time Interval')}} [s]</label>
                  <div class="mt-2 mb-2 ">
                    <input
                      id="time-interval"
                      v-model="powder_amount_time_interval"
                      min="15"
                      :class="time_interval_input_error ? 'form-control is-invalid':'form-control'"
                      type="number"
                      :disabled="powderAmountCalibrationCreated"
                      @input="time_interval_input_error = false"
                      >
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col w-20">
                    <label class="mt-2 mb-2">{{ $t('Min Powder Amount Param')}}</label>
                    <div class="mt-2 mb-2 ">
                    <input
                      id="min-powder-amount-param"
                      v-model="powder_amount_min_param"
                      min="0"
                      step="0.1"
                      :class="min_param_input_error ? 'form-control is-invalid':'form-control'"
                      :disabled="powderAmountCalibrationCreated"
                      type="number"
                      @change="updatePowderAmountParametersFromRange(); min_param_input_error = false"
                    >
                  </div>
                </div>
                <div class="col w-20">
                  <label class="mt-2 mb-2">{{ $t('Max Powder Amount Param')}}</label>
                  <div class="mt-2 mb-2 ">
                    <input
                      id="max-powder-amount-param"
                      v-model="powder_amount_max_param"
                      min="0"
                      step="0.1"
                      :class="max_param_input_error ? 'form-control is-invalid':'form-control'"
                      type="number"
                      :disabled="powderAmountCalibrationCreated"
                      @change="updatePowderAmountParametersFromRange(); max_param_input_error = false"
                      >
                  </div>
                </div>
                <div class="col w-20">
                  <label class="mt-2 mb-2">
                    {{ $t('Select Powder Model') }} <p class="text-xs form-text text-muted ms-1 d-inline">({{ $t('optional') }})</p>
                  </label>
                  <div class="mt-2 mb-2" style="position: relative;" @click.stop="toggleDropdown">
                    <button class="form-control" :disabled="powderAmountCalibrationCreated">
                      {{ selectedPowderModelName }}
                    </button>
                    <div v-if="dropdownVisible" class="dropdown-menu show">
                      <input
                        v-model="searchQuery"
                        type="text"
                        placeholder="Search..."
                        class="form-control"
                        @click.stop
                      />
                      <div
                        class="dropdown-item"
                        style="cursor: pointer;"
                        @click="selectModel(null)"
                        >
                        {{$t("Not Specified")}}
                      </div>
                      <div
                      v-for="powdermodel in filteredPowderModels"
                      :key="powdermodel.id"
                      class="dropdown-item"
                      style="cursor: pointer;"
                      @click="selectModel(powdermodel)"
                        >
                        {{ powdermodel.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="powderAmountParamRangeDefined" class="row">
                <div class="accordion">
                  <div class="accordion-item mb-0">
                    <h5 id="panelsStayOpen-headingOne">
                    <button
                      class="accordion-button mb-0"
                      type="button"
                      data-bs-toggle="collapse"
                      :class="{'collapsed': !isAccordionOpen}"
                      :aria-expanded="isAccordionOpen"
                      data-bs-target="#panelsStayOpen-collapseParams"
                      aria-controls="panelsStayOpen-collapseParams"
                      style="padding: 0;"
                    >
                      <p class="font-weight-bolder mt-3" style="font-weight: 700 !important; font-size: 1.05rem; vertical-align: middle; margin-bottom: 0;">
                        {{$t('Calibration Steps')}}
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        <i class="fa fa-chevron-up" aria-hidden="true"></i>
                      </p>
                    </button>
                    </h5>
                    <div 
                      id="panelsStayOpen-collapseParams"
                      class="accordion-collapse collapse"
                      :class="{'show': isAccordionOpen}"
                      aria-labelledby="panelsStayOpen-headingOne"
                      >
                      <div class="accordion-body" style="padding: 0;">
                        <ol>
                          <li>
                            {{ $t('Prepare the Vacuum Bags:') }}
                            <ul>
                              <li>{{ $t('Select a vacuum bag for each gun.') }}</li>
                              <li>{{ $t('Weigh the empty vacuum bag and record its weight.') }}</li>
                            </ul>
                          </li>
                          <li>
                            {{ $t('Set Up the Machine:') }}
                            <ul>
                              <li>{{ $t('Attach the vacuum bag to the tip of the gun.') }}</li>
                              <li>{{ $t('Turn on the machine using the specified setting for the defined time interval.') }}</li>
                            </ul>
                          </li>
                          <li>
                            {{ $t('Weigh the Bags:') }}
                            <ul>
                              <li>{{ $t('After the machine runs for the set time, weigh each vacuum bag again.') }}</li>
                              <li>{{ $t('Record the new weight of each bag.') }}</li>
                            </ul>
                          </li>
                          <li>
                            {{ $t('Repeat for Different Parameters:') }}
                            <ul>
                              <li>{{ $t('Empty the vacuum bags before proceeding to the next parameter.') }}</li>
                              <li>{{ $t('Weigh the empty vacuum bag and record its weight.') }}</li>
                              <li>{{ $t('Repeat the entire process for each new parameter setting.') }}</li>
                            </ul>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <h6 v-if="powderAmountParamRangeDefined">{{ $t("Set your machine's powder amount setting to:") }} &nbsp; <b>{{ powder_amount_parameters[currentPage] }}</b></h6>
                <div class="col-6 mt-4 text-center">
                  <canvas
                  id="powder_amount_calibration_canvas"
                  class="col-12 mt-4 mb-4 justify-content-center"
                  width="400"
                  height="300"
                  :style="`max-width: 700px; ${powderAmountParamRangeDefined ? '':'display: none;'}`"
                  >
                  </canvas>
                </div>
                <div v-if="powderAmountParamRangeDefined" class="col-6 mt-4 mb-3">
                  <div v-for="m in number_of_powder_amount_measures" :key="m" :class="{ 'hidden-div': currentPage !== m-1 }">
                    <div >
                      <div class="row">
                        <div v-for="n in total_pistols" :key="n" class="row">
                          <div class="col-2 mt-5">
                            <h6>{{ $t(`Gun`) }} {{ n }}</h6>
                          </div>
                          <div class="col-4">
                            <label class="mt-2 mb-2">{{ $t('Empty Bag Weight') }}  {{ m }} [{{$store.state.units_system[$store.state.user_data.unit_system].grams}}]</label>
                            <div class="mt-2 mb-2">
                              <input
                              v-model="convertedCalibrationMeasures[n-1]['emptyBagWeight'][m-1]"
                              :class="powder_amount_calibration_measures_input_error[n-1]['emptyBagWeight'][m-1] ? 'form-control is-invalid':'form-control'"
                              type="number"
                              min="0"
                              :disabled="powderAmountCalibrationCreated"
                              @change="updateMeasurement(n-1, 'emptyBagWeight', m-1, $event.target.value); powder_amount_calibration_measures_input_error[n-1]['emptyBagWeight'][m-1] = false"
                              >
                            </div>
                          </div>
                          <div class="col-4">
                            <label class="mt-2 mb-2">{{ $t('Full Bag Weight') }}  {{ m }} [{{$store.state.units_system[$store.state.user_data.unit_system].grams}}]</label>
                            <div class="mt-2 mb-2">
                              <input
                              v-model="convertedCalibrationMeasures[n-1]['fullBagWeight'][m-1]"
                              :class="powder_amount_calibration_measures_input_error[n-1]['fullBagWeight'][m-1] ? 'form-control is-invalid':'form-control'"
                              type="number"
                              :min="convertedCalibrationMeasures[n-1]['emptyBagWeight'][m-1] || 0"
                              :disabled="powderAmountCalibrationCreated"
                              @change="updateMeasurement(n-1, 'fullBagWeight', m-1, $event.target.value); powder_amount_calibration_measures_input_error[n-1]['fullBagWeight'][m-1] = false"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="powderAmountParamRangeDefined" class="row mt-4 mb-2">
                  <div class="col">
                    <soft-button v-if="currentPage > 0" color="success" size="sm" variant="contained" @click="onClickPrevPage">
                      {{$t('Back to Powder Amount Param')}} {{currentPage}}
                    </soft-button>
                  </div>
                  <div class="col text-end">
                    <soft-button v-if="currentPage < number_of_powder_amount_measures-1" color="success" size="sm" variant="contained" @click="onClickNextPage">
                      {{$t('Continue to Powder Amount Param')}} {{currentPage+2}}
                    </soft-button>
                  </div>
                </div>
                <div v-if="powderAmountParamRangeDefined">
                  <soft-pagination
                    class="justify-content-center mt-3 mb-3"
                    color="success"
                    aria-label="Powder amount measures input pages"
                  >
                    <soft-pagination-item prev :disabled="currentPage <= 0" @click="onClickPrevPage" />
                    <div v-for="n in number_of_powder_amount_measures" :key="n">
                      <soft-pagination-item :active="currentPage === n-1" :label="n.toString()" @click="currentPage = n-1;fillPowderAmountCalibrationCanvas()"/>
                    </div>
                    <soft-pagination-item next :disabled="currentPage >= number_of_powder_amount_measures-1" @click="onClickNextPage" />
                  </soft-pagination>
                </div>
              </div>
              <div v-if="!powderAmountCalibrationCreated" class="row mt-3">
                <div class="col-12 d-flex justify-content-center">
                  <button
                    type="button"
                    :class="`mt-2 mb-0 btn ${PowderAmountFieldsFilled ? 'bg-gradient-success' : 'bg-gradient-secondary'}`"
                    style="width: 300px; height: 60px;"
                    @click="createPowderAmountCalibration"
                  >
                    {{ $t('Save Powder Amount Calibration') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import setTooltip from "@/assets/js/tooltip.js";
import SoftButton from "@/components/SoftButton.vue";
import SoftPagination from "@/components/SoftPagination.vue";
import SoftPaginationItem from "@/components/SoftPaginationItem.vue";
import router from '@/router'

export default {
  name: "PowderAmountCalibrationDetail",
  components: {
    SoftButton,
    SoftPagination,
    SoftPaginationItem,
  },

  props: {
    lineId: {
      type: String,
      default: null,
    },
    powderAmountCalibrationIdProp: {
      type: String,
      default: null,
    },
  },

  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      powder_amount_name: null,
      number_of_powder_amount_measures: 2,
      powder_amount_time_interval: 60,
      powder_amount_min_param: null,
      powder_amount_max_param: null,
      powder_amount_calibration_measures: null,
      powder_amount_calibration_image: null,
      powder_amount_calibration_canvas: null,
      powder_amount_parameters: null,
      currentPage: 0,
      total_pistols: null,
      calibrated_powdermodels: [],
      powder_amount_selected_powder_model: null,
      searchQuery: '',
      dropdownVisible: false,
      powder_amount_calibration_id: null,
      powder_amount_calibration_measures_input_error: [],
      name_input_error: false,
      time_interval_input_error: false,
      min_param_input_error: false,
      max_param_input_error: false,
      isAccordionOpen: true,
      max_imperial_decimals: 2,
      max_metric_decimals: 0,
    }
  },

  computed: {
    powderAmountCalibrationCreated() {
      return this.powder_amount_calibration_id !== null && this.powder_amount_calibration_id !== "";
    },
    filteredPowderModels() {
      return this.calibrated_powdermodels.filter((powdermodel) =>
        powdermodel.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    selectedPowderModelName() {
      if (this.powder_amount_selected_powder_model === null) {
        return this.$t('Not Specified');
      }

      const selectedModel = this.calibrated_powdermodels.find(
        (model) => model.id === this.powder_amount_selected_powder_model
      );
      return selectedModel ? selectedModel.name : this.$t('Not Specified');
    },
    powderAmountParamRangeDefined() {
      return (
        this.powder_amount_min_param !== null && this.powder_amount_max_param != null
        && this.powder_amount_min_param !== "" && this.powder_amount_max_param != ""
        && this.powder_amount_max_param > this.powder_amount_min_param
        && this.powder_amount_parameters!=null);
    },
    convertedCalibrationMeasures() {
    return this.powder_amount_calibration_measures.map(measure => ({
      emptyBagWeight: measure.emptyBagWeight.map(weight => this.convertMeasurement(weight, 'get')),
      fullBagWeight: measure.fullBagWeight.map(weight => this.convertMeasurement(weight, 'get')),
      }));
    },
    PowderAmountFieldsFilled(){
      let filled = true
      if (this.powder_amount_name == null || this.powder_amount_name == "") {
        filled = false;
      }
      if (this.powder_amount_time_interval == null || this.powder_amount_time_interval == "") {
        filled = false;
      }
      if (this.powder_amount_min_param === null || this.powder_amount_min_param === "") {
        filled = false;
      }
      if (this.powder_amount_max_param === null || this.powder_amount_max_param === "") {
        filled = false;
      }
      if (this.powder_amount_calibration_measures){
          this.powder_amount_calibration_measures.forEach((measure) => {
            measure.emptyBagWeight.forEach((weight) => {
              if (weight === null) {
                filled = false;
              }
            });
            measure.fullBagWeight.forEach((weight) => {
              if (weight === null) {
                filled = false;
              }
            });
          });
        } 
      return filled
    }, 
  },
  watch: {
    number_of_powder_amount_measures(newVal) {
      this.updatePowderAmountCalibrationMeasures(newVal);
    },
    '$store.state.user_data.language' () {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.powder_amount_calibration_id = JSON.parse(JSON.stringify(this.powderAmountCalibrationIdProp));
    var canvas_powder_amount_calibration = document.getElementById("powder_amount_calibration_canvas");
    var ctx_powder_amount_calibration = canvas_powder_amount_calibration.getContext("2d");
    this.canvas_width = canvas_powder_amount_calibration.width;
    this.canvas_height = canvas_powder_amount_calibration.height;
    this.powder_amount_calibration_canvas = ctx_powder_amount_calibration;
    this.getData();
    setTooltip(this.$store.state.bootstrap);
    window.addEventListener('resize', this.onWindowResize);
    document.addEventListener('click', this.closeDropdown);
  },
  updated() {
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
    document.removeEventListener('click', this.closeDropdown);
  },
  methods: {
    onClickPrevPage() {
      if (this.currentPage > 0) {
        this.currentPage -= 1;
      }
    },
    onClickNextPage() {
      if (this.currentPage < this.number_of_powder_amount_measures-1) {
        this.currentPage += 1;
      }
    },
    closeDropdown() {
      this.dropdownVisible = false;
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    selectModel(powdermodel) {
      this.powder_amount_selected_powder_model = powdermodel ? powdermodel.id : null;
    },
    mapIncomingData(data) {
      if (!Array.isArray(data)) {
        console.error('Data is not an array:', data);
        return [];
      }

      return data.map(gun => {
        if (!Array.isArray(gun)) {
          console.error('gun is not an array:', gun);
          return { powderAmount: [], emptyBagWeight: [], fullBagWeight: [] };
        }

        return {
          powderAmount: gun.map(item => item[0] || null),
          emptyBagWeight: gun.map(item => item[1] || null),
          fullBagWeight: gun.map(item => item[2] || null)
        };
      });
    },
    PowderAmountFieldsProperlyFilled() {
        let properly_filled = true
        let missing_fields = []
        if (this.powder_amount_name == null || this.powder_amount_name == "") {
            properly_filled = false;
            missing_fields.push(this.$t("Name"))
            this.name_input_error = true;
        }
        if (this.powder_amount_time_interval == null || this.powder_amount_time_interval == "") {
            properly_filled = false;
            missing_fields.push(this.$t("Time Interval"))
            this.time_interval_input_error = true;
        }
        if (this.powder_amount_min_param === null || this.powder_amount_min_param === "") {
            properly_filled = false;
            missing_fields.push(this.$t("Min Powder Amount Param"))
            this.min_param_input_error = true;
        }
        if (this.powder_amount_max_param === null || this.powder_amount_max_param === "") {
            properly_filled = false;
            missing_fields.push(this.$t("Max Powder Amount Param"))
            this.max_param_input_error = true;
        }
        if (this.powder_amount_calibration_measures){
          this.powder_amount_calibration_measures.forEach((measure, gunIdx) => {
            measure.emptyBagWeight.forEach((weight, measureIdx) => {
              if (weight === null || weight === "") {
                this.powder_amount_calibration_measures_input_error[gunIdx].emptyBagWeight[measureIdx] = true;
                properly_filled = false;
                missing_fields.push(this.$t("Empty Bag Weight") + " " + this.$t("Measurement") + " " + (measureIdx + 1) + " " + this.$t("Gun") + " " + (gunIdx + 1));
              }
            });

            measure.fullBagWeight.forEach((weight, measureIdx) => {
              if (weight === null || weight === "") {
                this.powder_amount_calibration_measures_input_error[gunIdx].fullBagWeight[measureIdx] = true;
                properly_filled = false;
                missing_fields.push(this.$t("Full Bag Weight") + " " + this.$t("Measurement") + " " + (measureIdx + 1) + " " + this.$t("Gun") + " " + (gunIdx + 1));
              }
            });
          });
        }
        return [properly_filled, missing_fields]
     },
    async getCalibratedPowderModels(){
      try {
        const response = await axios.get("/api/v1/fp/powdermodels/lineid/" + this.lineId);
        this.calibrated_powdermodels = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error(error)
        setTimeout(function() {
          this.getCalibratedPowderModels();
        }.bind(this), 5000);
      }
    },
    async getPowderAmountCalibrationMeasures(){
      try {
        if (this.powderAmountCalibrationCreated) {
          let response = await axios.get('/api/v1/fp/powderamountcalibrationmeasures/' + this.powder_amount_calibration_id)
          let filtered_response = response.data[0];
          this.powder_amount_name = filtered_response.name;
          this.powder_amount_time_interval = filtered_response.time_interval;
          this.number_of_powder_amount_measures = filtered_response.number_of_measures;
          this.powder_amount_min_param = filtered_response.powder_amount_min_param;
          this.powder_amount_max_param = filtered_response.powder_amount_max_param;
          this.updatePowderAmountParametersFromRange();
          let measures_list = JSON.parse(filtered_response.measures_list);
          this.powder_amount_calibration_measures = this.mapIncomingData(measures_list);
          this.powder_amount_selected_powder_model = filtered_response.powdermodel;
          this.isAccordionOpen = false;
          } else {
          this.powder_amount_calibration_measures = Array.from({ length: this.total_pistols }, () => ({
            emptyBagWeight: new Array(this.number_of_powder_amount_measures).fill(null),
            fullBagWeight: new Array(this.number_of_powder_amount_measures).fill(null),
          }));
          }
          this.powder_amount_calibration_measures_input_error = Array.from({ length: this.total_pistols }, () => ({
            emptyBagWeight: new Array(this.number_of_powder_amount_measures).fill(false),
            fullBagWeight: new Array(this.number_of_powder_amount_measures).fill(false),
          }));
          return;
      } catch (error) {
        console.error(error)
        setTimeout(function() {
          this.getPowderAmountCalibrationMeasures();
        }.bind(this), 5000);
      }
    },
    async updatePowderAmountParametersFromRange(){
      if (this.powder_amount_min_param != null && this.powder_amount_max_param != null) {
        let body = {
          "num_measures": this.number_of_powder_amount_measures,
          "powder_amount_min_param": this.powder_amount_min_param,
          "powder_amount_max_param": this.powder_amount_max_param
      }
      try {
        let response = await axios.post('/api/v1/fp/computepowderamountparameters/', body)
        this.powder_amount_parameters = response.data["powder_amount_params"];
        console.log('powder_amount_parameters', this.powder_amount_parameters)
        this.powder_amount_calibration_measures
      } catch (error) {
        console.error(error)
        setTimeout(function() {
          this.updatePowderAmountParametersFromRange();
        }.bind(this), 5000);
      }
    }
    },
    async createPowderAmountCalibration() {
      let powder_amount_properly_checked = this.PowderAmountFieldsProperlyFilled();
      let properly_filled = powder_amount_properly_checked[0];
      let missing_fields = powder_amount_properly_checked[1];

      if (!properly_filled) {
        this.$swal({
          title: this.$t('Missing Required Fields'),
          text: `${this.$t('The following fields are required:')} ${missing_fields.join(", ")}`,
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return
      }

      let powder_amount_formated_measures = this.prepareDataForApi();
      let body = {
        "line": this.lineId,
        "name": this.powder_amount_name,
        "time_interval": this.powder_amount_time_interval,
        "number_of_measures": this.number_of_powder_amount_measures,
        "measures_list": powder_amount_formated_measures,
        "powder_amount_min_param": this.powder_amount_min_param,
        "powder_amount_max_param": this.powder_amount_max_param,
        "powdermodel": this.powder_amount_selected_powder_model,
        "library_display": true,
      }

      let response = await axios
        .post('/api/v1/fp/powderamountcalibrationmeasures/', body)
        .catch(error => {
          console.error('Error:', error.response || error);
        })
      console.log('response', response.data)
      let guns_in_proper_state = await this.gunsInProperState(JSON.parse(powder_amount_formated_measures));
      if (guns_in_proper_state.length > 0) {
        this.$swal({
          title: this.$t('Guns in Improper State'),
          text: `${this.$t('Gun')} ${guns_in_proper_state.join(", ")} ${this.$t('has a deviation above 20%.')} ${this.$t('Consider reviewing the state of the guns.')}
          ${this.$t('Do you want to create it anyway?')}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: `${this.$t("Yes, create it")}`,
          cancelButtonText: `${this.$t("No, cancel")}`,
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then(async result => {
        if (!result.isConfirmed) {
          await axios
              .patch(
                "/api/v1/fp/powderamountcalibrationmeasures/" + response.data.id + "/",
                { "library_display": false });
        } else {
          this.$swal({
            title: this.$t('Powder Amount Calibration Created'),
            text: this.powder_amount_name + ' ' + this.$t('was saved successfully'),
            icon: 'success',
            confirmButtonText: 'OK'
          });
          this.powder_amount_calibration_id = response.data.id
          router.push({ path: '/applications/flightpath/', replace: true });
        }
        });
      } else {
        this.$swal({
            title: this.$t('Powder Amount Calibration Created'),
            text: this.powder_amount_name + ' ' + this.$t('was saved successfully'),
            icon: 'success',
            confirmButtonText: 'OK'
          });
          this.powder_amount_calibration_id = response.data.id
          router.push({ path: '/applications/flightpath/', replace: true });
      }
    },
    prepareDataForApi() {
      const formattedData = this.powder_amount_calibration_measures.map(gun =>
        this.powder_amount_parameters.map((amount, index) => {
          const emptyBagWeight = gun.emptyBagWeight[index];
          const fullBagWeight = gun.fullBagWeight[index];
          return (amount != null && emptyBagWeight != null && fullBagWeight) ? [amount, emptyBagWeight, fullBagWeight] : null;
        })
        .filter(item => item !== null)  // Filter out null tuples
      );

      return JSON.stringify(formattedData);
    },
    async gunsInProperState(formattedData) {
      let calibration_measures = this.mapIncomingData(formattedData);
      let mid_point = parseInt(this.powder_amount_min_param + (this.powder_amount_max_param - this.powder_amount_min_param) / 2);
      let body = {
        "line": this.lineId,
        "time_interval": this.powder_amount_time_interval,
        "measures_list": JSON.stringify(calibration_measures),
        "powder_per_minute": null,
        "gun_idx": 1,
        "powder_amount_param": mid_point,
      }
      let response = await axios.post('/api/v1/fp/computepowderamountcalibration/', body)
        .catch(error => {
          console.error('Error:', error.response || error);
        })
      console.log('In proper state response', response.data)
      let powder_amount_params = response.data.powder_amount_params;
      let median_of_powder_amount_params = this.findMedian(powder_amount_params);
      let range = this.powder_amount_max_param - this.powder_amount_min_param;
      let deviations = this.computeDeviations(powder_amount_params, median_of_powder_amount_params, range);
      let deviations_above_20 = deviations.map((deviation, index) => deviation > 20 ? index + 1 : null).filter(item => item !== null);

      return deviations_above_20;
    },
    findMedian(values) {
      if (values.length === 0) {
        return null;
      }
      if (values.length === 2) {
        return Math.min(...values);
      }
      let sorted_values = values.slice().sort((a, b) => a - b);
      const half = Math.floor(sorted_values.length / 2);
      if (sorted_values.length % 2) {
        return sorted_values[half];
      }
      return (sorted_values[half - 1] + sorted_values[half]) / 2.0;
    },
    computeDeviations(values, median, range) {
      let deviations = values.map(value => Math.abs(value - median) / range * 100);
      return deviations;
    },
    updateMeasurement(gunIndex, measureType, measureIndex, value) {
      let convertedValue = parseFloat(value);
      if (convertedValue < 0) {
        this.powder_amount_calibration_measures[gunIndex][measureType][measureIndex] = "";
        this.$swal({
          title: this.$t('Invalid Value'),
          text: this.$t('Please enter a positive value'),
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return;
      }
      if (measureType === 'fullBagWeight' && convertedValue < this.powder_amount_calibration_measures[gunIndex]['emptyBagWeight'][measureIndex]) {
        this.powder_amount_calibration_measures[gunIndex][measureType][measureIndex] = "";
        this.powder_amount_calibration_measures[gunIndex]['emptyBagWeight'][measureIndex] = "";
        this.$swal({
          title: this.$t('Invalid Value'),
          text: this.$t('Full Bag Weight cannot be less than Empty Bag Weight'),
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return;
      }
      if (measureType !== 'powderAmount') {
        convertedValue = this.convertMeasurement(parseFloat(value), 'set');
      }
      this.powder_amount_calibration_measures[gunIndex][measureType][measureIndex] = convertedValue;
    },
    convertMeasurement(measurement, method) {
      if (method == 'get') {
        if (measurement == null || measurement == "" || isNaN(measurement)) {
          return measurement;
        }
        if (this.$store.state.user_data.unit_system === 'imperial') {
          let convertedValue = parseFloat((measurement * this.$store.state.conversion_factors.g_to_oz).toFixed(this.max_imperial_decimals));
          return convertedValue;
        } else if (this.$store.state.user_data.unit_system === 'metric') {
          let convertedValue = parseFloat(measurement.toFixed(this.max_metric_decimals));
          return convertedValue;
        } else {
          return null
        }
      } else if (method == 'set') {
        if (this.$store.state.user_data.unit_system === 'imperial') {
          let convertedValue = parseFloat((measurement / this.$store.state.conversion_factors.g_to_oz).toFixed(this.max_metric_decimals));
          return convertedValue;
        } else if (this.$store.state.user_data.unit_system === 'metric') {
          let convertedValue = parseFloat(measurement.toFixed(this.max_metric_decimals));
          return convertedValue;
        } else {
          return null
        }
      }
    },
    fillPowderAmountCalibrationCanvas() {
      let gun_layout= this.powder_amount_calibration_image;
      let gun_image = new ImageData(
        Uint8ClampedArray.from(gun_layout.values()),
        this.canvas_width,
        this.canvas_height
      );
      this.powder_amount_calibration_canvas.putImageData(gun_image, 0, 0);
    },
    async getPowderAmountCalibrationImage() {
      try{
        var body = {
          "line": this.lineId,
          "canvas_width": this.canvas_width,
          "canvas_height": this.canvas_height,
        }
        var response = await axios.post('/api/v1/fp/computepowderamountlineimage/', body);
        this.powder_amount_calibration_image = response.data["layout_image"];
      }
      catch(error){
        console.error(error)
        setTimeout(function () {
          this.getPowderAmountCalibrationImage();
        }.bind(this), 5000);
      }
    },
    async getData() {
      await this.getLine();
      await this.getPowderAmountCalibrationMeasures();
      await this.getCalibratedPowderModels();
      await this.getPowderAmountCalibrationImage();
      this.fillPowderAmountCalibrationCanvas();
    },
    async getLine() {
      let line_response = await axios.get('/api/v1/fp/line/' + this.lineId)

      line_response.data.total_pistols = JSON.parse(line_response.data.total_pistols)
      line_response.data.pistol_to_pistol_distance = JSON.parse(line_response.data.pistol_to_pistol_distance)
      line_response.data.pistol_columns_distances = JSON.parse(line_response.data.pistol_columns_distances)
      line_response.data.pistol_columns_vertical_offsets = JSON.parse(line_response.data.pistol_columns_vertical_offsets)

      line_response.data.pistols_max_movement_range = line_response.data.pistols_max_movement_range * 100;

      this.line = line_response.data;
      this.total_pistols = this.line.total_pistols[0];

    },
    updatePowderAmountCalibrationMeasures(newMeasureCount) {
      this.powder_amount_calibration_measures.forEach(measure => {
        if (measure.emptyBagWeight.length < newMeasureCount) {
          while (measure.emptyBagWeight.length < newMeasureCount) {
            measure.emptyBagWeight.push(null);
          }
        } else if (measure.emptyBagWeight.length > newMeasureCount) {
          measure.emptyBagWeight.length = newMeasureCount;
        }

        if (measure.fullBagWeight.length < newMeasureCount) {
          while (measure.fullBagWeight.length < newMeasureCount) {
            measure.fullBagWeight.push(null);
          }
        } else if (measure.fullBagWeight.length > newMeasureCount) {
          measure.fullBagWeight.length = newMeasureCount;
          this.currentPage = 0;
        }
      });
      this.powder_amount_calibration_measures_input_error.forEach(measure => {
        if (measure.emptyBagWeight.length < newMeasureCount) {
          while (measure.emptyBagWeight.length < newMeasureCount) {
            measure.emptyBagWeight.push(false);
          }
        } else if (measure.emptyBagWeight.length > newMeasureCount) {
          measure.emptyBagWeight.length = newMeasureCount;
        }

        if (measure.fullBagWeight.length < newMeasureCount) {
          while (measure.fullBagWeight.length < newMeasureCount) {
            measure.fullBagWeight.push(false);
          }
        } else if (measure.fullBagWeight.length > newMeasureCount) {
          measure.fullBagWeight.length = newMeasureCount;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.main-area {
  display: flex;
}

.grid-container {
  border: 2px solid black;
  width: 100%;
  height: 100%;
  padding-top: 60%;
  overflow: auto;
  position: relative;
}

#grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #333;
  position: absolute;
}

.row-controls {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.hidden-div {
  display: none;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
}

.dropdown-menu.show {
  display: block;
  width: 100%; /* Ensure the dropdown menu matches the width of the button */
}

.dropdown-item {
  padding: 0.25rem 1.5rem;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

</style>
<i18n>
{
  "EN": {
    "Powder Amount Calibration": "Powder Amount Calibration",
    "Name": "Name",
    "Number of Measures": "Number of Measures",
    "Please select one": "Please select one",
    "Time Interval": "Time Interval",
    "Min Powder Amount Param": "Min Powder Amount Param",
    "Max Powder Amount Param": "Max Powder Amount Param",
    "Select Powder Model": "Select Powder Model",
    "optional": "optional",
    "Not Specified": "Not Specified",
    "Calibration Steps": "Calibration Steps",
    "Prepare the Vacuum Bags:": "Prepare the Vacuum Bags:",
    "Select a vacuum bag for each gun.": "Select a vacuum bag for each gun.",
    "Weigh the empty vacuum bag and record its weight.": "Weigh the empty vacuum bag and record its weight.",
    "Set Up the Machine:": "Set Up the Machine:",
    "Attach the vacuum bag to the tip of the gun.": "Attach the vacuum bag to the tip of the gun.",
    "Turn on the machine using the specified setting for the defined time interval.": "Turn on the machine using the specified setting for the defined time interval.",
    "Weigh the Bags:": "Weigh the Bags:",
    "After the machine runs for the set time, weigh each vacuum bag again.": "After the machine runs for the set time, weigh each vacuum bag again.",
    "Record the new weight of each bag.": "Record the new weight of each bag.",
    "Repeat for Different Parameters:": "Repeat for Different Parameters:",
    "Empty the vacuum bags before proceeding to the next parameter.": "Empty the vacuum bags before proceeding to the next parameter.",
    "Repeat the entire process for each new parameter setting.": "Repeat the entire process for each new parameter setting.",
    "Set your machine's powder amount setting to:": "Set your machine's powder amount setting to:",
    "Gun": "Gun",
    "Empty Bag Weight": "Empty Bag Weight",
    "Full Bag Weight": "Full Bag Weight",
    "Back to Powder Amount Param": "Back to Powder Amount Param",
    "Continue to Powder Amount Param": "Continue to Powder Amount Param",
    "Save Powder Amount Calibration": "Save Powder Amount Calibration",
    "Missing Required Fields": "Missing Required Fields",
    "Measurement": "Measurement",
    "The following fields are required:": "The following fields are required:",
    "Guns in Improper State": "Guns in Improper State",
    "Powder Amount Calibration failed.": "Powder Amount Calibration failed.",
    "has a deviation above 20%.": "has a deviation above 20%.",
    "Consider reviewing the state of the guns.": "Consider reviewing the state of the guns.",
    "Powder Amount Calibration Created": "Powder Amount Calibration Created",
    "was saved successfully": "was saved successfully",
    "Invalid Value": "Invalid Value",
    "Please enter a positive value": "Please enter a positive value",
    "Full Bag Weight cannot be less than Empty Bag Weight": "Full Bag Weight cannot be less than Empty Bag Weight",
    "Do you want to create it anyway?": "Do you want to create it anyway?",
    "Yes, create it": "Yes, create it",
    "No, cancel": "No, cancel",
  },
  "DE": {
    "Powder Amount Calibration": "Pulvermengenkalibrierung",
    "Name": "Name",
    "Number of Measures": "Anzahl der Messungen",
    "Please select one": "Bitte wählen Sie eine",
    "Time Interval": "Zeitintervall",
    "Min Powder Amount Param": "Mindestpulvermengenparameter",
    "Max Powder Amount Param": "Maximaler Pulvermengenparameter",
    "Select Powder Model": "Pulvermodell auswählen",
    "optional": "optional",
    "Not Specified": "Nicht spezifiziert",
    "Calibration Steps": "Kalibrierschritte",
    "Prepare the Vacuum Bags:": "Bereiten Sie die Vakuumbeutel vor:",
    "Select a vacuum bag for each gun.": "Wählen Sie einen Vakuumbeutel für jede Waffe aus.",
    "Weigh the empty vacuum bag and record its weight.": "Wiegen Sie den leeren Vakuumbeutel und notieren Sie sein Gewicht.",
    "Set Up the Machine:": "Maschine einrichten:",
    "Attach the vacuum bag to the tip of the gun.": "Befestigen Sie den Vakuumbeutel an der Spitze der Waffe.",
    "Turn on the machine using the specified setting for the defined time interval.": "Schalten Sie die Maschine mit der angegebenen Einstellung für das definierte Zeitintervall ein.",
    "Weigh the Bags:": "Wiegen Sie die Taschen:",
    "After the machine runs for the set time, weigh each vacuum bag again.": "Wiegen Sie nach Ablauf der eingestellten Zeit erneut jeden Vakuumbeutel.",
    "Record the new weight of each bag.": "Notieren Sie das neue Gewicht jedes Beutels.",
    "Repeat for Different Parameters:": "Wiederholen für verschiedene Parameter:",
    "Empty the vacuum bags before proceeding to the next parameter.": "Leeren Sie die Vakuumbeutel, bevor Sie zum nächsten Parameter übergehen.",
    "Repeat the entire process for each new parameter setting.": "Wiederholen Sie den gesamten Prozess für jede neue Parameter-Einstellung.",
    "Set your machine's powder amount setting to:": "Stellen Sie die Pulvermengeneinstellung Ihrer Maschine auf:",
    "Gun": "Waffe",
    "Empty Bag Weight": "Leergewicht des Beutels",
    "Full Bag Weight": "Vollgewicht des Beutels",
    "Back to Powder Amount Param": "Zurück zum Pulvermengenparameter",
    "Continue to Powder Amount Param": "Weiter zum Pulvermengenparameter",
    "Save Powder Amount Calibration": "Pulvermengenkalibrierung speichern",
    "Missing Required Fields": "Erforderliche Felder fehlen",
    "Measurement": "Messung",
    "The following fields are required:": "Die folgenden Felder sind erforderlich:",
    "Guns in Improper State": "Waffen in unangemessenem Zustand",
    "Powder Amount Calibration failed.": "Pulvermengenkalibrierung fehlgeschlagen.",
    "has a deviation above 20%.": "hat eine Abweichung von über 20%.",
    "Consider reviewing the state of the guns.": "Überprüfen Sie den Zustand der Waffen.",
    "Powder Amount Calibration Created": "Pulvermengenkalibrierung erstellt",
    "was saved successfully": "wurde erfolgreich gespeichert",
    "Invalid Value": "Ungültiger Wert",
    "Please enter a positive value": "Bitte geben Sie einen positiven Wert ein",
    "Full Bag Weight cannot be less than Empty Bag Weight": "Das Vollgewicht des Beutels darf nicht kleiner sein als das Leergewicht des Beutels",
    "Do you want to create it anyway?": "Möchten Sie es trotzdem erstellen?",
    "Yes, create it": "Ja, erstellen",
    "No, cancel": "Nein, abbrechen",
  },
  "ES": {
    "Powder Amount Calibration": "Calibración de la flujo de pintura",
    "Name": "Nombre",
    "Number of Measures": "Número de medidas",
    "Please select one": "Por favor, seleccione uno",
    "Time Interval": "Intervalo de tiempo",
    "Min Powder Amount Param": "Parámetro de flujo de pintura mínimo",
    "Max Powder Amount Param": "Parámetro de flujo de pintura máximo",
    "Select Powder Model": "Seleccionar modelo de polvo",
    "optional": "opcional",
    "Not Specified": "No especificado",
    "Calibration Steps": "Pasos de calibración",
    "Prepare the Vacuum Bags:": "Preparar las bolsas de aspiradora:",
    "Select a vacuum bag for each gun.": "Seleccione una bolsa de aspiradora para cada pistola.",
    "Weigh the empty vacuum bag and record its weight.": "Pese la bolsa de aspiradora vacía y registre su peso.",
    "Set Up the Machine:": "Configurar la máquina:",
    "Attach the vacuum bag to the tip of the gun.": "Fije la bolsa de aspiradora en la punta del pistola.",
    "Turn on the machine using the specified setting for the defined time interval.": "Encienda la máquina utilizando la configuración especificada para el intervalo de tiempo definido.",
    "Weigh the Bags:": "Pese las bolsas:",
    "After the machine runs for the set time, weigh each vacuum bag again.": "Después de que la máquina funcione durante el tiempo establecido, pese cada bolsa de aspiradora nuevamente.",
    "Record the new weight of each bag.": "Registre el nuevo peso de cada bolsa.",
    "Repeat for Different Parameters:": "Repetir para diferentes parámetros:",
    "Empty the vacuum bags before proceeding to the next parameter.": "Vacíe las bolsas de aspiradora antes de pasar al siguiente parámetro.",
    "Repeat the entire process for each new parameter setting.": "Repita todo el proceso para cada nueva configuración de parámetros.",
    "Set your machine's powder amount setting to:": "Ajuste la configuración de la flujo de pintura de su máquina a:",
    "Gun":"Pistola",
    "Empty Bag Weight": "Peso de la bolsa vacía",
    "Full Bag Weight": "Peso de la bolsa llena",
    "Back to Powder Amount Param": "Volver al parámetro de flujo de pintura",
    "Continue to Powder Amount Param": "Continuar con el parámetro de flujo de pintura",
    "Save Powder Amount Calibration": "Guardar calibración de la flujo de pintura",
    "Missing Required Fields": "Campos obligatorios faltantes",
    "Measurement": "Medición",
    "The following fields are required:": "Los siguientes campos son obligatorios:",
    "Guns in Improper State": "Pistolas en estado inadecuado",
    "Powder Amount Calibration failed.": "Falló la calibración de la flujo de pintura.",
    "has a deviation above 20%.": "tiene una desviación superior al 20%.",
    "Consider reviewing the state of the guns.": "Considere revisar el estado de las pistolas.",
    "Powder Amount Calibration Created": "Calibración de la flujo de pintura creada",
    "was saved successfully": "se guardó con éxito",
    "Invalid Value": "Valor inválido",
    "Please enter a positive value": "Por favor, introduzca un valor positivo",
    "Full Bag Weight cannot be less than Empty Bag Weight": "El peso de la bolsa llena no puede ser menor que el peso de la bolsa vacía",
    "Do you want to create it anyway?": "¿Desea crearlo de todos modos?",
    "Yes, create it": "Sí, créalo",
    "No, cancel": "No, cancelar",
  },
  "FR": {
    "Powder Amount Calibration": "Calibration de la quantité de poudre",
    "Name": "Nom",
    "Number of Measures": "Nombre de mesures",
    "Please select one": "Veuillez en sélectionner un",
    "Time Interval": "Intervalle de temps",
    "Min Powder Amount Param": "Paramètre de quantité de poudre minimale",
    "Max Powder Amount Param": "Paramètre de quantité de poudre maximale",
    "Select Powder Model": "Sélectionnez le modèle de poudre",
    "optional": "optionnel",
    "Not Specified": "Non spécifié",
    "Calibration Steps": "Étapes de calibration",
    "Prepare the Vacuum Bags:": "Préparez les sacs sous vide :",
    "Select a vacuum bag for each gun.": "Sélectionnez un sac sous vide pour chaque arme.",
    "Weigh the empty vacuum bag and record its weight.": "Pesez le sac sous vide vide et enregistrez son poids.",
    "Set Up the Machine:": "Configurer la machine :",
    "Attach the vacuum bag to the tip of the gun.": "Fixez le sac sous vide à l'extrémité de l'arme.",
    "Turn on the machine using the specified setting for the defined time interval.": "Allumez la machine en utilisant le réglage spécifié pour l'intervalle de temps défini.",
    "Weigh the Bags:": "Pesez les sacs :",
    "After the machine runs for the set time, weigh each vacuum bag again.": "Après que la machine a fonctionné pendant le temps défini, pesez à nouveau chaque sac sous vide.",
    "Record the new weight of each bag.": "Enregistrez le nouveau poids de chaque sac.",
    "Repeat for Different Parameters:": "Répétez pour différents paramètres :",
    "Empty the vacuum bags before proceeding to the next parameter.": "Videz les sacs sous vide avant de passer au paramètre suivant.",
    "Repeat the entire process for each new parameter setting.": "Répétez l'ensemble du processus pour chaque nouvelle configuration de paramètres.",
    "Set your machine's powder amount setting to:": "Réglez la quantité de poudre de votre machine sur :",
    "Gun": "Arme",
    "Empty Bag Weight": "Poids du sac vide",
    "Full Bag Weight": "Poids du sac plein",
    "Back to Powder Amount Param": "Retour au paramètre de quantité de poudre",
    "Continue to Powder Amount Param": "Continuer au paramètre de quantité de poudre",
    "Save Powder Amount Calibration": "Enregistrer la calibration de la quantité de poudre",
    "Missing Required Fields": "Champs obligatoires manquants",
    "Measurement": "Mesure",
    "The following fields are required:": "Les champs suivants sont obligatoires :",
    "Guns in Improper State": "Armes en mauvais état",
    "Powder Amount Calibration failed.": "Échec de la calibration de la quantité de poudre.",
    "has a deviation above 20%.": "a une déviation supérieure à 20%.",
    "Consider reviewing the state of the guns.": "Envisagez de revoir l'état des armes.",
    "Powder Amount Calibration Created": "Calibration de la quantité de poudre créée",
    "was saved successfully": "a été enregistrée avec succès",
    "Invalid Value": "Valeur invalide",
    "Please enter a positive value": "Veuillez entrer une valeur positive",
    "Full Bag Weight cannot be less than Empty Bag Weight": "Le poids du sac plein ne peut pas être inférieur au poids du sac vide",
    "Do you want to create it anyway?": "Voulez-vous le créer quand même?",
    "Yes, create it": "Oui, créez-le",
    "No, cancel": "Non, annuler",
  },
  "IT": {
    "Powder Amount Calibration": "Calibrazione della quantità di polvere",
    "Name": "Nome",
    "Number of Measures": "Numero di misure",
    "Please select one": "Seleziona uno",
    "Time Interval": "Intervallo di tempo",
    "Min Powder Amount Param": "Parametro minimo della quantità di polvere",
    "Max Powder Amount Param": "Parametro massimo della quantità di polvere",
    "Select Powder Model": "Seleziona il modello di polvere",
    "optional": "opzionale",
    "Not Specified": "Non specificato",
    "Calibration Steps": "Passaggi di calibrazione",
    "Prepare the Vacuum Bags:": "Prepara i sacchetti sottovuoto:",
    "Select a vacuum bag for each gun.": "Seleziona un sacchetto sottovuoto per ogni pistola.",
    "Weigh the empty vacuum bag and record its weight.": "Pesare il sacchetto sottovuoto vuoto e registrare il suo peso.",
    "Set Up the Machine:": "Configura la macchina:",
    "Attach the vacuum bag to the tip of the gun.": "Attacca il sacchetto sottovuoto all'estremità della pistola.",
    "Turn on the machine using the specified setting for the defined time interval.": "Accendi la macchina utilizzando l'impostazione specificata per l'intervallo di tempo definito.",
    "Weigh the Bags:": "Pesare i sacchetti:",
    "After the machine runs for the set time, weigh each vacuum bag again.": "Dopo che la macchina è stata in funzione per il tempo stabilito, pesare nuovamente ciascun sacchetto sottovuoto.",
    "Record the new weight of each bag.": "Registra il nuovo peso di ciascun sacchetto.",
    "Repeat for Different Parameters:": "Ripeti per diversi parametri:",
    "Empty the vacuum bags before proceeding to the next parameter.": "Svuota i sacchetti sottovuoto prima di passare al parametro successivo.",
    "Repeat the entire process for each new parameter setting.": "Ripeti l'intero processo per ciascuna nuova impostazione del parametro.",
    "Set your machine's powder amount setting to:": "Imposta la quantità di polvere della tua macchina su:",
    "Gun": "Pistola",
    "Empty Bag Weight": "Peso del sacchetto vuoto",
    "Full Bag Weight": "Peso del sacchetto pieno",
    "Back to Powder Amount Param": "Torna al parametro della quantità di polvere",
    "Continue to Powder Amount Param": "Continua al parametro della quantità di polvere",
    "Save Powder Amount Calibration": "Salva la calibrazione della quantità di polvere",
    "Missing Required Fields": "Campi obbligatori mancanti",
    "Measurement": "Misura",
    "The following fields are required:": "I seguenti campi sono obbligatori:",
    "Guns in Improper State": "Armi in stato improprio",
    "Powder Amount Calibration failed.": "Calibrazione della quantità di polvere fallita.",
    "has a deviation above 20%.": "ha una deviazione superiore al 20%.",
    "Consider reviewing the state of the guns.": "Considera di rivedere lo stato delle armi.",
    "Powder Amount Calibration Created": "Calibrazione della quantità di polvere creata",
    "was saved successfully": "è stato salvato con successo",
    "Invalid Value": "Valore non valido",
    "Please enter a positive value": "Si prega di inserire un valore positivo",
    "Full Bag Weight cannot be less than Empty Bag Weight": "Il peso del sacchetto pieno non può essere inferiore al peso del sacchetto vuoto",
    "Do you want to create it anyway?": "Vuoi crearlo comunque?",
    "Yes, create it": "Sì, crealo",
    "No, cancel": "No, annulla",
  },
  "PL": {
    "Powder Amount Calibration": "Kalibracja ilości proszku",
    "Name": "Nazwa",
    "Number of Measures": "Liczba pomiarów",
    "Please select one": "Wybierz jedno",
    "Time Interval": "Interwał czasu",
    "Min Powder Amount Param": "Minimalny parametr ilości proszku",
    "Max Powder Amount Param": "Maksymalny parametr ilości proszku",
    "Select Powder Model": "Wybierz model proszku",
    "optional": "opcjonalne",
    "Not Specified": "Nie określono",
    "Calibration Steps": "Kroki kalibracji",
    "Prepare the Vacuum Bags:": "Przygotuj worki próżniowe:",
    "Select a vacuum bag for each gun.": "Wybierz worek próżniowy dla każdej broni.",
    "Weigh the empty vacuum bag and record its weight.": "Zważ pusty worek próżniowy i zapisz jego wagę.",
    "Set Up the Machine:": "Skonfiguruj maszynę:",
    "Attach the vacuum bag to the tip of the gun.": "Przymocuj worek próżniowy do końca broni.",
    "Turn on the machine using the specified setting for the defined time interval.": "Włącz maszynę, korzystając z określonego ustawienia dla zdefiniowanego interwału czasu.",
    "Weigh the Bags:": "Zważ worki:",
    "After the machine runs for the set time, weigh each vacuum bag again.": "Po upływie ustalonego czasu pracy maszyny zważ ponownie każdy worek próżniowy.",
    "Record the new weight of each bag.": "Zapisz nową wagę każdego worka.",
    "Repeat for Different Parameters:": "Powtórz dla różnych parametrów:",
    "Empty the vacuum bags before proceeding to the next parameter.": "Opróżnij worki próżniowe przed przejściem do następnego parametru.",
    "Repeat the entire process for each new parameter setting.": "Powtórz cały proces dla każdej nowej konfiguracji parametrów.",
    "Set your machine's powder amount setting to:": "Ustaw ilość proszku w maszynie na:",
    "Gun": "Broń",
    "Empty Bag Weight": "Waga pustego worka",
    "Full Bag Weight": "Waga pełnego worka",
    "Back to Powder Amount Param": "Powrót do parametru ilości proszku",
    "Continue to Powder Amount Param": "Kontynuuj do parametru ilości proszku",
    "Save Powder Amount Calibration": "Zapisz kalibrację ilości proszku",
    "Missing Required Fields": "Brakujące wymagane pola",
    "Measurement": "Pomiar",
    "The following fields are required:": "Następujące pola są wymagane:",
    "Guns in Improper State": "Broń w niewłaściwym stanie",
    "Powder Amount Calibration failed.": "Kalibracja ilości proszku nie powiodła się.",
    "has a deviation above 20%.": "ma odchylenie powyżej 20%.",
    "Consider reviewing the state of the guns.": "Rozważ ponowne sprawdzenie stanu broni.",
    "Powder Amount Calibration Created": "Utworzono kalibrację ilości proszku",
    "was saved successfully": "został pomyślnie zapisany",
    "Invalid Value": "Nieprawidłowa wartość",
    "Please enter a positive value": "Proszę podać wartość dodatnią",
    "Full Bag Weight cannot be less than Empty Bag Weight": "Waga pełnego worka nie może być mniejsza niż waga pustego worka",
    "Do you want to create it anyway?": "Czy chcesz je utworzyć mimo to?",
    "Yes, create it": "Tak, utwórz",
    "No, cancel": "Nie, anuluj",
  },
  "TR": {
    "Powder Amount Calibration": "Toz Miktarı Kalibrasyonu",
    "Name": "Ad",
    "Number of Measures": "Ölçü Sayısı",
    "Please select one": "Lütfen birini seçin",
    "Time Interval": "Zaman Aralığı",
    "Min Powder Amount Param": "Min Toz Miktarı Parametresi",
    "Max Powder Amount Param": "Maks Toz Miktarı Parametresi",
    "Select Powder Model": "Toz Modeli Seç",
    "optional": "isteğe bağlı",
    "Not Specified": "Belirtilmedi",
    "Calibration Steps": "Kalibrasyon Adımları",
    "Prepare the Vacuum Bags:": "Vakum Torbalarını Hazırla:",
    "Select a vacuum bag for each gun.": "Her tabanca için bir vakum torbası seçin.",
    "Weigh the empty vacuum bag and record its weight.": "Boş vakum torbasını tartın ve ağırlığını kaydedin.",
    "Set Up the Machine:": "Makineyi Kur:",
    "Attach the vacuum bag to the tip of the gun.": "Vakum torbasını tabancanın ucuna takın.",
    "Turn on the machine using the specified setting for the defined time interval.": "Belirtilen ayarları kullanarak makineyi belirlenen zaman aralığında açın.",
    "Weigh the Bags:": "Torbaları Tartın:",
    "After the machine runs for the set time, weigh each vacuum bag again.": "Makine belirlenen süre çalıştıktan sonra her vakum torbasını tekrar tartın.",
    "Record the new weight of each bag.": "Her torbanın yeni ağırlığını kaydedin.",
    "Repeat for Different Parameters:": "Farklı Parametreler İçin Tekrarlayın:",
    "Empty the vacuum bags before proceeding to the next parameter.": "Bir sonraki parametreye geçmeden önce vakum torbalarını boşaltın.",
    "Repeat the entire process for each new parameter setting.": "Her yeni parametre ayarı için tüm süreci tekrarlayın.",
    "Set your machine's powder amount setting to:": "Makinenizin toz miktarı ayarını şuna ayarlayın:",
    "Gun": "Tabanca",
    "Empty Bag Weight": "Boş Torba Ağırlığı",
    "Full Bag Weight": "Dolu Torba Ağırlığı",
    "Back to Powder Amount Param": "Toz Miktarı Parametresine Geri Dön",
    "Continue to Powder Amount Param": "Toz Miktarı Parametresine Devam Et",
    "Save Powder Amount Calibration": "Toz Miktarı Kalibrasyonunu Kaydet",
    "Missing Required Fields": "Eksik Gerekli Alanlar",
    "Measurement": "Ölçüm",
    "The following fields are required:": "Aşağıdaki alanlar gereklidir:",
    "Guns in Improper State": "Tabancalar Uygun Olmayan Durumda",
    "Powder Amount Calibration failed.": "Toz Miktarı Kalibrasyonu başarısız oldu.",
    "has a deviation above 20%.": "20%'nin üzerinde bir sapma var.",
    "Consider reviewing the state of the guns.": "Tabancaların durumunu gözden geçirmeyi düşünün.",
    "Powder Amount Calibration Created": "Toz Miktarı Kalibrasyonu Oluşturuldu",
    "was saved successfully": "başarıyla kaydedildi",
    "Invalid Value": "Geçersiz Değer",
    "Please enter a positive value": "Lütfen pozitif bir değer girin",
    "Full Bag Weight cannot be less than Empty Bag Weight": "Dolu Torba Ağırlığı Boş Torba Ağırlığından küçük olamaz",
    "Do you want to create it anyway?": "Yine de oluşturmak ister misiniz?",
    "Yes, create it": "Evet, oluştur",
    "No, cancel": "Hayır, iptal",
  },
  "ZH": {
    "Powder Amount Calibration": "粉量校准",
    "Name": "名称",
    "Number of Measures": "测量次数",
    "Please select one": "请选择一个",
    "Time Interval": "时间间隔",
    "Min Powder Amount Param": "最小粉量参数",
    "Max Powder Amount Param": "最大粉量参数",
    "Select Powder Model": "选择粉末模型",
    "optional": "可选",
    "Not Specified": "未指定",
    "Calibration Steps": "校准步骤",
    "Prepare the Vacuum Bags:": "准备真空袋：",
    "Select a vacuum bag for each gun.": "为每支枪选择一个真空袋。",
    "Weigh the empty vacuum bag and record its weight.": "称量空真空袋并记录其重量。",
    "Set Up the Machine:": "设置机器：",
    "Attach the vacuum bag to the tip of the gun.": "将真空袋连接到枪的尖端。",
    "Turn on the machine using the specified setting for the defined time interval.": "使用定义的时间间隔的指定设置打开机器。",
    "Weigh the Bags:": "称量袋子：",
    "After the machine runs for the set time, weigh each vacuum bag again.": "机器运行一段时间后，再次称量每个真空袋。",
    "Record the new weight of each bag.": "记录每个袋子的新重量。",
    "Repeat for Different Parameters:": "重复不同参数：",
    "Empty the vacuum bags before proceeding to the next parameter.": "在继续下一个参数之前清空真空袋。",
    "Repeat the entire process for each new parameter setting.": "为每个新参数设置重复整个过程。",
    "Set your machine's powder amount setting to:": "将机器的粉量设置为：",
    "Gun": "枪",
    "Empty Bag Weight": "空袋重量",
    "Full Bag Weight": "满袋重量",
    "Back to Powder Amount Param": "返回到粉量参数",
    "Continue to Powder Amount Param": "继续到粉量参数",
    "Save Powder Amount Calibration": "保存粉量校准",
    "Missing Required Fields": "缺少必填字段",
    "Measurement": "测量",
    "The following fields are required:": "以下字段是必需的：",
    "Guns in Improper State": "枪支状态不当",
    "Powder Amount Calibration failed.": "粉量校准失败。",
    "has a deviation above 20%.": "偏差超过20%。",
    "Consider reviewing the state of the guns.": "考虑审查枪支的状态。",
    "Powder Amount Calibration Created": "粉量校准已创建",
    "was saved successfully": "已成功保存",
    "Invalid Value": "无效值",
    "Please enter a positive value": "请输入正值",
    "Full Bag Weight cannot be less than Empty Bag Weight": "满袋重量不能小于空袋重量",
    "Do you want to create it anyway?": "您仍要创建吗？",
    "Yes, create it": "是的，创建",
    "No, cancel": "不，取消",
  },
}
</i18n>