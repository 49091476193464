export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Workflows for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflows for Line"])},
        "New Workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Workflow"])}
      },
      "DE": {
        "Workflows for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflows for Line"])},
        "New Workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Workflowlowlow"])}
      },
      "ES": {
        "Workflows for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflows for Line"])},
        "New Workflowlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Workflowlowlowlow"])}
      },
      "FR": {
        "Workflows for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflows for Line"])},
        "New Workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Workflow"])}
      },
      "IT": {
        "Workflows for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflows for Line"])},
        "New Workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Workflowlowlow"])}
      },
      "PL": {
        "Workflows for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflows for Line"])},
        "New Workflowlowlowlowlowlowlowlowlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Workflowlow"])}
      },
      "TR": {
        "Workflows for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflows for Line"])},
        "New Workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Workflow"])}
      },
      "ZH": {
        "Workflows for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflows for Line"])},
        "New Workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Workflow"])}
      }
    }
  })
}
