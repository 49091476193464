<template>
  <div class="py-1 container-fluid">
    <div id="topinfo">
      <div class="row mb-3">
        <div :class="FlightpathOptimalSettingResultComputed ? 'card bg-gradient-success' : 'card bg-gradient-info'">
          <div class="p-3 card-body">
            <div class="row">
              <div class="my-auto col-6">
                <div
                  v-if="optimizationCreated"
                  class="numbers"
                >
                  <p class="mb-0 text-sm text-white text-capitalize font-weight-bold opacity-7">
                    {{ $t("Static Optimization Name") }}
                  </p>
                  <h5 class="mb-0 text-white font-weight-bolder">
                    {{ flightpath.name }}
                  </h5>
                </div>
                <div
                  v-else
                  class="numbers"
                >
                  <h5 class="mb-0 text-white font-weight-bolder">
                    {{ $t("New Optimization for line") }}
                    <b
                      ><i>{{ line.name }}</i></b
                    >
                  </h5>
                </div>
              </div>
              <div class="col-6 text-end">
                <p class="mb-0 text-sm text-white text-capitalize font-weight-bold opacity-7">
                  {{ $t("Status") }}
                </p>
                <h5 class="mb-0 text-white text-end me-1">
                  {{ FlightpathOptimalSettingResultComputed ? $t("Optimum found") : $t("Ready to find optimum") }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-4">
      <div class="card">
        <div class="card-body">
          <div class="sticky top-0 w-full container_optimization">
            <div
              class="flex justify-between py-1 mb-4 z-10"
              style="border-bottom: 1px solid #f4f4f5; background: white"
            >
              <div class="flex items-center">
                <h5
                  class="font-weight-bolder text-xxl"
                  style="font-weight: 700 !important"
                >
                  {{ $t("Static Optimization details") }}
                </h5>
              </div>
              <div class="flex items-end">
                <button
                  type="submit"
                  class="flex-none p-2 show"
                  aria-hidden="true"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-container="body"
                  data-animation="true"
                  :title="`${$t('Clear & start new optimization for line')} ${line.name}`"
                  :disabled="calculationInProgress.includes(flightpath.id)"
                  @click="
                    ClearAndNewOptimization();
                    hideTooltip();
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    style="width: 20px; height: 20px"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div>
            <div class="row mb-2">
              <div class="col-6">
                <label class="mt-2 mb-2">{{ $t("Name") }}</label>
                <div
                  v-if="optimizationCreated"
                  class="mt-2 mb-2 px-1"
                >
                  {{ flightpath.name }}
                </div>
                <div
                  v-else
                  class="mt-2 mb-2 col-8"
                >
                  <input
                    v-model="flightpath_form.name"
                    :class="line_name_input_error ? `form-control is-invalid` : `form-control`"
                    type="text"
                    @input="line_name_input_error = false"
                  />
                </div>
              </div>
              <div class="col-6">
                <label class="mt-2 mb-2">
                  {{ $t("Line") }}
                </label>
                <div class="mt-2 mb-2 px-1">
                  {{ line.name }}
                </div>
              </div>
            </div>
            <div v-if="optimizationCreated">
              <div class="row">
                <div class="col-6">
                  <label class="mt-2 mb-2">
                    {{ $t("Optimization type") }}
                    <i
                      :title="$t('To use a different optimization type create a new Optimization')"
                      class="fa fa-info-circle"
                      aria-hidden="true"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-container="body"
                      data-animation="true"
                    >
                    </i>
                  </label>
                  <div class="mt-2 mb-2">
                    <div class="mt-2 mb-2 px-1">
                      {{ $t(optimization_type) }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    optimization_type == 'Specific Powder Optimization' ||
                    optimization_type == 'Multi-Powder Optimization'
                  "
                  class="col-6"
                >
                  <label class="mt-2 mb-2">
                    {{ $t("Powder Models") }}
                    <i
                      v-if="optimizationCreated"
                      :title="$t('If you want to use a different set of Powder Models, create a new Optimization.')"
                      class="fa fa-info-circle"
                      aria-hidden="true"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-container="body"
                      data-animation="true"
                    >
                    </i>
                  </label>
                  <div class="mt-2 mb-2">
                    {{ optimization_powdermodels.map(obj => obj.name).join(", ") }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col">
                  <label class="mt-2 mb-2">
                    {{ optimizationCreated ? "" : $t("Select") }} {{ $t("Optimization type") }}
                    <i
                      :title="
                        $t(
                          'Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.',
                        )
                      "
                      class="fa fa-info-circle"
                      aria-hidden="true"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-container="body"
                      data-animation="true"
                    >
                    </i>
                  </label>
                  <div class="col-12 ms-auto">
                    <div class="d-flex justify-content-left gap-8">
                      <button
                        type="button"
                        :class="`mt-2 mb-0 btn bg-gradient-${show_calibrated_powder_selection ? 'success' : 'gray'}`"
                        style="width: 300px; height: 60px; background-color: rgb(241, 241, 241); word-break: keep-all"
                        @click="PowderModelsButton()"
                      >
                        {{ $t("Calibrated Powder Optimization") }}
                      </button>
                      <button
                        type="button"
                        :class="`mt-2 mb-0 btn bg-gradient-${
                          selected_precalibrated_powdermodels.length ? 'success' : 'gray'
                        }`"
                        style="width: 300px; height: 60px; background-color: rgb(241, 241, 241); word-break: keep-all"
                        @click="PrecalibratedPowderModelsButton()"
                      >
                        {{ $t("Pre-Calibrated Powder Optimization") }}
                      </button>
                      <div v-if="$store.state.flightpath_pro_license">
                        <button
                          type="button"
                          :class="`mt-2 mb-0 btn bg-gradient-${generalistic_optimization_toggle ? 'success' : 'gray'}`"
                          style="width: 400px; height: 60px; background-color: rgb(241, 241, 241); word-break: keep-all"
                          @click="generalisticOptimizationButton"
                        >
                          {{ $t("Generalistic Optimization for any powder") }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div v-if="show_calibrated_powder_selection">
                    <label :class="`${show_calibrated_powder_selection ? '' : 'text-muted'} mt-2 mb-2`">
                      {{ $t("Select Powder Model") }}
                      <i
                        class="fa fa-info-circle"
                        aria-hidden="true"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-container="body"
                        data-animation="true"
                        :title="$t('Select the Powder Models to be used during the computation of optimal settings.')"
                      >
                      </i>
                    </label>
                    &nbsp;
                    <div
                      v-for="powdermodel in calibrated_powdermodels"
                      :key="powdermodel"
                      style="display: flex; align-items: center"
                    >
                      <label>
                        <input
                          type="checkbox"
                          style="vertical-align: middle"
                          :value="powdermodel.id"
                          :checked="selected_calibrated_powdermodels.includes(powdermodel.id)"
                          @change="onCheckboxChange($event, powdermodel.id)"
                        />
                        {{ powdermodel.name }}
                      </label>
                    </div>
                    <div v-if="calibrated_powdermodels.length == 0">
                      <p>
                        {{ $t("No Powder Models Created. To create one go to") }}
                        <router-link
                          class="font-weight-bold"
                          :to="'/applications/flightpath/powdermodelslibrary/' + lineId"
                        >
                          {{ $t("Powder Models") }}
                        </router-link>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4 justify-content-center">
              <h5 class="font-weight-bolder">
                {{ $t("Optimization parameters") }}
              </h5>
              <div class="col w-20">
                <label class="mt-2 mb-2">
                  {{ $t("Covered height") }}
                  <i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$t('Vertical height for which the uniformity will be optimized.')"
                    data-container="body"
                    data-animation="true"
                  >
                  </i>
                  [{{ this.$store.state.units_system[this.$store.state.user_data.unit_system].distance }}]
                </label>
              </div>
              <div
                v-if="!isSimpleCalibrationPowderModel"
                class="col w-20"
              >
                <label class="mt-2 mb-2">
                  {{ $t("Distance") }}
                  <i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$t('Distance from the gun nozzle to the substrate.')"
                    data-container="body"
                    data-animation="true"
                  >
                  </i>
                  [{{ this.$store.state.units_system[this.$store.state.user_data.unit_system].distance }}]</label
                >
              </div>
              <div
                v-if="!isSimpleCalibrationPowderModel"
                class="col w-20"
              >
                <label class="mt-2 mb-2">
                  {{ LoadedLine ? line.air_parameter_name + " " + line.air_parameter_units : "Air parameter" }}
                </label>
              </div>
            </div>
            <div class="row justify-content-left">
              <div class="col-4">
                <input
                  v-model="flightpathFormCoatedHeight"
                  :class="coated_height_input_error ? `form-control is-invalid` : `form-control`"
                  type="number"
                  :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                  :disabled="calculationInProgress.includes(flightpath.id)"
                  :max="maxCoatedHeightAllowed"
                  :min="minCoatedHeightAllowed"
                  @input="coated_height_input_error = false"
                  @change="CoatedHeightAlertCheck()"
                  @keydown="numericPositiveOnly"
                />
              </div>
              <div
                v-if="!isSimpleCalibrationPowderModel"
                class="col w-20"
              >
                <input
                  v-model="flightpathFormPistolToSubstrateDistance"
                  class="form-control"
                  type="number"
                  :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                  :disabled="calculationInProgress.includes(flightpath.id)"
                  @change="ZeroPistolDistAlertCheck"
                  @keydown="numericPositiveOnly"
                />
              </div>
              <div
                v-if="!isSimpleCalibrationPowderModel"
                class="col w-20"
              >
                <input
                  v-model="flightpath_form.air_parameter_value"
                  class="form-control"
                  :disabled="calculationInProgress.includes(flightpath.id)"
                  type="number"
                  @keydown="numericPositiveOnly"
                />
              </div>
            </div>
            <div
              v-if="!optimizationCreated"
              class="d-flex justify-content-end mt-2 mb-2"
            >
              <div class="p-2">
                <button
                  class="mt-0 mb-0 btn bg-gradient-success ms-lg-auto me-lg-0 me-auto mt-lg-0"
                  @click="createUpdateOptimization"
                >
                  {{ $t("Continue") }}
                  <i class="far fa-arrow-alt-circle-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="optimizationCreated && LoadedLine"
      class="row mt-4"
    >
      <div class="card">
        <div class="card-body">
          <div class="accordion">
            <div class="accordion-item mb-0">
              <h5 id="panelsStayOpen-headingOne">
                <button
                  :class="`accordion-button mb-0 ${currentSettingsReceived ? '' : 'collapsed'}`"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseCurrentParameters"
                  :aria-expanded="currentSettingsReceived"
                  aria-controls="panelsStayOpen-collapseCurrentParameters"
                  style="padding: 0; width: 50%"
                >
                  <h5>
                    <b>{{ $t("Compare with Current Settings") }}</b>
                  </h5>
                  &nbsp;
                  <div class="text-xs text-muted">({{ $t("optional") }}) &nbsp;</div>
                  <h6>
                    <i
                      class="fa fa-info-circle"
                      aria-hidden="true"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :title="
                        $t(
                          'Fill this section with your Guns Settings to visualize how they compare with the optimal settings.',
                        )
                      "
                      data-container="body"
                      data-animation="true"
                    ></i>
                  </h6>
                  &nbsp;
                  <i
                    class="fa fa-chevron-down"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="fa fa-chevron-up"
                    aria-hidden="true"
                  ></i>
                </button>
              </h5>
              <div
                class="container_optimization mx-4 mt-2"
                style="position: absolute; top: 0; right: 0"
              >
                <button
                  type="submit"
                  class="flex-none p-2 show"
                  aria-hidden="true"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-container="body"
                  data-animation="true"
                  :title="$t('Clear current settings')"
                  :disabled="calculationInProgress.includes(flightpath.id)"
                  @click="
                    deleteCurrentSettings();
                    hideTooltip();
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    style="width: 20px; height: 20px"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    ></path>
                  </svg>
                </button>
              </div>
              <div
                id="panelsStayOpen-collapseCurrentParameters"
                :class="`accordion-collapse collapse ${currentSettingsReceived ? 'show' : ''}`"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div
                  class="accordion-body"
                  style="padding: 0"
                >
                  <div class="row justify-content-left">
                    <div class="col-4">
                      <label class="mt-2 mb-2">
                        {{ isStaticVerticalLayout ? $t("Total guns") : $t("Total guns per column") }}
                      </label>
                    </div>
                    <div class="col-4">
                      <label class="mt-2 mb-2">
                        {{ $t("Gun to gun distance") }} [{{
                          this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                        }}]
                      </label>
                    </div>
                    <div class="col-4">
                      <label class="mt-2 mb-2">
                        {{ $t("Nozzle rotation angle") }}
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-container="body"
                          data-animation="true"
                          :disabled="calculationInProgress.includes(flightpath.id)"
                          :title="nozzleAngleTooltipImage()"
                        ></i>
                      </label>
                    </div>
                  </div>
                  <div class="row justify-content-left">
                    <div
                      v-if="isStaticVerticalLayout"
                      class="col-4"
                    >
                      {{ line.total_pistols[0] }} {{ $t("gun") }}
                    </div>
                    <div
                      v-if="isStaticMixedLayout"
                      class="col-4"
                    >
                      {{ line.total_pistols.join(", ") }} {{ $t("gun") }}
                    </div>
                    <div class="col-4">
                      <input
                        v-model="currentSettingPistolToPistolDistance"
                        :disabled="calculationInProgress.includes(flightpath.id)"
                        :class="
                          current_setting_pistol_to_pistol_distance_input_error
                            ? `form-control is-invalid`
                            : `form-control`
                        "
                        type="number"
                        step="1"
                        :min="minPistolToPistolDistanceAllowed"
                        :max="maxPistolToPistolDistanceAllowed"
                        @input="current_setting_pistol_to_pistol_distance_input_error = false"
                        @change="WithinRangePistolToPistolDistAlertCheck"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                    <div class="col-4">
                      <select
                        id="choices-category"
                        v-model="current_setting_form.nozzle_angle"
                        :class="current_setting_nozzle_angle_input_error ? `form-control is-invalid` : `form-control`"
                        name="line-manufacturer-choices"
                        selected=""
                        :disabled="calculationInProgress.includes(flightpath.id)"
                        style="appearance: listbox"
                        @input="current_setting_nozzle_angle_input_error = false"
                      >
                        <option
                          disabled
                          value=""
                        >
                          {{ $t("Please select one") }}
                        </option>
                        <option :value="0">{{ "0 " + $t("degrees") }} (3h)</option>
                        <option :value="15">{{ "15 " + $t("degrees") }} (2:30h)</option>
                        <option :value="30">{{ "30 " + $t("degrees") }} (2h)</option>
                        <option :value="45">{{ "45 " + $t("degrees") }} (1:30h)</option>
                        <option :value="60">{{ "60 " + $t("degrees") }} (1h)</option>
                        <option :value="75">{{ "75 " + $t("degrees") }} (0:30h)</option>
                        <option :value="90">{{ "90 " + $t("degrees") }} (12h)</option>
                      </select>
                    </div>
                  </div>
                  <div
                    v-if="isStaticMixedLayout"
                    class="row justify-content-left"
                  >
                    <div class="col-3">
                      <label class="mt-2 mb-2">
                        {{ $t("2nd column height offset") }} [{{
                          this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                        }}]
                      </label>
                    </div>
                    <div
                      v-if="line.total_pistols.length > 2"
                      class="col-3"
                    >
                      <label class="mt-2 mb-2">
                        {{ $t("3rd column height offset") }} [{{
                          this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                        }}]
                      </label>
                    </div>
                    <div
                      v-if="line.total_pistols.length > 3"
                      class="col-3"
                    >
                      <label class="mt-2 mb-2">
                        {{ $t("4th column height offset") }} [{{
                          this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                        }}]
                      </label>
                    </div>
                    <div
                      v-if="line.total_pistols.length > 4"
                      class="col-3"
                    >
                      <label class="mt-2 mb-2">
                        {{ $t("5th column height offset") }} [{{
                          this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                        }}]
                      </label>
                    </div>
                  </div>
                  <div
                    v-if="isStaticMixedLayout"
                    class="row justify-content-left"
                  >
                    <div class="col-3">
                      <input
                        v-model.lazy="PistolColumnVerticalOffset2"
                        :class="
                          pistol_column_vertical_offset_2_input_error ? `form-control is-invalid` : `form-control`
                        "
                        type="number"
                        :disabled="calculationInProgress.includes(flightpath.id)"
                        :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                        @input="pistol_column_vertical_offset_2_input_error = false"
                        @keydown="numericOnly"
                      />
                    </div>
                    <div
                      v-if="line.total_pistols.length > 2"
                      class="col-3"
                    >
                      <input
                        v-model.lazy="PistolColumnVerticalOffset3"
                        :class="
                          pistol_column_vertical_offset_3_input_error ? `form-control is-invalid` : `form-control`
                        "
                        type="number"
                        :disabled="calculationInProgress.includes(flightpath.id)"
                        :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                        @input="pistol_column_vertical_offset_3_input_error = false"
                        @keydown="numericOnly"
                      />
                    </div>
                    <div
                      v-if="line.total_pistols.length > 3"
                      class="col-3"
                    >
                      <input
                        v-model.lazy="pistolColumnVerticalOffset4"
                        :class="
                          pistol_column_vertical_offset_4_input_error ? `form-control is-invalid` : `form-control`
                        "
                        type="number"
                        :disabled="calculationInProgress.includes(flightpath.id)"
                        :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                        @input="pistol_column_vertical_offset_4_input_error = false"
                        @keydown="numericOnly"
                      />
                    </div>
                    <div
                      v-if="line.total_pistols.length > 4"
                      class="col-3"
                    >
                      <input
                        v-model.lazy="pistolColumnVerticalOffset5"
                        :class="
                          pistol_column_vertical_offset_5_input_error ? `form-control is-invalid` : `form-control`
                        "
                        type="number"
                        :disabled="calculationInProgress.includes(flightpath.id)"
                        :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                        @input="pistol_column_vertical_offset_5_input_error = false"
                        @keydown="numericOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mt-3 d-flex justify-content-center"
        style="height: 80px"
      >
        <button
          class="btn text-lg"
          :class="
            optimizationFieldsUpdated && !calculationInProgress.includes(flightpath.id)
              ? 'bg-gradient-success'
              : 'bg-gradient-secondary'
          "
          style="height: 100%; width: 50%; max-width: 300px"
          :disabled="calculationInProgress.includes(flightpath.id)"
          @click="computeOptimization()"
        >
          <i class="fa fa-rocket"></i>
          &nbsp; {{ $t("Compute") }}
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div
        v-if="calculationInProgress.includes(flightpath.id) || FlightpathOptimalSettingResultComputed"
        class="card"
      >
        <div
          v-if="calculationInProgress.includes(flightpath.id)"
          class="card-body"
        >
          <div style="width: 100%; height: 100%">
            <div
              class="spinner-border mb-2"
              style="margin: auto; display: table; width: 100px; height: 100px"
              role="status"
            ></div>
          </div>
          <soft-progress
            :percentage="calculation_progress_percentage"
            variant="gradient"
            color="secondary"
          />
        </div>
        <div
          v-else-if="FlightpathOptimalSettingResultComputed"
          class="card-body"
        >
          <div class="row ms-auto mt-xl-0 mt-4">
            <h5 class="mt-2 text-left">{{ $t("Optimal machine settings") }}</h5>
            <div class="row">
              <div :class="`mt-md-0 ${isStaticMixedLayout ? 'col-md-3' : 'col-md-4'}`">
                <mini-statistics-card
                  :title="isStaticVerticalLayout ? $t('Total guns') : $t('Total guns per column')"
                  :value="
                    isStaticVerticalLayout
                      ? `${optimal_setting.num_active_pistols[0]} ${$t('guns')}`
                      : `${line.total_pistols.join(', ')} ${$t('guns')}`
                  "
                  :icon="{ component: 'ni ni-bullet-list-67', background: 'bg-gradient-info' }"
                  class-content="ms-3"
                />
              </div>
              <div :class="`mt-md-0 ${isStaticMixedLayout ? 'col-md-3' : 'col-md-4'}`">
                <mini-statistics-card
                  :title="$t('Gun to gun distance')"
                  :value="
                    optimalSettingPistolToPistolDistance +
                    this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                  "
                  :percentage="{ color: 'text-success' }"
                  :icon="{ component: 'ni ni-user-run', background: 'bg-gradient-info' }"
                  class-content="ms-3"
                />
              </div>
              <div :class="`mt-md-0 ${isStaticMixedLayout ? 'col-md-3' : 'col-md-4'}`">
                <mini-statistics-card
                  :title="$t('Nozzle rotation angle')"
                  :value="optimal_setting.nozzle_angle + ' ' + $t('degrees')"
                  :percentage="{ color: 'text-success' }"
                  :icon="{ component: 'ni ni-compass-04', background: 'bg-gradient-info' }"
                  class-content="ms-3"
                />
              </div>
              <div
                v-if="isStaticMixedLayout"
                class="col-md-3 mt-md-0"
              >
                <mini-statistics-card
                  :title="$t('Gun column height offsets')"
                  :value="OptimalSettingPistolColumnVerticalOffsetsDisplay"
                  :percentage="{ color: 'text-success' }"
                  :icon="{ component: 'ni ni-chart-bar-32', background: 'bg-gradient-info' }"
                  class-content="ms-3"
                />
              </div>
            </div>
            <div class="row mb-3 justify-content-center">
              <h5 class="mt-2 text-left">{{ $t("Simulation results") }}</h5>
              <div
                v-if="currentSettingsReceived"
                class="col-md-6 d-flex justify-content-center p-0"
              >
                <div
                  class="p-0 card d-flex align-items-center"
                  style="width: 95%; background-image: linear-gradient(310deg, #2152ff 0%, #21d4fd 100%)"
                >
                  <div class="p-0 card-body">
                    <div class="p-1 d-flex justify-content-center">
                      <div class="row">
                        <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                          {{ $t("Current Settings") }}
                        </p>
                        <div class="col">
                          <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                            {{ optimizationType == "general" ? $t("Median uniformity") : $t("Uniformity") }}
                          </p>
                          <h5 class="mb-0 font-weight-bolder text-center">
                            <span class="text-md font-weight-bolder text-center text-white">
                              {{ (Math.round(current_setting.homogenity_percentage * 10) / 10).toFixed(1) + "%" }}
                            </span>
                          </h5>
                        </div>
                        <div class="col">
                          <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                            {{ $t("Powder Waste") }}
                          </p>
                          <h5 class="mb-0 font-weight-bolder text-center">
                            <span class="text-md font-weight-bolder text-center text-white">
                              {{ (Math.round(current_setting.powder_waste_percentage * 10) / 10).toFixed(1) + "%" }}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 d-flex justify-content-center p-0">
                <div
                  class="p-0 card"
                  style="width: 95%; background-image: linear-gradient(310deg, #2152ff 0%, #21d4fd 100%)"
                >
                  <div class="p-0 card-body">
                    <div class="p-1 d-flex justify-content-center">
                      <div class="row">
                        <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                          {{ $t("Optimal Settings") }}
                        </p>
                        <div class="col justify-content-between">
                          <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                            {{ optimizationType == "general" ? $t("Median uniformity") : $t("Uniformity") }}
                          </p>
                          <h5 class="mb-0 font-weight-bolder text-center">
                            <span class="text-md font-weight-bolder text-center text-white">
                              {{ (Math.round(optimal_setting.homogenity_percentage * 10) / 10).toFixed(1) + "%" }}
                              {{ UniformityDifference }}
                            </span>
                          </h5>
                        </div>
                        <div class="col">
                          <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                            {{ $t("Powder Waste") }}
                          </p>
                          <h5 class="mb-0 font-weight-bolder text-center">
                            <span class="text-md font-weight-bolder text-center text-white">
                              {{ (Math.round(optimal_setting.powder_waste_percentage * 10) / 10).toFixed(1) + "%" }}
                              {{ PowderWasteDifference }}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!isMixedLayout && powder_amount_calibrations != null"
            class="form-check form-switch"
          >
            <input
              id="powder_amount_calibration_toggle"
              class="form-check-input"
              type="checkbox"
              :checked="powder_amount_calibration_toggle"
              :disabled="false"
              @change="powder_amount_calibration_toggle = !powder_amount_calibration_toggle"
            />
            <label class="form-check-label">
              {{ $t("Calibrate Powder Amount") }}
              <p class="text-xs form-text text-muted ms-1 d-inline">({{ $t("optional") }})</p>
              <i
                class="fa fa-info-circle"
                aria-hidden="true"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-container="body"
                data-animation="true"
                :title="$t('Activate Toggle to open the pistol powder amount calibration panel')"
                style="scale: 0.8"
              >
              </i>
              <slot />
            </label>
            &nbsp;
          </div>
          <div
            v-if="powder_amount_calibration_toggle && !isMixedLayout"
            id="compute-optimal-settings-view"
            class="accordion-body"
          >
            <h6
              v-if="selected_powder_amount_calibration == null"
              class="form-check-label"
            >
              {{ $t("Please select a powder amount calibration") }}
            </h6>
            <label class="mt-2 mb-2">
              {{ $t("Powder Amount Calibration") }}
            </label>
            <div
              class="mt-2 mb-2 col-3"
              style="position: relative"
              @click.stop="toggleDropdown"
            >
              <button
                class="form-control"
                :disabled="powder_amount_calibrations.length == 1"
              >
                {{ selectedPowderAmountCalibrationName }}
              </button>
              <div
                v-if="dropdownVisible"
                class="dropdown-menu show"
              >
                <input
                  v-model="searchQuery"
                  type="text"
                  placeholder="Search..."
                  class="form-control"
                  :disabled="powder_amount_calibrations.length == 1"
                  @click.stop
                />
                <div
                  v-for="powderamountcalibration in filteredPowderAmountCalibrations"
                  :key="powderamountcalibration.id"
                  class="dropdown-item"
                  style="cursor: pointer"
                  :disabled="powder_amount_calibrations.length == 1"
                  @click="selectPowderAmountCalibration(powderamountcalibration)"
                >
                  {{ powderamountcalibration.name }}
                </div>
              </div>
            </div>
            <h6
              v-if="reference_gun_index == null"
              class="form-check-label"
            >
              {{ $t("Please select a reference gun") }}
            </h6>
            <div class="row mb-3">
              <div class="col-9">
                <div
                  v-for="n in line.total_pistols[0]"
                  :key="n"
                  class="row mb-3"
                >
                  <div
                    class="col-2 text-center"
                    style="vertical-align: middle"
                  >
                    <h6 class="mt-3">{{ $t(`Gun`) }} {{ n }}</h6>

                    <label>
                      <input
                        v-model="selected_guns_for_powder_amount[n - 1]"
                        type="checkbox"
                        style="vertical-align: middle; scale: 2"
                        :disabled="powderAmountCheckboxesDisabled[n - 1]"
                        @change="updateSelectedGunsForPowderAmount(n - 1, $event.target)"
                      />
                    </label>
                  </div>
                  <div
                    class="col-4 mt-3"
                    style="vertical-align: bottom"
                  >
                    <gun-svg
                      :fill-color="selected_guns_for_powder_amount[n - 1] ? '\#000000' : '\#BDBDBD'"
                      style="scale: 0.75"
                    ></gun-svg>
                  </div>
                  <div class="col-3">
                    <label class="mt-2 mb-2">{{ $t("Powder Amount Parameter") }}</label>
                    <div class="mt-2 mb-2">
                      <input
                        v-model="powder_amount_parameters[n - 1]"
                        class="form-control"
                        type="number"
                        :min="powder_amount_min_param"
                        :max="powder_amount_max_param"
                        :disabled="
                          !selected_guns_for_powder_amount[n - 1] || selected_powder_amount_calibration == null
                        "
                        @change="getPowderAmountParametersFromParameter(n - 1, powder_amount_parameters[n - 1])"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <label class="mt-2 mb-2"
                  >{{ $t("Expected throughput") }} [{{
                    $store.state.units_system[$store.state.user_data.unit_system].grams
                  }}/ min]</label
                >
                <div class="mt-2 mb-2 col-9">
                  <input
                    v-model="gunExpectedOutput"
                    class="form-control"
                    type="number"
                    min="0"
                    :disabled="reference_gun_index == null || selected_powder_amount_calibration == null"
                    @change="getPowderAmountParametersFromExpectedOutput"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="visualize_simulated_images_toggle"
              :disabled="false"
              @change="visualize_simulated_images_toggle = !visualize_simulated_images_toggle"
            />
            <label class="form-check-label">
              {{ $t("Visualize graphs") }}
              <slot />
            </label>
            &nbsp;
            <i
              class="fa fa-info-circle"
              aria-hidden="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-container="body"
              data-animation="true"
              :title="$t('Activate Toggle to visualize simulation images')"
              style="scale: 0.8"
            >
            </i>
          </div>
          <div
            v-if="visualize_simulated_images_toggle"
            id="compute-optimal-settings-view"
            class="tab-pane container"
          >
            <div class="d-flex align-items-center justify-content-center mt-0">
              <div v-if="calculationInProgress.includes(flightpath.id)">
                <div
                  class="p-3 pb-0 card-header"
                  style="text-align: center"
                >
                  <h6>{{ $t("Calculation running") }}</h6>
                </div>
                <div class="p-3 card-body">
                  <div style="width: 100%; height: 100%">
                    <div
                      class="spinner-border"
                      style="margin: auto; display: table; width: 100px; height: 100px"
                      role="status"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div v-if="calculationInProgress.includes(flightpath.id)">
              <div>
                <soft-progress
                  :percentage="calculation_progress_percentage"
                  variant="gradient"
                  color="secondary"
                />
              </div>
            </div>
          </div>
          <div v-if="FlightpathOptimalSettingResultComputed && !calculationInProgress.includes(flightpath.id)">
            <hr class="horizontal dark" />
            <div class="row pt-1">
              <div class="col-lg-12">
                <div class="card">
                  <div
                    v-if="visualize_simulated_images_toggle"
                    class="row"
                  >
                    <div class="nav-wrapper position-relative ms-auto">
                      <ul class="nav nav-tabs nav-fill nav-justified p-1">
                        <li
                          v-if="optimizationType == 'general'"
                          class="nav-item"
                        >
                          <a
                            class="nav-link active px-0 py-1 mb-0 active"
                            data-bs-toggle="tab"
                            href="#statistical_distribution_img_view"
                            style="color: #344767"
                          >
                            {{ $t("Uniformity comparison") }}
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            :class="
                              optimizationType == 'general'
                                ? 'nav-link px-0 py-1 mb-0'
                                : 'nav-link px-0 py-1 mb-0 active'
                            "
                            data-bs-toggle="tab"
                            href="#substrate_surface_img_view"
                            style="color: #344767"
                          >
                            {{ $t("Applied spray") }}
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link px-0 py-1 mb-0"
                            data-bs-toggle="tab"
                            href="#applied_spray_animation_view"
                            style="color: #344767"
                          >
                            {{ $t("Applied spray animation") }}
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link px-0 py-1 mb-0"
                            data-bs-toggle="tab"
                            href="#nozzle_angle_view"
                            style="color: #344767"
                          >
                            {{ $t("Nozzle rotation angle") }}
                          </a>
                        </li>
                        <li
                          v-if="
                            !(
                              optimal_setting.powder_usage_pie_chart == null ||
                              optimal_setting.powder_usage_pie_chart == 'None'
                            )
                          "
                          class="nav-item"
                        >
                          <a
                            class="nav-link px-0 py-1 mb-0"
                            data-bs-toggle="tab"
                            href="#detailed_pie_chart_view"
                            style="color: #344767"
                          >
                            {{ $t("Powder Usage Pie Chart") }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    v-if="visualize_simulated_images_toggle && optimalSettingsReceived"
                    class="mt-2 card-body"
                  >
                    <div class="tab-content">
                      <div
                        v-if="optimizationType == 'general'"
                        id="statistical_distribution_img_view"
                        class="tab-pane container active"
                      >
                        <img
                          alt="Image placeholder"
                          :src="
                            (optimal_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                            optimal_setting.general_optimization_chart_img
                          "
                          class="img-fluid shadow-lg border-radius-lg"
                          style="display: block; margin-left: auto; margin-right: auto; max-height: 500px"
                        />
                      </div>
                      <div
                        id="substrate_surface_img_view"
                        :class="optimizationType == 'general' ? 'tab-pane container' : 'tab-pane container active'"
                      >
                        <div class="row">
                          <div
                            v-if="currentSettingsReceived"
                            :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'"
                          >
                            <h6 style="text-align: center">{{ $t("Current Settings Coated Area") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="
                                (current_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                current_setting.substrate_surface_img
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                          <div
                            v-if="optimalSettingsReceived"
                            class="text-center"
                            :class="
                              currentSettingsReceived && optimalSettingsReceived
                                ? 'col-lg-6 text-center'
                                : 'text-center'
                            "
                          >
                            <h6 style="text-align: center">{{ $t("Optimal Settings Coated Area") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="
                                (optimal_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                optimal_setting.substrate_surface_img
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        id="applied_spray_animation_view"
                        class="tab-pane container"
                      >
                        <div class="row">
                          <div
                            v-if="currentSettingsReceived"
                            :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'"
                          >
                            <h6 style="text-align: center">{{ $t("Current Settings Coated Area") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="
                                (current_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                current_setting.simulation_gif
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                          <div
                            v-if="optimalSettingsReceived"
                            :class="
                              currentSettingsReceived && optimalSettingsReceived
                                ? 'col-lg-6 text-center'
                                : 'text-center'
                            "
                          >
                            <h6 style="text-align: center">{{ $t("Optimal Settings Coated Area") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="
                                (optimal_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                optimal_setting.simulation_gif
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="optimalSettingsReceived && optimal_setting.nozzle_angle != null"
                        id="nozzle_angle_view"
                        class="tab-pane container"
                      >
                        <div class="row">
                          <div
                            v-if="currentSettingsReceived"
                            :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'"
                          >
                            <h6 style="text-align: center">{{ $t("Current Settings Nozzle Angle") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="CurrentNozzleAngleImage"
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                          <div :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'">
                            <h6 style="text-align: center">{{ $t("Optimal Settings Nozzle Angle") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="OptimalNozzleAngleImage"
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          !(
                            optimal_setting.powder_usage_pie_chart == null ||
                            optimal_setting.powder_usage_pie_chart == 'None'
                          )
                        "
                        id="detailed_pie_chart_view"
                        class="tab-pane container"
                      >
                        <div class="row">
                          <div
                            v-if="currentSettingsReceived"
                            :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'"
                          >
                            <h6 style="text-align: center">{{ $t("Current Settings Pie Chart") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="
                                (current_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                current_setting.powder_usage_pie_chart
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                          <div
                            class="text-center"
                            :class="currentSettingsReceived ? 'col-lg-6' : ''"
                          >
                            <h6 style="text-align: center">{{ $t("Optimal Settings Pie Chart") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="
                                (optimal_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                optimal_setting.powder_usage_pie_chart
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div
                            v-if="currentSettingsReceived"
                            :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'"
                          >
                            <img
                              alt="Image placeholder"
                              :src="
                                (current_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                current_setting.detailed_powder_usage_pie_chart
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                          <div
                            class="text-center"
                            :class="currentSettingsReceived ? 'col-lg-6' : ''"
                          >
                            <img
                              alt="Image placeholder"
                              :src="
                                (optimal_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                optimal_setting.detailed_powder_usage_pie_chart
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                        </div>
                        <div class="text-center mt-3">
                          <p>
                            *<b>{{ $t("Overcoated powder:") }}</b>
                            {{
                              $t(
                                "The amount of extra powder applied because of an uneven powder distribution on the substrate.",
                              )
                            }}
                            <br />
                            *<b>{{ $t("Oversprayed powder:") }}</b>
                            {{
                              $t(
                                "The powder that does not stick to the part and is then not reclaimed by the recycling system.",
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-4 horizontal dark" />
  </div>
</template>

<script>
import axios from "axios";
import MiniStatisticsCard from "@/components/MiniStatisticsCard.vue";
import setTooltip from "@/assets/js/tooltip.js";
import SoftProgress from "@/components/SoftProgress.vue";
import GunSvg from "@/components/GunSvg.vue";

export default {
  name: "StaticLineReciprocatorOptimizationDetail",
  components: {
    MiniStatisticsCard,
    SoftProgress,
    GunSvg,
  },

  props: {
    lineId: {
      type: String,
      default: null,
    },
    visitId: {
      type: String,
      default: null,
    },
    optimizationTypeProp: {
      type: String,
      default: null,
    },
    flightpathIdProp: {
      type: String,
      default: null,
    },
  },

  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      flightpath: [],
      flightpath_form: {
        pistol_to_substrate_distance: 0,
        air_parameter_value: 0,
        coated_width: 100,
        line_speed: 2.5,
      },
      optimal_setting: [],
      optimization_type: "Loading optimization type...",
      optimizationType: "",
      flightpathId: "",
      powdermodels: [],
      generalistic_optimization_toggle: false,
      visualize_simulated_images_toggle: true,
      general_precalibrated_powdermodels: [],
      calibrated_powdermodels: [],
      powdermodel_options: [],
      selected_calibrated_powdermodels: [],
      selected_precalibrated_powdermodels: [],
      optimization_powdermodels: [],
      show_calibrated_powder_selection: false,
      current_setting: {
        num_active_pistols: [],
        pistol_to_pistol_distance: null,
        nozzle_angle: 0,
        pistol_columns_vertical_offsets: [],
      },
      current_setting_form: {
        num_active_pistols: [],
        pistol_to_pistol_distance: null,
        nozzle_angle: 0,
        pistol_columns_vertical_offsets: [],
      },
      line: [],
      powdermodelcalibrationmeasures: [],
      calculation_progress_interval: {},
      calculation_progress_percentage: 0,
      num_active_pistols_column_1: null,
      local_storage_base_URL: axios.defaults.baseURL + "/static/",
      max_imperial_decimals: 2,
      max_metric_decimals: 2,
      coated_height_input_error: false,
      line_name_input_error: false,
      current_setting_active_pistols_input_error: false,
      current_setting_pistol_to_pistol_distance_input_error: false,
      current_setting_nozzle_angle_input_error: false,
      pistol_column_vertical_offset_2: 0,
      pistol_column_vertical_offset_3: 0,
      pistol_column_vertical_offset_4: 0,
      pistol_column_vertical_offset_5: 0,
      pistol_column_vertical_offset_2_input_error: false,
      pistol_column_vertical_offset_3_input_error: false,
      pistol_column_vertical_offset_4_input_error: false,
      pistol_column_vertical_offset_5_input_error: false,
      s3_storage_base_URL: "https://d21tewxstkbxbj.cloudfront.net/flightpath-platform/public-assets/static/",
      // Powder Amount Calibration variables
      powder_amount_calibration_measures: null,
      selected_powder_amount_calibration: null,
      powder_amount_parameters: [],
      powder_amount_min_param: 0,
      powder_amount_max_param: 0,
      searchQuery: "",
      reference_gun_index: null,
      dropdownVisible: false,
      expected_powder_per_minute: null,
      powder_amount_calibration_toggle: false,
      selected_guns_for_powder_amount: [],
    };
  },

  computed: {
    optimizationCreated() {
      return this.flightpathId != "";
    },
    currentSettingsReceived() {
      return (
        Object.prototype.hasOwnProperty.call(this.current_setting, "flightpath") ||
        Object.prototype.hasOwnProperty.call(this.current_setting, "general_flightpath")
      );
    },
    optimalSettingsReceived() {
      return (
        Object.prototype.hasOwnProperty.call(this.optimal_setting, "flightpath") ||
        Object.prototype.hasOwnProperty.call(this.optimal_setting, "general_flightpath")
      );
    },
    isStaticVerticalLayout() {
      return this.line.pistols_layout === "s";
    },
    isStaticMixedLayout() {
      return this.line.pistols_layout === "staticmixed";
    },
    CurrentNozzleAngleImage() {
      if (this.current_setting.nozzle_angle == null) {
        return null;
      } else if (this.current_setting.nozzle_angle == 0) {
        return this.s3_storage_base_URL + "angles-0.jpg";
      } else if (this.current_setting.nozzle_angle == 15) {
        return this.s3_storage_base_URL + "angles-15.jpg";
      } else if (this.current_setting.nozzle_angle == 30) {
        return this.s3_storage_base_URL + "angles-30.jpg";
      } else if (this.current_setting.nozzle_angle == 45) {
        return this.s3_storage_base_URL + "angles-45.jpg";
      } else if (this.current_setting.nozzle_angle == 60) {
        return this.s3_storage_base_URL + "angles-60.jpg";
      } else if (this.current_setting.nozzle_angle == 75) {
        return this.s3_storage_base_URL + "angles-75.jpg";
      } else if (this.current_setting.nozzle_angle == 90) {
        return this.s3_storage_base_URL + "angles-90.jpg";
      } else {
        return this.s3_storage_base_URL + "angles-0.jpg";
      }
    },
    OptimalNozzleAngleImage() {
      if (this.optimal_setting.nozzle_angle == null) {
        return null;
      } else if (this.optimal_setting.nozzle_angle == 0) {
        return this.s3_storage_base_URL + "angles-0.jpg";
      } else if (this.optimal_setting.nozzle_angle == 15) {
        return this.s3_storage_base_URL + "angles-15.jpg";
      } else if (this.optimal_setting.nozzle_angle == 30) {
        return this.s3_storage_base_URL + "angles-30.jpg";
      } else if (this.optimal_setting.nozzle_angle == 45) {
        return this.s3_storage_base_URL + "angles-45.jpg";
      } else if (this.optimal_setting.nozzle_angle == 60) {
        return this.s3_storage_base_URL + "angles-60.jpg";
      } else if (this.optimal_setting.nozzle_angle == 75) {
        return this.s3_storage_base_URL + "angles-75.jpg";
      } else if (this.optimal_setting.nozzle_angle == 90) {
        return this.s3_storage_base_URL + "angles-90.jpg";
      } else {
        return this.s3_storage_base_URL + "angles-0.jpg";
      }
    },
    gunExpectedOutput: {
      get() {
        if (this.expected_powder_per_minute == null || this.expected_powder_per_minute == "") {
          return this.expected_powder_per_minute;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.expected_powder_per_minute * this.$store.state.conversion_factors.g_to_oz).toFixed(2),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.expected_powder_per_minute.toFixed(0));
        } else {
          return this.expected_powder_per_minute;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.expected_powder_per_minute = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.expected_powder_per_minute = value / this.$store.state.conversion_factors.g_to_oz;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.expected_powder_per_minute = value;
          }
        }
      },
    },
    OptimalSettingPistolColumnVerticalOffsetsDisplay() {
      if (
        this.optimal_setting == [] ||
        !Object.prototype.hasOwnProperty.call(this.optimal_setting, "pistol_columns_vertical_offsets")
      ) {
        return "";
      }
      let cm_to_in = this.$store.state.conversion_factors.cm_to_in;
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.optimal_setting.pistol_columns_vertical_offsets
            .slice(1)
            .map(function (number) {
              return Number((number * cm_to_in).toFixed(2));
            })
            .join(", ") + this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
        );
      } else {
        return (
          this.optimal_setting.pistol_columns_vertical_offsets
            .slice(1)
            .map(function (number) {
              return Number(number.toFixed(2));
            })
            .join(", ") + this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
        );
      }
    },
    PistolColumnVerticalOffset2: {
      get() {
        if (this.pistol_column_vertical_offset_2 == null || this.pistol_column_vertical_offset_2 == "") {
          return 0;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_vertical_offset_2 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_vertical_offset_2.toFixed(this.max_metric_decimals));
        } else {
          return 0;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_vertical_offset_2 = 0;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_vertical_offset_2 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_vertical_offset_2 = value;
          } else {
            this.pistol_column_vertical_offset_2 = 0;
          }
        }
      },
    },
    PistolColumnVerticalOffset3: {
      get() {
        if (this.pistol_column_vertical_offset_3 == null || this.pistol_column_vertical_offset_3 == "") {
          return 0;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_vertical_offset_3 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_vertical_offset_3.toFixed(this.max_metric_decimals));
        } else {
          return 0;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_vertical_offset_3 = 0;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_vertical_offset_3 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_vertical_offset_3 = value;
          } else {
            this.pistol_column_vertical_offset_3 = 0;
          }
        }
      },
    },
    PistolColumnVerticalOffset4: {
      get() {
        if (this.pistol_column_vertical_offset_4 == null || this.pistol_column_vertical_offset_4 == "") {
          return 0;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_vertical_offset_4 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_vertical_offset_4.toFixed(this.max_metric_decimals));
        } else {
          return 0;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_vertical_offset_4 = 0;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_vertical_offset_4 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_vertical_offset_4 = value;
          } else {
            this.pistol_column_vertical_offset_4 = 0;
          }
        }
      },
    },
    PistolColumnVerticalOffset5: {
      get() {
        if (this.pistol_column_vertical_offset_5 == null || this.pistol_column_vertical_offset_5 === "") {
          return this.pistol_column_vertical_offset_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_vertical_offset_5 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_vertical_offset_5.toFixed(this.max_metric_decimals));
        } else {
          return 0;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_vertical_offset_5 = 0;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_vertical_offset_5 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_vertical_offset_5 = value;
          } else {
            this.pistol_column_vertical_offset_5 = 0;
          }
        }
      },
    },
    optimizationFieldsUpdated() {
      return (
        this.CurrentSettingsFormUpdated ||
        (!this.FlightpathOptimalSettingResultComputed && !this.calculationInProgress.includes(this.flightpath.id)) ||
        (this.FlightPathParamsUpdatedForm && this.optimizationCreated)
      );
    },
    UniformityDifference() {
      if (this.current_setting.homogenity_percentage != null && this.optimal_setting.homogenity_percentage != null) {
        let difference = Math.abs(
          this.current_setting.homogenity_percentage - this.optimal_setting.homogenity_percentage,
        ).toFixed(1);
        let difference_sign =
          this.optimal_setting.homogenity_percentage - this.current_setting.homogenity_percentage > 0 ? "+" : "-";
        return "(" + difference_sign + difference + "%)";
      } else {
        return null;
      }
    },
    PowderWasteDifference() {
      if (
        this.current_setting.powder_waste_percentage != null &&
        this.optimal_setting.powder_waste_percentage != null
      ) {
        let difference = Math.abs(
          this.current_setting.powder_waste_percentage - this.optimal_setting.powder_waste_percentage,
        ).toFixed(1);
        let difference_sign =
          this.optimal_setting.powder_waste_percentage - this.current_setting.powder_waste_percentage > 0 ? "+" : "-";
        return "(" + difference_sign + difference + "%)";
      } else {
        return null;
      }
    },
    flightpathFormPistolToSubstrateDistance: {
      get() {
        if (
          this.flightpath_form.pistol_to_substrate_distance == null ||
          this.flightpath_form.pistol_to_substrate_distance == ""
        ) {
          return this.flightpath_form.pistol_to_substrate_distance;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.flightpath_form.pistol_to_substrate_distance * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.flightpath_form.pistol_to_substrate_distance.toFixed(this.max_metric_decimals));
        } else {
          return this.flightpath_form.pistol_to_substrate_distance;
        }
      },
      set(value) {
        if (value == null || value == "") {
          return (this.flightpath_form.pistol_to_substrate_distance = value);
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.flightpath_form.pistol_to_substrate_distance = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.flightpath_form.pistol_to_substrate_distance = value;
          }
        }
      },
    },
    flightpathFormCoatedHeight: {
      get() {
        if (this.flightpath_form.coated_height == null || this.flightpath_form.coated_height == "") {
          return this.flightpath_form.coated_height;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.flightpath_form.coated_height * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.flightpath_form.coated_height.toFixed(this.max_metric_decimals));
        } else {
          return this.flightpath_form.coated_height;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.flightpath_form.coated_height = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.flightpath_form.coated_height = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.flightpath_form.coated_height = value;
          }
        }
        this.flightpathFormCoatedWidth = value;
      },
    },
    flightpathFormCoatedWidth: {
      get() {
        if (this.flightpath_form.coated_width == null || this.flightpath_form.coated_width == "") {
          return this.flightpath_form.coated_width;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.flightpath_form.coated_width * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.flightpath_form.coated_width.toFixed(this.max_metric_decimals));
        } else {
          return this.flightpath_form.coated_width;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.flightpath_form.coated_width = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.flightpath_form.coated_width = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.flightpath_form.coated_width = value;
          }
        }
      },
    },
    currentSettingPistolToPistolDistance: {
      get() {
        if (
          this.current_setting_form.pistol_to_pistol_distance == null ||
          this.current_setting_form.pistol_to_pistol_distance == ""
        ) {
          return this.current_setting_form.pistol_to_pistol_distance;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.current_setting_form.pistol_to_pistol_distance * this.$store.state.conversion_factors.cm_to_in
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.current_setting_form.pistol_to_pistol_distance.toFixed(this.max_metric_decimals));
        } else {
          return this.current_setting_form.pistol_to_pistol_distance;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.current_setting_form.pistol_to_pistol_distance = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.current_setting_form.pistol_to_pistol_distance = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.current_setting_form.pistol_to_pistol_distance = value;
          }
        }
      },
    },
    minPistolToSubstrateDistanceAllowed() {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (
            this.$store.state.constraints.min_pistol_to_substrate_distance_allowed *
            this.$store.state.conversion_factors.cm_to_in
          ).toFixed(this.max_imperial_decimals),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(
          this.$store.state.constraints.min_pistol_to_substrate_distance_allowed.toFixed(this.max_metric_decimals),
        );
      } else {
        return null;
      }
    },
    minCoatedHeightAllowed() {
      let min_coated_height_allowed = 5;

      if (this.line.pistols_layout == "s") {
        min_coated_height_allowed =
          (this.line.total_pistols - 1) *
          this.$store.state.constraints.min_pistol_to_pistol_distance_allowed_static_lines;
      } else if (this.line.pistols_layout == "staticmixed") {
        const cumulativeHeights = this.line.pistol_columns_vertical_offsets.map((_, index, offsets) =>
          offsets.slice(0, index + 1).reduce((total, currentOffset) => total + currentOffset, 0),
        );

        const mixedLineMinHeight = Math.max(
          ...this.line.total_pistols.map(
            (numPistols, index) =>
              (numPistols - 1) * this.$store.state.constraints.min_pistol_to_pistol_distance_allowed_static_lines +
              cumulativeHeights[index],
          ),
        );

        min_coated_height_allowed = mixedLineMinHeight;
      }

      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (min_coated_height_allowed * this.$store.state.conversion_factors.cm_to_in).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(min_coated_height_allowed.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    maxCoatedHeightAllowed() {
      let max_coated_height_allowed = 15000;

      if (this.line.pistols_layout == "s") {
        max_coated_height_allowed =
          (this.line.total_pistols - 1) *
          this.$store.state.constraints.max_pistol_to_pistol_distance_allowed_static_lines;
      } else if (this.line.pistols_layout == "staticmixed") {
        const cumulativeHeights = this.line.pistol_columns_vertical_offsets.map((_, index, offsets) =>
          offsets.slice(0, index + 1).reduce((total, currentOffset) => total + currentOffset, 0),
        );

        const mixedLineMaximumHeight = Math.max(
          ...this.line.total_pistols.map(
            (numPistols, index) =>
              (numPistols - 1) * this.$store.state.constraints.max_pistol_to_pistol_distance_allowed_static_lines +
              cumulativeHeights[index],
          ),
        );

        max_coated_height_allowed = mixedLineMaximumHeight;
      }

      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (max_coated_height_allowed * this.$store.state.conversion_factors.cm_to_in).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(max_coated_height_allowed.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    minPistolToPistolDistanceAllowed: function () {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.min_pistol_to_pistol_distance_allowed *
          this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.min_pistol_to_pistol_distance_allowed;
      } else {
        return null;
      }
    },
    maxPistolToPistolDistanceAllowed: function () {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.max_pistol_to_pistol_distance_allowed *
          this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.max_pistol_to_pistol_distance_allowed;
      } else {
        return null;
      }
    },
    optimalSettingPistolToPistolDistance() {
      if (this.optimal_setting.pistol_to_pistol_distance == null) {
        return null;
      }
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.optimal_setting.pistol_to_pistol_distance * this.$store.state.conversion_factors.cm_to_in).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.optimal_setting.pistol_to_pistol_distance.toFixed(3));
      } else {
        return null;
      }
    },
    CurrentSettingsFormUpdated() {
      if (this.CurrentSettingsFormStatus().form_filled_status == "filled") {
        if (
          // JSON.stringify(this.current_setting_form.num_active_pistols) != JSON.stringify(this.current_setting.num_active_pistols) ||
          this.current_setting_form.pistol_to_pistol_distance != this.current_setting.pistol_to_pistol_distance ||
          this.current_setting_form.nozzle_angle != this.current_setting.nozzle_angle
        ) {
          return true;
        } else if (this.isStaticMixedLayout) {
          if (this.pistol_column_vertical_offset_2 != this.current_setting.pistol_columns_vertical_offsets[1] * 100) {
            return true;
          }
          if (
            this.line.total_pistols.length > 2 &&
            this.pistol_column_vertical_offset_3 != this.current_setting.pistol_columns_vertical_offsets[2] * 100
          ) {
            return true;
          }
          if (
            this.line.total_pistols.length > 3 &&
            this.pistol_column_vertical_offset_4 != this.current_setting.pistol_columns_vertical_offsets[3] * 100
          ) {
            return true;
          }
          if (
            this.line.total_pistols.length > 4 &&
            this.pistol_column_vertical_offset_5 != this.current_setting.pistol_columns_vertical_offsets[4] * 100
          ) {
            return true;
          }
        }
      }
      if (this.currentSettingsReceived && this.CurrentSettingsFormStatus().form_filled_status == "partially_filled") {
        return true;
      }
      return false;
    },
    isSimpleCalibrationPowderModel() {
      if (!this.optimizationCreated || this.optimizationType == "general") {
        return true;
      }

      if (this.optimization_powdermodels.length != 1 || this.optimization_type != "Specific Powder Optimization") {
        return true;
      }

      if (
        this.powdermodelcalibrationmeasures.calibration_type === "simple" ||
        (this.flightpath.powdermodel.h_distance == 0 &&
          this.flightpath.powdermodel.h_air == 0 &&
          this.flightpath.powdermodel.h_bias != 0 &&
          this.flightpath.powdermodel.v_distance == 0 &&
          this.flightpath.powdermodel.v_air == 0 &&
          this.flightpath.powdermodel.v_bias != 0 &&
          this.flightpath.powdermodel.beta_distance == 0 &&
          this.flightpath.powdermodel.beta_air == 0 &&
          this.flightpath.powdermodel.beta_bias != 0 &&
          this.flightpath.powdermodel.m_distance == 0 &&
          this.flightpath.powdermodel.m_air == 0 &&
          this.flightpath.powdermodel.m_bias != 0) ||
        this.powdermodelcalibrationmeasures.calibration_type === "static"
      ) {
        return true;
      }
      return false;
    },
    LoadedLine: function () {
      return Object.prototype.hasOwnProperty.call(this.line, "id");
    },
    FlightpathOptimalSettingResultComputed: function () {
      return this.optimal_setting.num_active_pistols != null;
    },
    calculationInProgress: function () {
      return this.$store.state.optimal_settings_calculation_in_progress_flightpath_ids.concat(
        this.$store.state.current_settings_calculation_in_progress_flightpath_ids,
      );
    },
    FlightPathParamsUpdatedForm: function () {
      if (this.optimizationCreated) {
        return JSON.stringify(this.flightpath_form) !== JSON.stringify(this.flightpath);
      }
      return false;
    },
    selectedPowderAmountCalibrationName() {
      if (this.selected_powder_amount_calibration === null) {
        return this.$t("Please select one");
      }

      const selectedPowderAmountCalibration = this.powder_amount_calibrations.find(
        model => model.id === this.selected_powder_amount_calibration,
      );

      return selectedPowderAmountCalibration.name;
    },
    filteredPowderAmountCalibrations() {
      return this.powder_amount_calibrations.filter(powder_amount_calibration =>
        powder_amount_calibration.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
      );
    },
    powderAmountCheckboxesDisabled() {
      if (this.reference_gun_index == null) {
        return Array.from(
          { length: this.line.total_pistols },
          (_, index) => index > this.line.total_pistols[0] - this.optimal_setting.num_active_pistols[0],
        );
      } else if (this.optimal_setting.num_active_pistols[0] == this.line.total_pistols[0]) {
        return Array.from({ length: this.line.total_pistols }, () => true);
      } else {
        return Array.from({ length: this.line.total_pistols }, (_, index) => index != this.reference_gun_index);
      }
    },
  },
  watch: {
    "$store.state.optimal_settings_calculation_in_progress_flightpath_ids": function () {
      if (!this.calculationInProgress.includes(this.flightpath.id)) {
        this.$store.state.isLoading = true;
        this.getData();
      }
    },
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
    powder_amount_calibration_toggle: function () {
      if (
        this.powder_amount_calibration_toggle &&
        this.optimal_setting.num_active_pistols != null &&
        this.current_setting.num_active_pistols != null &&
        this.line.total_pistols[0] == this.optimal_setting.num_active_pistols[0]
      ) {
        this.updateSelectedGunsForPowderAmount(0);
      } else {
        this.selected_guns_for_powder_amount = Array.from({ length: this.line.total_pistols }, () => false);
      }
    },
  },
  mounted() {
    this.optimizationType = JSON.parse(JSON.stringify(this.optimizationTypeProp));
    this.flightpathId = JSON.parse(JSON.stringify(this.flightpathIdProp));

    this.getData();
    setTooltip(this.$store.state.bootstrap);
  },

  updated() {
    setTooltip(this.$store.state.bootstrap);
  },

  methods: {
    async getData() {
      console.log("getData()");
      const tick = Date.now();

      this.hideTooltip();

      await this.getLine();

      if (this.optimizationCreated) {
        await this.getOptimization();
        await Promise.all([this.getOptimizationOptimalSetting(), this.getOptimizationCurrentSetting()]);
        await this.getPowderAmountCalibrations();
      } else {
        this.current_setting.num_active_pistols = this.line.total_pistols;
        this.current_setting_form.num_active_pistols = this.line.total_pistols;
      }

      await Promise.all([this.getCalibratedPowderModels(), this.getGeneralPreCalibratedPowderModels()]);

      this.powdermodel_options = this.calibrated_powdermodels.concat(this.general_precalibrated_powdermodels);

      await this.getCreatedOptimizationPowderModelsList();
      await this.getPowderCalibrationMeasures();

      this.$store.state.isLoading = false;
      console.log(`\n getData() Time Elapsed: ${Date.now() - tick}ms`);
    },
    async getLine() {
      let line_response = await axios.get("/api/v1/fp/line/" + this.lineId);
      line_response.data.total_pistols = JSON.parse(line_response.data.total_pistols);

      line_response.data.pistol_columns_distances = JSON.parse(line_response.data.pistol_columns_distances);
      line_response.data.pistol_columns_vertical_offsets = JSON.parse(
        line_response.data.pistol_columns_vertical_offsets,
      );

      this.line = line_response.data;
    },
    async getOptimization() {
      let optimization_response;

      if (this.optimizationType == "general") {
        optimization_response = await axios.get("/api/v1/fp/generalflightpathoptimization/" + this.flightpathId);
      } else if (this.optimizationType == "specific") {
        optimization_response = await axios.get("/api/v1/fp/flightpathoptimization/" + this.flightpathId);
      }

      // convert line_speed [m / s] to [m / min] and [m] to [cm]
      optimization_response.data.line_speed *= 60;
      optimization_response.data.coated_height *= 100;
      optimization_response.data.coated_width *= 100;

      if (optimization_response.data.pistol_to_substrate_distance != null) {
        optimization_response.data.pistol_to_substrate_distance *= 100;
      }

      this.flightpath = Object.assign({}, optimization_response.data);
      this.flightpath_form = Object.assign({}, optimization_response.data);
    },
    onCheckboxChange(event, id) {
      if (event.target.checked) {
        this.selected_calibrated_powdermodels = [id];
      } else {
        this.selected_calibrated_powdermodels = [];
      }
    },
    optmizationFormFilled() {
      let properly_filled = true;
      let missing_fields = [];
      if (this.flightpath_form.name == null || this.flightpath_form.name == "") {
        this.line_name_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Name"));
      }
      if (this.flightpath_form.coated_height == null || this.flightpath_form.coated_height == "") {
        this.coated_height_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Coated height"));
      }
      if (this.flightpath_form.coated_width == null || this.flightpath_form.coated_width == "") {
        this.coated_width_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Simulation width"));
      }
      if (
        !this.optimizationCreated &&
        !this.selected_calibrated_powdermodels.length &&
        !this.selected_precalibrated_powdermodels.length &&
        !this.generalistic_optimization_toggle
      ) {
        properly_filled = false;
        missing_fields.push(this.$t("Powder model"));
      }
      if (!properly_filled) {
        this.$swal({
          title: this.$t("Missing Required Fields"),
          text: "Please fill the following fields: " + missing_fields.join(", ") + ".",
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
      }
      return properly_filled;
    },
    mapIncomingData(data) {
      if (!Array.isArray(data)) {
        console.error("Data is not an array:", data);
        return [];
      }

      return data.map(gun => {
        if (!Array.isArray(gun)) {
          console.error("gun is not an array:", gun);
          return { powderAmount: [], emptyBagWeight: [], fullBagWeight: [] };
        }

        return {
          powderAmount: gun.map(item => item[0] || null),
          emptyBagWeight: gun.map(item => item[1] || null),
          fullBagWeight: gun.map(item => item[2] || null),
        };
      });
    },
    async getPowderAmountCalibrationMeasures() {
      try {
        let response = await axios.get("/api/v1/fp/powderamountcalibrationmeasures/", {
          params: {
            line: this.lineId,
            library_display: true,
          },
        });
        let powder_amount_calibrations = JSON.parse(JSON.stringify(response.data)).filter(
          powderamountmeasures => powderamountmeasures.id == this.selected_powder_amount_calibration,
        );
        let powder_amount_calibration = powder_amount_calibrations[0];
        this.powder_amount_time_interval = powder_amount_calibration["time_interval"];
        this.powder_amount_number_of_measures = powder_amount_calibration["number_of_measures"];
        let measures_list = JSON.parse(powder_amount_calibration["measures_list"]);
        this.powder_amount_calibration_measures = this.mapIncomingData(measures_list);
        this.powder_amount_min_param = powder_amount_calibration["powder_amount_min_param"];
        this.powder_amount_max_param = powder_amount_calibration["powder_amount_max_param"];
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getPowderAmountCalibrationMeasures();
          }.bind(this),
          5000,
        );
      }
    },
    async getPowderAmountCalibrations() {
      try {
        let response = await axios.get("/api/v1/fp/powderamountcalibrationmeasures/", {
          params: {
            line: this.lineId,
            library_display: true,
          },
        });

        this.powder_amount_calibrations = JSON.parse(JSON.stringify(response.data));

        if (this.powder_amount_calibrations.length == 0) {
          this.powder_amount_calibrations = null;
          this.powder_amount_calibration_measures = null;
        } else if (this.powder_amount_calibrations.length == 1) {
          this.selectPowderAmountCalibration(this.powder_amount_calibrations[0]);
        } else if (this.optimizationType == "specific" && this.flightpath.powdermodel != null) {
          let powder_model = this.flightpath.powdermodel;
          this.powder_amount_calibrations = this.powder_amount_calibrations.filter(
            powderamountmeasures => powderamountmeasures.powdermodel == powder_model,
          );
          if (this.powder_amount_calibrations.length == 0) {
            this.powder_amount_calibrations = null;
            this.powder_amount_calibration_measures = null;
          } else if (this.powder_amount_calibrations.length == 1) {
            this.selectPowderAmountCalibration(this.powder_amount_calibrations[0]);
          }
        }
        this.selected_guns_for_powder_amount = Array.from({ length: this.line.total_pistols }, () => false);
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getPowderAmountCalibrations();
          }.bind(this),
          5000,
        );
      }
    },
    updateSelectedGunsForPowderAmount(gun_index) {
      if (
        this.reference_gun_index != null &&
        this.line.total_pistols[0] != this.optimal_setting.num_active_pistols[0]
      ) {
        this.reference_gun_index = null;
        this.powder_amount_parameters = Array.from({ length: this.line.total_pistols }, () => null);
        this.expected_powder_per_minute = null;
      } else if (this.reference_gun_index == null) {
        this.reference_gun_index = gun_index;
      }
      if (
        this.optimal_setting != null &&
        this.optimal_setting.num_active_pistols != null &&
        this.reference_gun_index != null
      ) {
        if (this.optimal_setting.num_active_pistols.length == this.line.total_pistols) {
          this.selected_guns_for_powder_amount = Array.from({ length: this.line.total_pistols }, () => true);
        } else {
          this.selected_guns_for_powder_amount = Array.from(
            { length: this.line.total_pistols },
            (_, index) =>
              index >= this.reference_gun_index &&
              index < this.reference_gun_index + this.optimal_setting.num_active_pistols[0],
          );
        }
      } else {
        this.selected_guns_for_powder_amount = Array.from({ length: this.line.total_pistols }, () => false);
      }
    },
    async selectPowderAmountCalibration(powder_amount_calibration) {
      this.selected_powder_amount_calibration = powder_amount_calibration ? powder_amount_calibration.id : null;
      if (this.selected_powder_amount_calibration != null) {
        await this.getPowderAmountCalibrationMeasures();
      }
      if (this.expected_powder_per_minute != null && this.expected_powder_per_minute != "") {
        await this.getPowderAmountParametersFromExpectedOutput();
      }
    },
    async getPowderAmountParametersFromExpectedOutput() {
      if (
        this.expected_powder_per_minute == null ||
        this.expected_powder_per_minute == "" ||
        this.expected_powder_per_minute <= 0
      ) {
        this.powder_amount_parameters = Array.from({ length: this.line.total_pistols }, () => null);
        if (this.expected_powder_per_minute <= 0) {
          this.$swal({
            title: this.$t("Invalid Expected Throughput"),
            text: this.$t("The expected throughput must be greater than 0."),
            icon: "error",
            confirmButtonText: "OK",
          });
          this.expected_powder_per_minute = null;
        }
        return;
      }
      let body = {
        line: this.lineId,
        time_interval: this.powder_amount_time_interval,
        measures_list: JSON.stringify(this.powder_amount_calibration_measures),
        powder_per_minute: this.expected_powder_per_minute,
        gun_idx: null,
        powder_amount_param: null,
      };
      let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);
      console.log("FromExpectedOutputResponse", response);
      this.powder_amount_parameters = response.data.powder_amount_params.map((item, index) =>
        this.selected_guns_for_powder_amount[index] ? item : null,
      );
      for (let index = 0; index < this.powder_amount_parameters.length; index++) {
        let item = this.powder_amount_parameters[index];
        if (item !== null && (item < this.powder_amount_min_param || item > this.powder_amount_max_param)) {
          this.$swal({
            title: this.$t("Calibration Out of Range"),
            text: `${this.$t("The required settings to obtain this throughput are out of range for gun")} ${index + 1}.\n 'Min': ${this.powder_amount_min_param}, 'Max': ${this.powder_amount_max_param}. ${this.$t("Obtained Parameter")}: ${item}`,
            icon: "error",
            confirmButtonText: "OK",
          });
          this.powder_amount_parameters = this.powder_amount_parameters.map(() => null);
          this.expected_powder_per_minute = null;
          return;
        }
      }
    },
    toggleDropdown() {
      if (this.powder_amount_calibrations.length == 1) {
        return;
      }
      this.dropdownVisible = !this.dropdownVisible;
    },
    CurrentSettingsFormStatus() {
      if (this.CurrentSettingsFormIsFilled()) {
        return {
          form_filled_status: "filled",
          missing_fields: [],
        };
      }
      if (this.CurrentSettingsFormIsEmpty()) {
        return {
          form_filled_status: "empty",
          missing_fields: [],
        };
      }
      return {
        form_filled_status: "partially_filled",
        missing_fields: this.getCurrentSettingsFormMissingFields(),
      };
    },
    CurrentSettingsFormIsFilled() {
      if (
        this.current_setting_form.pistol_to_pistol_distance != null &&
        this.current_setting_form.pistol_to_pistol_distance != "" &&
        this.current_setting_form.nozzle_angle != null &&
        this.current_setting_form.nozzle_angle !== ""
      ) {
        if (this.isStaticVerticalLayout) {
          return true;
        } else if (this.isStaticMixedLayout) {
          if (
            this.line.total_pistols.length == 2 &&
            (this.pistol_column_vertical_offset_2 != null || this.pistol_column_vertical_offset_2 !== "")
          ) {
            return true;
          } else if (
            this.line.total_pistols.length == 3 &&
            (this.pistol_column_vertical_offset_2 != null || this.pistol_column_vertical_offset_2 !== "") &&
            (this.pistol_column_vertical_offset_3 != null || this.pistol_column_vertical_offset_3 !== "")
          ) {
            return true;
          } else if (
            this.line.total_pistols.length == 4 &&
            (this.pistol_column_vertical_offset_2 != null || this.pistol_column_vertical_offset_2 !== "") &&
            (this.pistol_column_vertical_offset_3 != null || this.pistol_column_vertical_offset_3 !== "") &&
            (this.pistol_column_vertical_offset_4 != null || this.pistol_column_vertical_offset_4 !== "")
          ) {
            return true;
          } else if (
            this.line.total_pistols.length == 5 &&
            (this.pistol_column_vertical_offset_2 != null || this.pistol_column_vertical_offset_2 !== "") &&
            (this.pistol_column_vertical_offset_3 != null || this.pistol_column_vertical_offset_3 !== "") &&
            (this.pistol_column_vertical_offset_4 != null || this.pistol_column_vertical_offset_4 !== "") &&
            (this.pistol_column_vertical_offset_5 != null || this.pistol_column_vertical_offset_5 !== "")
          ) {
            return true;
          }
        }
      }
      return false;
    },
    CurrentSettingsFormIsEmpty() {
      if (
        (this.current_setting_form.pistol_to_pistol_distance == null ||
          this.current_setting_form.pistol_to_pistol_distance === "") &&
        (this.current_setting_form.nozzle_angle == null ||
          this.current_setting_form.nozzle_angle === "" ||
          this.current_setting_form.nozzle_angle == 0)
      ) {
        if (this.isStaticVerticalLayout) {
          return true;
        } else if (this.isStaticMixedLayout) {
          if (
            this.line.total_pistols.length == 2 &&
            (this.pistol_column_vertical_offset_2 == null ||
              this.pistol_column_vertical_offset_2 === "" ||
              this.pistol_column_vertical_offset_2 == 0)
          ) {
            return true;
          } else if (
            this.line.total_pistols.length == 3 &&
            (this.pistol_column_vertical_offset_2 == null ||
              this.pistol_column_vertical_offset_2 === "" ||
              this.pistol_column_vertical_offset_2 == 0) &&
            (this.pistol_column_vertical_offset_3 == null ||
              this.pistol_column_vertical_offset_3 == "" ||
              this.pistol_column_vertical_offset_3 == 0)
          ) {
            return true;
          } else if (
            this.line.total_pistols.length == 4 &&
            (this.pistol_column_vertical_offset_2 == null ||
              this.pistol_column_vertical_offset_2 === "" ||
              this.pistol_column_vertical_offset_2 == 0) &&
            (this.pistol_column_vertical_offset_3 == null ||
              this.pistol_column_vertical_offset_3 === "" ||
              this.pistol_column_vertical_offset_3 == 0) &&
            (this.pistol_column_vertical_offset_4 == null ||
              this.pistol_column_vertical_offset_4 === "" ||
              this.pistol_column_vertical_offset_4 == 0)
          ) {
            return true;
          } else if (
            this.line.total_pistols.length == 5 &&
            (this.pistol_column_vertical_offset_2 == null ||
              this.pistol_column_vertical_offset_2 === "" ||
              this.pistol_column_vertical_offset_2 == 0) &&
            (this.pistol_column_vertical_offset_3 == null ||
              this.pistol_column_vertical_offset_3 === "" ||
              this.pistol_column_vertical_offset_3 == 0) &&
            (this.pistol_column_vertical_offset_4 == null ||
              this.pistol_column_vertical_offset_4 === "" ||
              this.pistol_column_vertical_offset_4 == 0) &&
            (this.pistol_column_vertical_offset_5 == null ||
              this.pistol_column_vertical_offset_5 === "" ||
              this.pistol_column_vertical_offset_5 == 0)
          ) {
            return true;
          }
        }
      }
      return false;
    },
    getCurrentSettingsFormMissingFields() {
      let missing_fields = [];

      const self = this;
      function check_missing_pistol_column_vertical_offset_2() {
        if (self.pistol_column_vertical_offset_2 == null || self.pistol_column_vertical_offset_2 === "") {
          self.pistol_column_vertical_offset_2_input_error = true;
          missing_fields.push("2nd column height offset");
        }
      }
      function check_missing_pistol_column_vertical_offset_3() {
        if (self.pistol_column_vertical_offset_3 == null || self.pistol_column_vertical_offset_3 === "") {
          self.pistol_column_vertical_offset_3_input_error = true;
          missing_fields.push("3rd column height offset");
        }
      }
      function check_missing_pistol_column_vertical_offset_4() {
        if (self.pistol_column_vertical_offset_4 == null || self.pistol_column_vertical_offset_4 === "") {
          self.pistol_column_vertical_offset_4_input_error = true;
          missing_fields.push("4th column height offset");
        }
      }
      function check_missing_pistol_column_vertical_offset_5() {
        if (self.pistol_column_vertical_offset_5 == null || self.pistol_column_vertical_offset_5 === "") {
          self.pistol_column_vertical_offset_5_input_error = true;
          missing_fields.push("5th column height offset");
        }
      }
      if (
        this.current_setting_form.pistol_to_pistol_distance == null ||
        this.current_setting_form.pistol_to_pistol_distance === ""
      ) {
        this.current_setting_pistol_to_pistol_distance_input_error = true;
        missing_fields.push("Gun to Gun Distance");
      }
      if (this.current_setting_form.nozzle_angle == null) {
        this.current_setting_nozzle_angle_input_error = true;
        missing_fields.push("Nozzle rotation angle");
      }
      if (this.isStaticMixedLayout) {
        if (this.line.total_pistols.length == 2) {
          check_missing_pistol_column_vertical_offset_2();
        }
        if (this.line.total_pistols.length == 3) {
          check_missing_pistol_column_vertical_offset_2();
          check_missing_pistol_column_vertical_offset_3();
        }
        if (this.line.total_pistols.length == 4) {
          check_missing_pistol_column_vertical_offset_2();
          check_missing_pistol_column_vertical_offset_3();
          check_missing_pistol_column_vertical_offset_4();
        }
        if (this.line.total_pistols.length == 5) {
          check_missing_pistol_column_vertical_offset_2();
          check_missing_pistol_column_vertical_offset_3();
          check_missing_pistol_column_vertical_offset_4();
          check_missing_pistol_column_vertical_offset_5();
        }
      }
      return missing_fields;
    },
    nozzleAngleTooltipImage() {
      let url = this.s3_storage_base_URL + "angle-0-200x200.jpg";
      return `<img src="${url}" alt="Tooltip Image">`;
    },
    async getOptimizationOptimalSetting() {
      try {
        if (this.optimizationType == "general" && this.flightpath.generalflightpathoptimalsetting == null) {
          return;
        }
        if (this.optimizationType == "specific" && this.flightpath.flightpathoptimalsetting == null) {
          return;
        }

        let optimal_setting_response;

        if (this.optimizationType == "general") {
          optimal_setting_response = await axios.get(
            "/api/v1/fp/generalflightpathoptimalsetting/" + this.flightpath.generalflightpathoptimalsetting,
          );
        } else if (this.optimizationType == "specific") {
          optimal_setting_response = await axios.get(
            "/api/v1/fp/flightpathoptimalsetting/" + this.flightpath.flightpathoptimalsetting,
          );
        }

        if (optimal_setting_response.data.pistol_columns_vertical_offsets != null) {
          optimal_setting_response.data.pistol_columns_vertical_offsets = JSON.parse(
            optimal_setting_response.data.pistol_columns_vertical_offsets,
          ).map(element => element * 100);
        }

        if (optimal_setting_response.data.nozzle_angle == null) {
          optimal_setting_response.data.nozzle_angle = 0;
        }

        this.optimal_setting = optimal_setting_response.data;

        this.optimal_setting.pistol_to_pistol_distance =
          JSON.parse(optimal_setting_response.data.pistol_to_pistol_distance)[0] * 100;
        this.optimal_setting.num_active_pistols = JSON.parse(optimal_setting_response.data.num_active_pistols);
        this.report_comments_section_input = this.optimal_setting.report_comments_section;
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getOptimizationOptimalSetting();
          }.bind(this),
          5000,
        );
      }
    },
    async getOptimizationCurrentSetting() {
      try {
        if (
          (this.optimizationType == "general" && this.flightpath.generalflightpathcurrentsetting == null) ||
          (this.optimizationType == "specific" && this.flightpath.flightpathcurrentsetting == null)
        ) {
          if (Object.prototype.hasOwnProperty.call(this.current_setting, "flightpath")) {
            delete this.current_setting.flightpath;
          } else if (Object.prototype.hasOwnProperty.call(this.current_setting, "general_flightpath")) {
            delete this.current_setting.general_flightpath;
          }

          this.current_setting.num_active_pistols = this.line.total_pistols;
          this.current_setting_form.num_active_pistols = this.line.total_pistols;
          return;
        }

        let current_setting_response;

        if (this.optimizationType == "general") {
          current_setting_response = await axios.get(
            "/api/v1/fp/generalflightpathcurrentsetting/" + this.flightpath.generalflightpathcurrentsetting + "/",
          );
        } else if (this.optimizationType == "specific") {
          current_setting_response = await axios.get(
            "/api/v1/fp/flightpathcurrentsetting/" + this.flightpath.flightpathcurrentsetting + "/",
          );
        }

        current_setting_response.data.num_active_pistols = JSON.parse(JSON.stringify(this.line.total_pistols));
        current_setting_response.data.pistol_to_pistol_distance =
          JSON.parse(current_setting_response.data.pistol_to_pistol_distance)[0] * 100;

        if (current_setting_response.data.nozzle_angle == null) {
          current_setting_response.data.nozzle_angle = 0;
        }

        if (current_setting_response.data.pistol_columns_vertical_offsets != null) {
          current_setting_response.data.pistol_columns_vertical_offsets = JSON.parse(
            current_setting_response.data.pistol_columns_vertical_offsets,
          );

          this.pistol_column_vertical_offset_2 =
            current_setting_response.data.pistol_columns_vertical_offsets[1] != undefined
              ? current_setting_response.data.pistol_columns_vertical_offsets[1] * 100
              : 0;
          this.pistol_column_vertical_offset_3 =
            current_setting_response.data.pistol_columns_vertical_offsets[2] != undefined
              ? current_setting_response.data.pistol_columns_vertical_offsets[2] * 100
              : 0;
          this.pistol_column_vertical_offset_4 =
            current_setting_response.data.pistol_columns_vertical_offsets[3] != undefined
              ? current_setting_response.data.pistol_columns_vertical_offsets[3] * 100
              : 0;
          this.pistol_column_vertical_offset_5 =
            current_setting_response.data.pistol_columns_vertical_offsets[4] != undefined
              ? current_setting_response.data.pistol_columns_vertical_offsets[4] * 100
              : 0;
        }

        this.current_setting = Object.assign({}, current_setting_response.data);
        this.current_setting_form = Object.assign({}, current_setting_response.data);
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getOptimizationCurrentSetting();
          }.bind(this),
          5000,
        );
      }
    },
    async deleteCurrentSettings() {
      try {
        if (
          this.flightpath.generalflightpathcurrentsetting == null &&
          this.flightpath.flightpathcurrentsetting == null
        ) {
          this.clearCurrentSettings();
          return;
        }
        this.$store.state.isLoading = true;
        if (this.optimizationType == "general") {
          await axios.delete(
            "/api/v1/fp/generalflightpathcurrentsetting/" + this.current_setting_form.general_flightpath + "/",
          );
        } else if (this.optimizationType == "specific") {
          await axios.delete("/api/v1/fp/flightpathcurrentsetting/" + this.current_setting_form.flightpath + "/");
        }
        this.clearCurrentSettings();

        this.$store.state.isLoading = false;
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.deleteCurrentSettings();
          }.bind(this),
          5000,
        );
      }
    },
    clearCurrentSettings() {
      this.current_setting_form = {
        num_active_pistols: [],
        pistol_to_pistol_distance: null,
        nozzle_angle: 0,
        pistol_columns_vertical_offsets: [],
      };

      this.current_setting = {
        num_active_pistols: [],
        pistol_to_pistol_distance: null,
        nozzle_angle: 0,
        pistol_columns_vertical_offsets: [],
      };

      this.num_active_pistols_column_1 = null;

      this.pistol_column_vertical_offset_2 = 0;
      this.pistol_column_vertical_offset_3 = 0;
      this.pistol_column_vertical_offset_4 = 0;
      this.pistol_column_vertical_offset_5 = 0;
    },
    async getCalibratedPowderModels() {
      try {
        const response = await axios.get("/api/v1/fp/powdermodels/lineid/" + this.lineId);
        this.calibrated_powdermodels = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getCalibratedPowderModels();
          }.bind(this),
          5000,
        );
      }
    },
    async getGeneralPreCalibratedPowderModels() {
      await axios
        .get("/api/v1/fp/generalprecalibratedpowdermodels/")
        .then(response => {
          this.general_precalibrated_powdermodels = JSON.parse(JSON.stringify(response.data));
        })
        .catch(error => {
          console.error(error);
          setTimeout(
            function () {
              this.getGeneralPreCalibratedPowderModels();
            }.bind(this),
            5000,
          );
        });
    },

    async getPowderAmountParametersFromParameter(gun_index, powder_amount_parameter) {
      if (
        powder_amount_parameter < this.powder_amount_min_param ||
        powder_amount_parameter > this.powder_amount_max_param
      ) {
        this.powder_amount_parameters[gun_index] = null;
        this.$swal({
          title: this.$t("Invalid Powder Amount Parameter"),
          text:
            this.$t(
              "The powder amount parameter must be between the minimum and maximum values defined in the calibration.",
            ) +
            " " +
            "Min: " +
            this.powder_amount_min_param +
            ", " +
            "Max: " +
            this.powder_amount_max_param,
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }
      if (this.powder_amount_parameter == null || this.powder_amount_parameter == "") {
        this.powder_amount_parameters = Array.from({ length: this.line.total_pistols }, () => null);
        this.expected_powder_per_minute = null;
      }

      let body = {
        line: this.lineId,
        time_interval: this.powder_amount_time_interval,
        measures_list: JSON.stringify(this.powder_amount_calibration_measures),
        powder_per_minute: null,
        gun_idx: gun_index,
        powder_amount_param: powder_amount_parameter,
      };
      let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);
      console.log("FromParameterResponse", response);
      this.powder_amount_parameters = response.data.powder_amount_params.map((item, index) =>
        this.selected_guns_for_powder_amount[index] ? item : null,
      );
      for (let index = 0; index < this.powder_amount_parameters.length; index++) {
        let item = this.powder_amount_parameters[index];
        if (item !== null && (item < this.powder_amount_min_param || item > this.powder_amount_max_param)) {
          this.$swal({
            title: this.$t("Calibration Out of Range"),
            text: `${this.$t("The required settings to obtain this throughput are out of range for gun")} ${index + 1}.\n Min: ${this.powder_amount_min_param}, Max: ${this.powder_amount_max_param}. ${this.$t("Obtained Parameter")}: ${item}`,
            icon: "error",
            confirmButtonText: "OK",
          });
          this.powder_amount_parameters = this.powder_amount_parameters.map(() => null);
          this.expected_powder_per_minute = null;
          return;
        }
      }
      if (response.data.powder_per_minute <= 0) {
        this.$swal({
          title: this.$t("Invalid Expected Throughput"),
          text: this.$t("The expected throughput must be greater than 0."),
          icon: "error",
          confirmButtonText: "OK",
        });
        this.powder_amount_parameters = this.powder_amount_parameters.map(() => null);
        this.expected_powder_per_minute = null;
        return;
      }
      this.expected_powder_per_minute = response.data.powder_per_minute;
    },
    async getCreatedOptimizationPowderModelsList() {
      let specific_optimization_calibrated_powdermodel = [];
      let specific_optimization_general_powdermodel = [];
      let general_optimization_multipowder_calibrated_powdermodel = [];

      if (this.optimizationCreated && this.optimizationType == "specific") {
        if (this.flightpath.powdermodel != null) {
          specific_optimization_calibrated_powdermodel = this.calibrated_powdermodels.filter(
            powdermodel => powdermodel.id == this.flightpath.powdermodel,
          );
          this.optimization_type = "Specific Powder Optimization";
        } else if (this.flightpath.general_precalibrated_powdermodel != null) {
          specific_optimization_general_powdermodel = this.general_precalibrated_powdermodels.filter(
            powdermodel => powdermodel.id == this.flightpath.general_precalibrated_powdermodel,
          );
          this.optimization_type = "Pre-Calibrated Powder Optimization";
        }
      }
      if (this.optimizationCreated && this.optimizationType == "general") {
        this.generalistic_optimization_toggle = true;
        this.optimization_type = "Generalistic Optimization for any powder";
      }
      this.optimization_powdermodels = specific_optimization_calibrated_powdermodel
        .concat(specific_optimization_general_powdermodel)
        .concat(general_optimization_multipowder_calibrated_powdermodel);
    },
    async getPowderCalibrationMeasures() {
      if (
        this.optimizationType == "specific" &&
        this.optimization_type == "Specific Powder Optimization" &&
        this.optimization_powdermodels.length == 1
      ) {
        try {
          let powdermodelcalibrationmeasures_response = await axios.get(
            "/api/v1/fp/powdercalibrationmeasures/" + this.optimization_powdermodels[0].id,
          );
          // convert distances from [m] to [cm]
          powdermodelcalibrationmeasures_response.data.max_pistol_to_substrate_distance_measurements *= 100;
          powdermodelcalibrationmeasures_response.data.min_pistol_to_substrate_distance_measurements *= 100;

          this.powdermodelcalibrationmeasures = powdermodelcalibrationmeasures_response.data;

          if (this.isSimpleCalibrationPowderModel) {
            if (this.powdermodelcalibrationmeasures.max_pistol_to_substrate_distance_measurements != null) {
              this.flightpath_form.pistol_to_substrate_distance =
                this.powdermodelcalibrationmeasures.max_pistol_to_substrate_distance_measurements;
            } else {
              this.flightpath_form.pistol_to_substrate_distance = 0;
            }
            if (this.powdermodelcalibrationmeasures.max_air_parameter_measurements != null) {
              this.flightpath_form.air_parameter_value =
                this.powdermodelcalibrationmeasures.max_air_parameter_measurements;
            } else {
              this.flightpath_form.air_parameter_value = 0;
            }
            if (this.powdermodelcalibrationmeasures.max_pistol_to_substrate_distance_measurements != null) {
              this.flightpath.pistol_to_substrate_distance =
                this.powdermodelcalibrationmeasures.max_pistol_to_substrate_distance_measurements;
            } else {
              this.flightpath.pistol_to_substrate_distance = 0;
            }
            if (this.powdermodelcalibrationmeasures.max_air_parameter_measurements != null) {
              this.flightpath.air_parameter_value = this.powdermodelcalibrationmeasures.max_air_parameter_measurements;
            } else {
              this.flightpath.air_parameter_value = 0;
            }
          }
        } catch (error) {
          console.error(error);
          setTimeout(
            function () {
              this.getPowderCalibrationMeasures();
            }.bind(this),
            5000,
          );
        }
      }
    },
    generalisticOptimizationButton() {
      this.generalistic_optimization_toggle = true;
      this.show_calibrated_powder_selection = false;
      this.selected_precalibrated_powdermodels = [];
      this.selected_precalibrated_powdermodels = [];
    },
    PrecalibratedPowderModelsButton() {
      this.selected_calibrated_powdermodels = [];
      this.show_calibrated_powder_selection = false;
      this.generalistic_optimization_toggle = false;

      if (!this.selected_precalibrated_powdermodels.length) {
        this.selected_precalibrated_powdermodels.push(this.general_precalibrated_powdermodels[0].id);
      }
    },
    PowderModelsButton() {
      this.selected_precalibrated_powdermodels = [];
      this.show_calibrated_powder_selection = true;
      this.generalistic_optimization_toggle = false;

      if (this.calibrated_powdermodels.length == 1 && this.selected_calibrated_powdermodels.length == 0) {
        this.selected_calibrated_powdermodels.push(this.calibrated_powdermodels[0].id);
      }
    },
    StartCalculationInProgress() {
      clearInterval(this.calculation_progress_interval);
      this.calculation_progress_percentage = 0;

      let progress_bar_duration = 40;
      if (this.optimizationType == "specific") {
        progress_bar_duration = 20;
      }

      this.calculation_progress_interval = setInterval(() => {
        this.calculation_progress_percentage += 0.2;
      }, progress_bar_duration);
    },
    WithinRangePistolToPistolDistAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].distance;
      this.current_setting_pistol_to_pistol_distance_input_error = false;

      if (this.current_setting_form.pistol_to_pistol_distance === 0) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Gun to gun distance cannot be 0")} [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.current_setting_pistol_to_pistol_distance_input_error = true;
        this.current_setting_form.pistol_to_pistol_distance = null;
      } else if (
        this.current_setting_form.pistol_to_pistol_distance != null &&
        this.current_setting_form.pistol_to_pistol_distance != "" &&
        this.current_setting_form.pistol_to_pistol_distance <
          this.$store.state.constraints.min_pistol_to_pistol_distance_allowed
      ) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Gun to Gun distance must be greater than")} ${
            this.minPistolToPistolDistanceAllowed
          } [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.current_setting_pistol_to_pistol_distance_input_error = true;
        this.current_setting_form.pistol_to_pistol_distance = null;
      } else if (
        this.current_setting_form.pistol_to_pistol_distance >
        this.$store.state.constraints.max_pistol_to_pistol_distance_allowed
      ) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Gun to Gun distance must be smaller than")} ${
            this.maxPistolToPistolDistanceAllowed
          } [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.current_setting_pistol_to_pistol_distance_input_error = true;
        this.current_setting_form.pistol_to_pistol_distance = null;
      }
    },
    CoatedHeightAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].distance;
      if (this.flightpathFormCoatedHeight < this.minCoatedHeightAllowed) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Covered height must be greater than")} ${this.minCoatedHeightAllowed} [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
        this.flightpathFormCoatedHeight = this.minCoatedHeightAllowed;
      }
      if (this.flightpathFormCoatedHeight > this.maxCoatedHeightAllowed) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("The covered height, for the number of guns available, must be smaller than ")} ${
            this.maxCoatedHeightAllowed
          } [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
        this.flightpathFormCoatedHeight = this.maxCoatedHeightAllowed;
      }
    },
    async computeOptimization() {
      if (!this.optimizationFieldsUpdated) {
        this.$swal({
          title: this.$t("No fields were changed from the previous optimization"),
          text: this.$t("Do you want to recompute it anyway?"),
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: this.$t("Cancel"),
          confirmButtonText: this.$t("Yes, recompute it!"),
          customClass: {
            confirmButton: "btn bg-gradient-success w-40",
            cancelButton: "btn bg-gradient-danger w-40",
          },
          reverseButtons: true,
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.runCalculation();
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.dismiss;
          }
        });
      } else if (this.CurrentSettingsFormStatus().form_filled_status == "partially_filled") {
        this.$swal({
          title: this.$t("Missing Required Fields"),
          text:
            this.$t("Please fill the following fields: ") +
            this.CurrentSettingsFormStatus().missing_fields.join(", ") +
            ".",
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
      } else {
        this.runCalculation();
      }
    },
    async runCalculation() {
      let flightpath_form_updated = this.FlightPathParamsUpdatedForm.valueOf();

      if (flightpath_form_updated) {
        await this.createUpdateOptimization();

        if (Object.prototype.hasOwnProperty.call(this.current_setting, "flightpath")) {
          console.log("delete this.current_setting.flightpath");
          delete this.current_setting.flightpath;
        } else if (Object.prototype.hasOwnProperty.call(this.current_setting, "general_flightpath")) {
          console.log("delete this.current_setting.general_flightpath");
          delete this.current_setting.general_flightpath;
        }
      }

      this.StartCalculationInProgress();

      if (
        this.CurrentSettingsFormStatus().form_filled_status == "filled" &&
        (this.CurrentSettingsFormUpdated || flightpath_form_updated)
      ) {
        this.$store.commit("addCurrentSettingsCalculationInProgress", this.flightpath.id);
        await this.updateCreateCurrentSettings();
        if (this.optimizationType == "specific") {
          await this.getData();
        }
        this.$store.commit("removeCurrentSettingsCalculationInProgress", this.flightpath.id);
      }

      if (this.optimizationType == "general") {
        this.$store.commit("addOptimalSettingsCalculationInProgress", this.flightpath.id);
        await axios
          .post("/api/v1/fp/computestaticlinegeneralisticoptimalsetting/" + this.flightpath.id + "/")
          .catch(error => console.error(error));
      } else if (this.optimizationType == "specific") {
        if (flightpath_form_updated || !this.optimalSettingsReceived) {
          if (this.optimizationType == "specific") {
            this.$store.commit("addOptimalSettingsCalculationInProgress", this.flightpath.id);
            await axios
              .post("/api/v1/fp/computestaticlineoptimalsetting/" + this.flightpath.id + "/")
              .catch(error => console.error(error));
          }
        }
      }
    },
    async updateCreateCurrentSettings() {
      let pistol_to_pistol_distance_array = Array(this.line.total_pistols.length).fill(
        this.current_setting_form.pistol_to_pistol_distance / 100,
      );

      let pistol_columns_vertical_offsets_array = [
        0,
        this.pistol_column_vertical_offset_2,
        this.pistol_column_vertical_offset_3,
        this.pistol_column_vertical_offset_4,
        this.pistol_column_vertical_offset_5,
      ]
        .filter(function (element) {
          return element !== "" && element != null;
        })
        .map(function (element) {
          return element / 100;
        })
        .slice(0, this.line.total_pistols.length);

      if (
        this.optimizationType == "general" &&
        !Object.prototype.hasOwnProperty.call(this.current_setting, "general_flightpath")
      ) {
        await axios
          .post("/api/v1/fp/generalflightpathcurrentsetting/", {
            general_flightpath: this.flightpath.id,
            num_active_pistols: this.line.total_pistols,
            pistol_to_pistol_distance: JSON.stringify(pistol_to_pistol_distance_array),
            pistol_columns_vertical_offsets: JSON.stringify(pistol_columns_vertical_offsets_array),
            nozzle_angle: this.current_setting_form.nozzle_angle,
            pistols_velocity: 0,
            pistols_period: 0,
            pistols_movement_range: 0,
          })
          .catch(error => console.error(error));
      } else if (
        this.optimizationType == "specific" &&
        !Object.prototype.hasOwnProperty.call(this.current_setting, "flightpath")
      ) {
        await axios
          .post("/api/v1/fp/flightpathcurrentsetting/", {
            flightpath: this.flightpath.id,
            num_active_pistols: this.line.total_pistols,
            pistol_to_pistol_distance: JSON.stringify(pistol_to_pistol_distance_array),
            pistol_columns_vertical_offsets: JSON.stringify(pistol_columns_vertical_offsets_array),
            nozzle_angle: this.current_setting_form.nozzle_angle,
            pistols_velocity: 0,
            pistols_period: 0,
            pistols_movement_range: 0,
          })
          .catch(error => console.error(error));
      } else if (
        this.optimizationType == "general" &&
        Object.prototype.hasOwnProperty.call(this.current_setting, "general_flightpath")
      ) {
        await axios
          .patch("/api/v1/fp/generalflightpathcurrentsetting/" + this.current_setting_form.general_flightpath + "/", {
            general_flightpath: this.flightpath.id,
            num_active_pistols: this.line.total_pistols,
            pistol_to_pistol_distance: JSON.stringify(pistol_to_pistol_distance_array),
            pistol_columns_vertical_offsets: JSON.stringify(pistol_columns_vertical_offsets_array),
            nozzle_angle: this.current_setting_form.nozzle_angle,
            pistols_velocity: 0,
            pistols_period: 0,
            pistols_movement_range: 0,
          })
          .catch(error => console.error(error));
      } else if (
        this.optimizationType == "specific" &&
        Object.prototype.hasOwnProperty.call(this.current_setting, "flightpath")
      ) {
        await axios
          .patch("/api/v1/fp/flightpathcurrentsetting/" + this.current_setting_form.flightpath + "/", {
            flightpath: this.flightpath.id,
            num_active_pistols: this.line.total_pistols,
            pistol_to_pistol_distance: JSON.stringify(pistol_to_pistol_distance_array),
            pistol_columns_vertical_offsets: JSON.stringify(pistol_columns_vertical_offsets_array),
            nozzle_angle: this.current_setting_form.nozzle_angle,
            pistols_velocity: 0,
            pistols_period: 0,
            pistols_movement_range: 0,
          })
          .catch(error => console.error(error));
      }

      this.current_setting.pistols_movement_range = this.current_setting_form.pistols_movement_range;
      this.current_setting.pistols_velocity = this.current_setting_form.pistols_velocity;
      this.current_setting.pistols_period = this.current_setting_form.pistols_period;
      this.current_setting.pistol_to_pistol_distance = this.current_setting_form.pistol_to_pistol_distance;
    },
    async createUpdateOptimization() {
      if (!this.optmizationFormFilled()) {
        return;
      }
      this.$store.state.isLoading = true;
      if (!this.optimizationCreated) {
        if (this.generalistic_optimization_toggle) {
          try {
            let response = await axios.post("/api/v1/fp/generalflightpathoptimization/", {
              line: this.lineId,
              name: this.flightpath_form.name,
              coated_height: this.flightpath_form.coated_height / 100, //convert [cm] to [m]
              coated_width: this.flightpath_form.coated_width / 100, //convert [cm] to [m]
              line_speed: this.flightpath_form.line_speed / 60, //convert [m / min] to [m / s]
            });
            this.flightpathId = response.data.id;
            this.optimizationType = "general";
          } catch (error) {
            console.error(error);
          }
        } else if (this.selected_precalibrated_powdermodels.length) {
          try {
            let response = await axios.post("/api/v1/fp/flightpathoptimization/", {
              general_precalibrated_powdermodel: this.selected_precalibrated_powdermodels[0],
              line: this.lineId,
              name: this.flightpath_form.name,
              coated_height: this.flightpath_form.coated_height / 100, //convert [cm] to [m]
              coated_width: this.flightpath_form.coated_width / 100, //convert [cm] to [m]
              line_speed: this.flightpath_form.line_speed / 60, //convert [m / min] to [m / s]
              pistol_to_substrate_distance: this.flightpath_form.pistol_to_substrate_distance / 100, //convert [cm] to [m]
              air_parameter_value: this.flightpath_form.air_parameter_value,
            });
            this.flightpathId = response.data.id;
            this.optimizationType = "specific";
          } catch (error) {
            console.error(error);
          }
        } else if (this.selected_calibrated_powdermodels.length) {
          try {
            let response = await axios.post("/api/v1/fp/flightpathoptimization/", {
              powdermodel: this.selected_calibrated_powdermodels[0],
              line: this.lineId,
              name: this.flightpath_form.name,
              coated_height: this.flightpath_form.coated_height / 100, //convert [cm] to [m]
              coated_width: this.flightpath_form.coated_width / 100, //convert [cm] to [m]
              line_speed: this.flightpath_form.line_speed / 60, //convert [m / min] to [m / s]
              pistol_to_substrate_distance: this.flightpath_form.pistol_to_substrate_distance / 100, //convert [cm] to [m]
              air_parameter_value: this.flightpath_form.air_parameter_value,
            });
            this.flightpathId = response.data.id;
            this.optimizationType = "specific";
          } catch (error) {
            console.error(error);
          }
        }

        this.$router.push({
          name: "StaticLineOptimizationDetail",
          path: `/applications/flightpath/staticlineoptimization/${this.lineId}/${this.flightpathId}/`,
          params: {
            lineId: this.lineId,
            flightpathIdProp: this.flightpathId,
            optimizationTypeProp: this.optimizationType,
          },
          replace: true,
        });
      } else if (this.optimizationCreated) {
        let body = {
          coated_height: this.flightpath_form.coated_height / 100, //convert [m] to [cm]
          coated_width: this.flightpath_form.coated_width / 100,
          line_speed: this.flightpath_form.line_speed / 60, //convert [m] to [c
        };

        if (this.optimizationType == "general") {
          await axios
            .patch("/api/v1/fp/generalflightpathoptimization/" + this.flightpathId + "/", body)
            .catch(error => console.error(error));
        } else if (this.optimizationType == "specific") {
          body = {
            ...body,
            ...{
              pistol_to_substrate_distance: this.flightpath_form.pistol_to_substrate_distance / 100,
              air_parameter_value: this.flightpath_form.air_parameter_value,
            },
          };

          await axios
            .patch("/api/v1/fp/flightpathoptimization/" + this.flightpathId + "/", body)
            .catch(error => console.error(error));
        }
      }
      await this.getData();
    },
    ClearAndNewOptimization() {
      this.clearAllData();
      this.flightpathId = "";
      this.optimizationType = "";
      this.optimization_type = "";

      this.$router.push("/applications/flightpath/staticlineoptimization/" + this.lineId);
      this.getData();
    },
    clearAllData() {
      this.flightpath = [];
      this.flightpath_form = {
        pistol_to_substrate_distance: 0,
        air_parameter_value: 0,
        coated_width: 100,
        line_speed: 2.5,
      };
      this.optimal_setting = [];
      this.optimization_type = "";
      this.powdermodels = [];
      this.visualize_simulated_images_toggle = true;
      this.general_precalibrated_powdermodels = [];
      this.calibrated_powdermodels = [];
      this.powdermodel_options = [];
      this.selected_calibrated_powdermodels = [];
      this.selected_precalibrated_powdermodels = [];
      this.optimization_powdermodels = [];
      this.current_setting = {
        num_active_pistols: [],
        pistol_to_pistol_distance: null,
      };
      this.current_setting_form = {
        num_active_pistols: [],
        pistol_to_pistol_distance: null,
      };
      this.line = [];
      this.powdermodelcalibrationmeasures = [];
      this.calculation_progress_percentage = 0;
      this.num_active_pistols_column_1 = null;
    },
    hideTooltip() {
      const elements = document.querySelectorAll(".tooltip");

      elements.forEach(element => {
        element.remove();
      });
    },
    numericPositiveOnly(event) {
      let key = event.key;
      if (
        [
          "Delete",
          "Backspace",
          "Tab",
          "Enter",
          "ArrowUp",
          "ArrowRight",
          "ArrowDown",
          "ArrowLeft",
          ".",
          "Shift",
          "Control",
          ",",
        ].includes(key) ||
        ((event.ctrlKey || event.metaKey) && ["a", "c", "v", "x", "z", "y"].includes(key.toLowerCase()))
      ) {
        return;
      }
      if (!/\d/.test(key)) {
        event.preventDefault();
      }
    },
    numericOnly(event) {
      let key = event.key;
      if (
        [
          "Delete",
          "Backspace",
          "Tab",
          "Enter",
          "ArrowUp",
          "ArrowRight",
          "ArrowDown",
          "ArrowLeft",
          ".",
          "Shift",
          "Control",
          ",",
          "-",
        ].includes(key) ||
        ((event.ctrlKey || event.metaKey) && ["a", "c", "v", "x", "z", "y"].includes(key.toLowerCase()))
      ) {
        return;
      }
      if (!/\d/.test(key)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container_optimization {
  @import "~@/assets/css/coating_expert_chat.scss";
}
</style>

<i18n>
  {
    "EN": {
      "": "",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "The amount of extra powder applied because of an uneven powder distribution on the substrate.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "The powder that does not stick to the part and is then not reclaimed by the recycling system.",
      "2nd column height offset": "2nd column height offset",
      "3rd column height offset": "3rd column height offset",
      "4th column height offset": "4th column height offset",
      "5th column height offset": "5th column height offset",
      "gun": "gun",
      "Gun to Gun distance must be smaller than": "Gun to Gun distance must be smaller than",
      "Gun to Gun distance must be greater than": "Gun to Gun distance must be greater than",
      "Gun to gun distance cannot be 0": "Gun to gun distance cannot be 0",
      "degrees": "degrees",
      "Gun column height offsets": "Gun column height offsets",
      "Nozzle rotation angle": "Nozzle rotation angle",
      "The covered height, for the number of guns available, must be smaller than ": "The covered height, for the number of guns available, must be smaller than ",
      "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": "Guns movement range cannot be greater than the maximum possible mechanical range of the line:",
      "Loading optimization type...": "Loading optimization type...",
      "Clear & start new optimization for line": "Clear & start new optimization for line",
      "Please fill the following fields: ": "Please fill the following fields: ",
      "Line speed": "Line speed",
      "Coated height": "Coated height",
      "Powder model": "Powder model",
      "Specific Powder Optimization": "Specific Powder Optimization",
      "Input value out of range" : "Input value out of range",
      "was saved successfully." : "was saved successfully.",
      "Missing Required Fields" : "Missing Required Fields",
      "Guns movement range cannot be 0" : "Guns movement range cannot be 0",
      "Line speed cannot be 0" : "Line speed cannot be 0",
      "Line speed must be greater than" : "Line speed must be greater than",
      "Line speed must be less than" : "Line speed must be less than",
      "Covered height must be greater than" : "Covered height must be greater than",
      "Guns movement speed cannot be slower than" : "Guns movement speed cannot be slower than",
      "Use a faster guns velocity or a different movement range & period time combination." : "Use a faster guns velocity or a different movement range & period time combination.",
      "Guns movement speed cannot be faster than" : "Guns movement speed cannot be faster than",
      "Use a slower guns velocity or a different movement range & period time combination." : "Use a slower guns velocity or a different movement range & period time combination.",
      "Guns movement period cannot be longer than" : "Guns movement period cannot be longer than",
      "Use a shorter guns period or a different movement range & period time combination." : "Use a shorter guns period or a different movement range & period time combination.",
      "Guns movement period must be greater than" : "Guns movement period must be greater than",
      "No fields were changed from the previous optimization" : "No fields were changed from the previous optimization",
      "Do you want to recompute it anyway?" : "Do you want to recompute it anyway?",
      "Cancel" : "Cancel",
      "Yes, recompute it!" : "Yes, recompute it!",
      "You need to define the Current Settings to generate a report" : "You need to define the Current Settings to generate a report",
      "OK" : "OK",
      "Total guns": "Total guns",
      "Total guns per column": "Total guns per column",
      "Gun movement range": "Gun movement range",
      "Gun Movement Speed": "Gun Movement Speed",
      "Gun Movement Period": "Gun Movement Period",
      "Save Optimization to Library": "Save Optimization to Library",
      "Optimization Saved to Library": "Optimization Saved to Library",
      "optional": "optional",
      "Static Optimization Name": "Static Optimization Name",
      "New Optimization for line": "New Optimization for line",
      "Status": "Status",
      "Optimum found": "Optimum found",
      "Ready to find optimum": "Ready to find optimum",
      "Static Optimization details": "Static Optimization details",
      "Name": "Name",
      "Line": "Line",
      "Optimization type": "Optimization type",
      "Select": "Select",
      "Powder Models": "Powder Models",
      "Calibrated Powder Optimization": "Calibrated Powder Optimization",
      "Pre-Calibrated Powder Optimization": "Pre-Calibrated Powder Optimization",
      "Generalistic Optimization for any powder": "Generalistic Optimization for any powder",
      "Select Powder Model": "Select Powder Model",
      "No Powder Models Created. To create one go to": "No Powder Models Created. To create one go to",
      "Optimization parameters": "Optimization parameters",
      "Line Speed": "Line Speed",
      "Distance": "Distance",
      "Covered height": "Covered height",
      "Simulation width": "Simulation width",
      "Continue": "Continue",
      "Compare with Current Settings": "Compare with Current Settings",
      "Number of active guns 1st column": "Number of active guns 1st column",
      "Please select one": "Please select one",
      "Number of active guns 2nd column": "Number of active guns 2nd column",
      "Number of active guns 3rd column": "Number of active guns 3rd column",
      "Number of active guns 4th column": "Number of active guns 4th column",
      "Number of active guns 5th column": "Number of active guns 5th column",
      "Guns movement range": "Guns movement range",
      "Guns movement period time": "Guns movement period time",
      "Guns movement speed": "Guns movement speed",
      "Compute": "Compute",
      "Optimal machine settings": "Optimal machine settings",
      "Simulation results": "Simulation results",
      "Current Settings": "Current Settings",
      "Overspray": "Overspray",
      "Optimal Settings": "Optimal Settings",
      "Uniformity": "Uniformity",
      "Median uniformity": "Median uniformity",
      "Overspray": "Overspray",
      "Visualize graphs": "Visualize graphs",
      "Calculation running": "Calculation running",
      "Uniformity comparison": "Uniformity comparison",
      "Applied spray": "Applied spray",
      "Movement chart": "Movement chart",
      "Applied spray animation": "Applied spray animation",
      "Current Settings Coated Area": "Current Settings Coated Area",
      "Optimal Settings Coated Area": "Optimal Settings Coated Area",
      "Current Settings Guns Movement": "Current Settings Guns Movement",
      "Optimal Settings Guns Movement": "Optimal Settings Guns Movement",
      "Current Settings Coated Area": "Current Settings Coated Area",
      "Optimal Settings Coated Area": "Optimal Settings Coated Area",
      "Comments section for the generated report": "Comments section for the generated report",
      "Hide Optimal Settings in the report": "Hide Optimal Settings in the report",
      "Save Edited Comments": "Save Edited Comments",
      "Generate Report": "Generate Report",
      "Generating optimization report...": "Generating optimization report...",
      "To use a different optimization type create a new Optimization": "To use a different optimization type create a new Optimization",
      "If you want to use a different set of Powder Models, create a new Optimization.": "If you want to use a different set of Powder Models, create a new Optimization.",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.",
      "Select the Powder Models to be used during the computation of optimal settings.": "Select the Powder Models to be used during the computation of optimal settings.",
      "Line velocity value in [meters/minute] units.": "Line velocity value in [meters/minute] units.",
      "Distance from the gun nozzle to the substrate.": "Distance from the gun nozzle to the substrate.",
      "Vertical height for which the uniformity will be optimized.": "Vertical height for which the uniformity will be optimized.",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.",
      "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.",
      "Clear current settings": "Clear current settings",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "Number of active guns on the first column of guns. The minimum is 0 and the maximum is",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "Number of active guns on the second column of guns. The minimum is 0 and the maximum is",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "Number of active guns on the third column of guns. The minimum is 0 and the maximum is",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is",
      "Number of active guns on one side of the booth": "Number of active guns on one side of the booth",
      "Activate Toggle to visualize simulation images": "Activate Toggle to visualize simulation images",
      "Gun to gun distance": "Gun to gun distance",
      "Current Settings Nozzle Angle": "Current Settings Nozzle Angle",
      "Optimal Settings Nozzle Angle": "Optimal Settings Nozzle Angle",
      "guns": "guns",
      "Powder Usage Pie Chart": "Powder Usage Pie Chart",
      "Optimal Settings Pie Chart": "Optimal Settings Pie Chart",
      "Current Settings Pie Chart": "Current Settings Pie Chart",
      "Powder Waste": "Powder Waste",
    },
    "DE": {
      "": "",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "Die Menge an zusätzlichem Pulver, die aufgrund einer ungleichmäßigen Verteilung des Pulvers auf dem Substrat aufgetragen wird.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "Das Pulver, das nicht auf dem Teil haften bleibt und vom Recycling-System nicht zurückgewonnen wird.",
      "2nd column height offset": "Höhenversatz der 2. Spalte",
      "3rd column height offset": "Höhenversatz der 3. Spalte",
      "4th column height offset": "Höhenversatz der 4. Spalte",
      "5th column height offset": "Höhenversatz der 5. Spalte",
      "gun": "Pistole",
      "Gun to Gun distance must be smaller than": "Der Abstand von Pistole zu Pistole muss kleiner sein als",
      "Gun to Gun distance must be greater than": "Der Abstand von Pistole zu Pistole muss größer sein als",
      "Gun to gun distance cannot be 0": "Der Abstand von Pistole zu Pistole kann nicht 0 sein",
      "degrees": "Grad",
      "Gun column height offsets": "Höhenversatz der Pistolensäule",
      "Nozzle rotation angle": "Drehwinkel der Düse",
      "The covered height, for the number of guns available, must be smaller than ": "Die bedeckte Höhe muss bei der Anzahl der verfügbaren Pistolen kleiner sein als",
      "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": "Der Bewegungsbereich der Pistolen kann nicht größer sein als die maximal mögliche mechanische Reichweite der Linie:",
      "Loading optimization type...": "Optimierungstyp laden...",
      "Clear & start new optimization for line": "Löschen und neue Optimierung für Linie starten",
      "Please fill the following fields: ": "Bitte füllen Sie die folgenden Felder aus: ",
      "Line speed": "Kettengeschwindigkeit",
      "Coated height": "Beschichtete Höhe",
      "Powder model": "Puder-Modell",
      "Specific Powder Optimization": "Spezifische Puder-Optimierung",
      "Input value out of range" : "Eingabewert außerhalb des Bereichs",
      "was saved successfully." : "wurde erfolgreich gespeichert.",
      "Missing Required Fields" : "Fehlende Pflichtfelder",
      "Guns movement range cannot be 0" : "Bewegungsbereich der Pistolen kann nicht 0 sein",
      "Line speed cannot be 0" : "Kettengeschwindigkeit kann nicht 0 sein",
      "Line speed must be greater than" : "Die Kettengeschwindigkeit muss größer sein als",
      "Line speed must be less than" : "Kettengeschwindigkeit muss kleiner sein als",
      "Covered height must be greater than" : "Beschichtete Höhe muss größer sein als",
      "Guns movement speed cannot be slower than" : "Die Bewegungsgeschwindigkeit der Pistole kann nicht langsamer sein als",
      "Use a faster guns velocity or a different movement range & period time combination." : "Verwenden Sie eine schnellere Pistolengeschwindigkeit oder eine andere Kombination aus Bewegungsbereich und Zeitspanne.",
      "Guns movement speed cannot be faster than" : "Die Bewegungsgeschwindigkeit der Pistole kann nicht schneller sein als",
      "Use a slower guns velocity or a different movement range & period time combination." : "Verwenden Sie eine langsamere Pistolengeschwindigkeit oder eine andere Kombination aus Bewegungsbereich und Zeitspanne.",
      "Guns movement period cannot be longer than" : "Die Bewegungszeit der Pistole kann nicht länger sein als",
      "Use a shorter guns period or a different movement range & period time combination." : "Verwenden Sie eine kürzere Pistolenzeit oder eine andere Kombination aus Bewegungsbereich und Zeitspanne.",
      "Guns movement period must be greater than" : "Die Bewegungszeit der Pistole muss größer sein als",
      "No fields were changed from the previous optimization" : "Bei der vorherigen Optimierung wurden keine Felder geändert.",
      "Do you want to recompute it anyway?" : "Möchten Sie trotzdem eine Neuberechnung durchführen?",
      "Cancel" : "Abbrechen",
      "Yes, recompute it!" : "Ja, berechnen Sie sie neu!",
      "You need to define the Current Settings to generate a report" : "Sie müssen die aktuellen Einstellungen definieren, um einen Bericht zu erstellen",
      "OK" : "OK",
      "Total guns": "Pistolen insgesamt",
      "Total guns per column": "Gesamtpistolen pro Spalte",
      "Gun movement range": "Hubhöhe",
      "Gun Movement Speed": "Pistolen-Bewegungsgeschwindigkeit",
      "Gun Movement Period": "Intervalzeit der Pistolen",
      "Save Optimization to Library": "Optimierung in der Bibliothek speichern",
      "Optimization Saved to Library": "In der Bibliothek gespeicherte Optimierung",
      "optional": "optional",
      "Static Optimization Name": "Statische Optimierung Name",
      "New Optimization for line": "Neue Optimierung für Anlage",
      "Status": "Status",
      "Optimum found": "Optimum gefunden",
      "Ready to find optimum": "Bereit",
      "Static Optimization details": "Details zur statischen Optimierung",
      "Name": "Name",
      "Line": "Anlage",
      "Optimization type": "Art der Optimierung",
      "Select": "Wählen Sie",
      "Powder Models": "Pulver-Modelle",
      "Calibrated Powder Optimization": "Kalibrierte Sprühbilder",
      "Pre-Calibrated Powder Optimization": "Vorkalibrierte Sprühbilder",
      "Generalistic Optimization for any powder": "Generalistische Optimierung für beliebige Pulver",
      "Select Powder Model": "Pulvermodell auswählen",
      "No Powder Models Created. To create one go to": "Keine Sprühbilder erstellt. Um eines zu erstellen, gehen Sie zu",
      "Optimization parameters": "Optimierungsparameter",
      "Line Speed": "Kettengeschwindigkeit",
      "Distance": "Abstand",
      "Covered height": "Bauteilhöhe",
      "Simulation width": "Simulationsbreite",
      "Continue": "Weiter",
      "Compare with Current Settings": "Vergleich mit aktuellen Einstellungen",
      "Number of active guns 1st column": "Anzahl der aktiven Pistolen 1. Spalte",
      "Please select one": "Bitte wählen Sie eine",
      "Number of active guns 2nd column": "Anzahl der aktiven Pistolen 2. Spalte",
      "Number of active guns 3rd column": "Anzahl aktiver Pistolen 3. Spalte",
      "Number of active guns 4th column": "Anzahl der aktiven Pistolen 4. Spalte",
      "Number of active guns 5th column": "Anzahl der aktiven Pistolen 5. Spalte",
      "Guns movement range": "Hubhöhe",
      "Guns movement period time": "Intervalzeit der Pistolen",
      "Guns movement speed": "Bewegungsgeschwindigkeit der Pistolen",
      "Compute": "Optimieren",
      "Optimal machine settings": "Optimale Maschineneinstellungen",
      "Simulation results": "Ergebnisse der Simulation",
      "Current Settings": "Aktuelle Einstellungen",
      "Overspray": "Overspray",
      "Optimal Settings": "Optimale Einstellungen",
      "Uniformity": "Gleichmäßigkeit",
      "Median uniformity": "Gleichmäßigkeit des Medians",
      "Overspray": "Overspray",
      "Visualize graphs": "Diagramme visualisieren",
      "Calculation running": "Berechnung läuft",
      "Uniformity comparison": "Vergleich der Gleichmäßigkeit",
      "Applied spray": "Auftragsverteilung",
      "Movement chart": "Bewegungsdiagramm",
      "Applied spray animation": "Animation",
      "Current Settings Coated Area": "Aktuelle Einstellungen Beschichtete Fläche",
      "Optimal Settings Coated Area": "Optimale Einstellungen Beschichtete Fläche",
      "Current Settings Guns Movement": "Aktuelle Pistolenbewegung",
      "Optimal Settings Guns Movement": "Optimale Pistolenbewegung",
      "Current Settings Coated Area": "Aktuelle Auftragsverteilung",
      "Optimal Settings Coated Area": "Optimale Auftragsverteilung",
      "Comments section for the generated report": "Kommentarbereich für den generierten Bericht",
      "Hide Optimal Settings in the report": "Optimale Einstellungen im Bericht ausblenden",
      "Save Edited Comments": "Bearbeitete Kommentare speichern",
      "Generate Report": "Bericht generieren",
      "Generating optimization report...": "Generierung von Optimierungsberichten...",
      "To use a different optimization type create a new Optimization": "Um einen anderen Optimierungstyp zu verwenden, erstellen Sie eine neue Optimierung",
      "If you want to use a different set of Powder Models, create a new Optimization.": "Wenn Sie einen anderen Satz von Pulvermodellen verwenden möchten, erstellen Sie eine neue Optimierung.",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "Wählen Sie Spezifische Pulveroptimierung, um ein oder mehrere Sprühbilder zur Berechnung der optimalen Einstellungen zu verwenden. Wählen Sie die Option Generalistische Optimierung, um eine pulverunabhängige Optimierungslösung zu erhalten.",
      "Select the Powder Models to be used during the computation of optimal settings.": "Wählen Sie die Sprühbilder aus, die bei der Berechnung der optimalen Einstellungen verwendet werden sollen.",
      "Line velocity value in [meters/minute] units.": "Wert der Kettengeschwindigkeit in der Einheit [Meter/Minute].",
      "Distance from the gun nozzle to the substrate.": "Abstand von der Pistolendüse zum Substrat.",
      "Vertical height for which the uniformity will be optimized.": "Vertikale Höhe, für die die Gleichmäßigkeit optimiert werden soll.",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "Nur für die Visualisierung relevant, bestimmt die horizontale Breite des Simulationsbildes, das im Ergebnisbereich angezeigt wird.",
      "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": "Füllen Sie diesen Abschnitt mit Ihren Pistoleneinstellungen, um zu sehen, wie sie im Vergleich zu den optimalen Einstellungen aussehen.",
      "Clear current settings": "Aktuelle Einstellungen löschen",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "Anzahl der aktiven Pistolen in der ersten Spalte der Pistolen. Der Mindestwert ist 0 und der Höchstwert ist",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "Anzahl der aktiven Pistolen in der zweiten Pistolenspalte. Der Mindestwert ist 0 und der Höchstwert ist",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "Anzahl der aktiven Pistolen in der dritten Pistolenspalte. Der Minimalwert ist 0 und der Maximalwert ist",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "Anzahl der aktiven Pistolen in der vierten Pistolenspalte. Der Minimalwert ist 0 und der Maximalwert ist",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "Anzahl der aktiven Pistolen in der fünften Pistolenspalte. Der Minimalwert ist 0 und der Maximalwert ist",
      "Number of active guns on one side of the booth": "Anzahl der aktiven Pistolen auf einer Seite der Kabine",
      "Activate Toggle to visualize simulation images": "Aktivieren Sie Toggle, um Simulationsbilder zu visualisieren",
      "Gun to gun distance": "Pistole zu Pistolenabstand",
      "Optimal Settings Nozzle Angle": "Optimale Einstellungen Düsenwinkel",
      "Current Settings Nozzle Angle": "Aktuelle Einstellungen Düsenwinkel",
      "guns": "Pistolen",
      "Powder Usage Pie Chart": "Kreisdiagramm zum Pulververbrauch",
      "Optimal Settings Pie Chart": "Kuchendiagramm Optimale Einstellungen",
      "Current Settings Pie Chart": "Kuchendiagramm Optimale Einstellungen",
      "Powder Waste": "Pulverabfall",
    },
    "ES": {
      "": "",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "La cantidad de polvo adicional aplicada debido a una distribución desigual del polvo sobre el sustrato.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "El polvo que no se adhiere a la pieza y que no es recuperado por el sistema de reciclado.",
      "2nd column height offset": "Diferencia de altura de la 2ª columna",
      "3rd column height offset": "Diferencia de altura de la 3ª columna",
      "4th column height offset": "Diferencia de altura de la 4ª columna",
      "5th column height offset": "Diferencia de altura de la 5ª columna",
      "gun": "pistola",
      "Gun to Gun distance must be smaller than": "La distancia de pistola a pistola a debe ser menor que",
      "Gun to Gun distance must be greater than": "La distancia de pistola a pistola a debe ser mayor que",
      "Gun to gun distance cannot be 0": "La distancia de pistola a pistola no puede ser 0",
      "degrees": "grados",
      "Gun column height offsets": "Diferencia de altura de cada columna de pistolas",
      "Nozzle rotation angle": "Ángulo de la boquilla",
      "The covered height, for the number of guns available, must be smaller than ": "La altura cubierta, para el número de pistolas disponibles, debe ser inferior a ",
      "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": "El rango del movimiento de las pistolas no puede ser superior al máximo alcance mecánico de la línea:",
      "Loading optimization type...": "Cargando tipo de optimización...",
      "Clear & start new optimization for line": "Limpiar todo y empezar una nueva optimización",
      "Please fill the following fields: ": "Rellene los siguientes campos: ",
      "Line speed": "Velocidad de la línea",
      "Coated height": "Altura cubierta",
      "Powder model": "Modelo de polvo",
      "Specific Powder Optimization": "Optimización para polvos específicos",
      "Input value out of range" : "Valor fuera de rango",
      "was saved successfully." : "se ha guardado correctamente.",
      "Missing Required Fields" : "Faltan campos obligatorios",
      "Guns movement range cannot be 0" : "El rango de movimiento de las pistola no puede ser 0",
      "Line speed cannot be 0" : "La velocidad de la línea no puede ser 0",
      "Line speed must be greater than" : "La velocidad de la línea debe ser mayor que",
      "Line speed must be less than" : "La velocidad de la línea debe ser menor que",
      "Covered height must be greater than" : "La altura cubierta por la optimización debe ser mayor que",
      "Guns movement speed cannot be slower than" : "La velocidad de las pistolas no puede ser inferior a",
      "Use a faster guns velocity or a different movement range & period time combination." : "Utiliza una velocidad de las pistolas más rápida o una combinación de rango de movimiento y periodo.",
      "Guns movement speed cannot be faster than" : "La velocidad de movimiento de las pistolas no puede ser superior a",
      "Use a slower guns velocity or a different movement range & period time combination." : "Utiliza una velocidad de las pistolas más lenta o una combinación diferente de rango de movimiento y periodo.",
      "Guns movement period cannot be longer than" : "El periodo del movimiento de la pistola a no puede ser mayor que",
      "Use a shorter guns period or a different movement range & period time combination." : "Utiliza un periodo de movimiento de las pistolas más corto o una combinación diferente de rango de movimiento y periodo.",
      "Guns movement period must be greater than" : "El periodo del movimiento de las pistolas debe ser mayor que",
      "No fields were changed from the previous optimization" : "No se ha modificado ningún campo de la optimización.",
      "Do you want to recompute it anyway?" : "¿Desea volver a calcular de todos modos?",
      "Cancel" : "Cancelar",
      "Yes, recompute it!" : "Sí, ¡recalcúla la optimización!",
      "You need to define the Current Settings to generate a report" : "Se necesita definir una Configuración actual para generar un informe",
      "OK" : "OK",
      "Total guns": "Numero de pistolas",
      "Total guns per column": "Numero de pistolas por columna",
      "Gun movement range": "Rango de movimiento de las pistolas",
      "Gun Movement Speed": "Velocidad de movimiento de las pistolas",
      "Gun Movement Period": "Periodo de movimiento de las pistolas",
      "Save Optimization to Library": "Guardadar optimización en la biblioteca",
      "Optimization Saved to Library": "Optimización guardada en la biblioteca",
      "optional": "opcional",
      "Static Optimization Name": "Nombre de la optimización estática",
      "New Optimization for line": "Nueva optimización para la línea",
      "Status": "Estado",
      "Optimum found": "Configuración Óptima encontrada",
      "Ready to find optimum": "Listo para encontrar Configuración Óptima",
      "Static Optimization details": "Detalles de la optimización estática",
      "Name": "Nombre",
      "Line": "Línea",
      "Optimization type": "Tipo de optimización",
      "Select": "Seleccione",
      "Powder Models": "Modelos de polvo",
      "Calibrated Powder Optimization": "Optimización con un polvo calibrado",
      "Pre-Calibrated Powder Optimization": "Optimización con un polvo precalibrado",
      "Generalistic Optimization for any powder": "Optimización generalista para cualquier polvo",
      "Select Powder Model": "Seleccionar Modelo de Polvo",
      "No Powder Models Created. To create one go to": "No se han creado modelos de polvo. Para crear uno vaya a",
      "Optimization parameters": "Parámetros de la optimización",
      "Line Speed": "Velocidad de la línea",
      "Distance": "Distancia",
      "Covered height": "Altura cubierta",
      "Simulation width": "Anchura de la simulación",
      "Continue": "Continuar",
      "Compare with Current Settings": "Comparar con la configuración actual",
      "Number of active guns 1st column": "Número de pistolas activas 1ª columna",
      "Please select one": "Seleccione una",
      "Number of active guns 2nd column": "Número de pistolas activas 2ª columna",
      "Number of active guns 3rd column": "Número de pistolas activas 3ª columna",
      "Number of active guns 4th column": "Número de pistolas activas 4ª columna",
      "Number of active guns 5th column": "Número de pistolas activas 5ª columna",
      "Guns movement range": "Rango de movimiento de las pistolas",
      "Guns movement period time": "Periodo del movimiento de las pistolas",
      "Guns movement speed": "Velocidad de movimiento de las pistolas",
      "Compute": "Calcular",
      "Optimal machine settings": "Ajustes óptimos de la máquina",
      "Simulation results": "Resultados de la simulación",
      "Current Settings": "Configuración actual",
      "Overspray": "Sobrepulverización",
      "Optimal Settings": "Ajustes óptimos",
      "Uniformity": "Uniformidad",
      "Median uniformity": "Uniformidad mediana",
      "Overspray": "Sobrepulverización",
      "Visualize graphs": "Visualizar gráficos",
      "Calculation running": "Cálculo en proceso",
      "Uniformity comparison": "Comparación de la uniformidad",
      "Applied spray": "Pintura aplicada",
      "Movement chart": "Gráfico del movimiento",
      "Applied spray animation": "Animación de la pintura aplicada",
      "Current Settings Coated Area": "Configuración actual Área recubierta",
      "Optimal Settings Coated Area": "Ajustes óptimos Área recubierta",
      "Current Settings Guns Movement": "Configuración actual Movimiento de las pistolas",
      "Optimal Settings Guns Movement": "Ajustes óptimos Movimiento de las pistolas",
      "Current Settings Coated Area": "Configuración actual Área recubierta",
      "Optimal Settings Coated Area": "Ajustes óptimos Área recubierta",
      "Comments section for the generated report": "Sección de comentarios libres para el informe",
      "Hide Optimal Settings in the report": "Ocultar los ajustes óptimos en el informe",
      "Save Edited Comments": "Guardar comentarios editados",
      "Generate Report": "Generar informe",
      "Generating optimization report...": "Generando informe de la optimización...",
      "To use a different optimization type create a new Optimization": "Para utilizar un tipo de optimización diferente cree una nueva Optimización",
      "If you want to use a different set of Powder Models, create a new Optimization.": "Si desea utilizar un conjunto diferente de Modelos de polvo, cree una nueva Optimización.",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "Seleccione Optimización específica de polvo para utilizar uno o más modelos de polvo para calcular los ajustes óptimos. Seleccione la opción Optimización generalista para obtener una solución de optimización agnóstica al polvo.",
      "Select the Powder Models to be used during the computation of optimal settings.": "Seleccione los Modelos de polvo que se utilizarán durante el cálculo de los ajustes óptimos.",
      "Line velocity value in [meters/minute] units.": "Valor de velocidad de la línea en unidades [metros/minuto].",
      "Distance from the gun nozzle to the substrate.": "Distancia de la boquilla de la pistola al sustrato.",
      "Vertical height for which the uniformity will be optimized.": "Altura vertical para la que se optimizará la uniformidad.",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "Sólo relevante a efectos de visualización, determina la anchura de las imagenes de la simulación mostradas en la sección de resultados.",
      "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": "Rellene esta sección con sus ajustes actuales para visualizar la comparación con los ajustes óptimos.",
      "Clear current settings": "Borrar estos ajustes actuales",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "Número de pistolas activas en la primera columna. El mínimo es 0 y el máximo es",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "Número de pistolas activas en la segunda columna. El mínimo es 0 y el máximo es",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "Número de pistolas activas en la tercera columna. El mínimo es 0 y el máximo es",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "Número de pistolas activas en la cuarta columna. El mínimo es 0 y el máximo es",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "Número de pistolas activas en la quinta columna. El mínimo es 0 y el máximo es",
      "Number of active guns on one side of the booth": "Número de pistolas activas en un lado de la cabina",
      "Activate Toggle to visualize simulation images": "Activar para visualizar las imágenes de la simulación",
      "Gun to gun distance": "Distancia entre pistolas",
      "Optimal Settings Nozzle Angle": "Ángulo de la boquilla de los ajustes óptimos",
      "Current Settings Nozzle Angle": "Ángulo de la boquilla de la configuración actual",
      "guns": "pistolas",
      "Powder Usage Pie Chart": "Grafico del uso de la pintura",
      "Optimal Settings Pie Chart": "Configuración óptima",
      "Current Settings Pie Chart": "Configuración actual",
      "Powder Waste": "Desperdicio de polvo",
    },
    "FR": {
      "": "",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "La quantité de poudre supplémentaire appliquée en raison d'une distribution inégale de la poudre sur le substrat.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "La poudre qui n'adhère pas à la pièce et qui n'est donc pas récupérée par le système de recyclage.",
      "2nd column height offset": "Décalage de la hauteur de la 2ème colonne",
      "3rd column height offset": "Décalage de la hauteur de la 3ème colonne",
      "4th column height offset": "Décalage de la hauteur de la 4ème colonne",
      "5th column height offset": "Décalage de la hauteur de la 5ème colonne",
      "gun": "pistolet",
      "Gun to Gun distance must be smaller than": "La distance de pistolet à pistolet doit être inférieure à",
      "Gun to Gun distance must be greater than": "La distance de pistolet à pistolet doit être supérieure à",
      "Gun to gun distance cannot be 0": "La distance de pistolet à pistolet ne peut pas être 0",
      "degrees": "degrés",
      "Gun column height offsets": "Décalage de la hauteur de la colonne du pistolet",
      "Nozzle rotation angle": "Angle de rotation de la buse",
      "The covered height, for the number of guns available, must be smaller than ": "La hauteur couverte, pour le nombre de pistolets disponibles, doit être inférieure à ",
      "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": "La portée de mouvement des pistolets ne peut être supérieure à la portée mécanique maximale possible de la ligne:",
      "Loading optimization type...": "Chargement du type d'optimisation...",
      "Clear & start new optimization for line": "Effacer et commencer une nouvelle optimisation de la ligne",
      "Please fill the following fields: ": "Veuillez remplir les champs suivants: ",
      "Line speed": "Vitesse de la ligne",
      "Coated height": "Hauteur du revêtement",
      "Powder model": "Modèle de poudre",
      "Specific Powder Optimization": "Optimisation des poudres spécifiques",
      "Input value out of range" : "Valeur d'entrée en dehors de la plage",
      "was saved successfully." : "a été enregistrée avec succès.",
      "Missing Required Fields" : "Champs obligatoires manquants",
      "Guns movement range cannot be 0" : "La portée des mouvements des pistolets ne peut pas être égale à 0",
      "Line speed cannot be 0" : "La vitesse de la ligne ne peut pas être égale à 0",
      "Line speed must be greater than" : "La vitesse de la ligne doit être supérieure à",
      "Line speed must be less than" : "La vitesse de la ligne doit être inférieure à",
      "Covered height must be greater than" : "La hauteur du revêtement doit être supérieure à",
      "Guns movement speed cannot be slower than" : "La vitesse de mouvement des pistolets ne peut pas être inférieure à",
      "Use a faster guns velocity or a different movement range & period time combination." : "Utiliser une vitesse de pistolet plus rapide ou une combinaison différente de portée de mouvement et de durée.",
      "Guns movement speed cannot be faster than" : "La vitesse de mouvement des pistolets ne peut pas être supérieure à",
      "Use a slower guns velocity or a different movement range & period time combination." : "Utilisez une vitesse de pistolet plus lente ou une combinaison différente de plage de mouvement et de durée de période.",
      "Guns movement period cannot be longer than" : "La période de mouvement des pistolets ne peut pas être plus longue que",
      "Use a shorter guns period or a different movement range & period time combination." : "Utilisez une période de mouvement du pistolet plus courte ou une combinaison différente d'amplitude de mouvement et de durée de période.",
      "Guns movement period must be greater than" : "La période de mouvement des pistolets doit être supérieure à",
      "No fields were changed from the previous optimization" : "Aucun champ n'a été modifié par rapport à l'optimisation précédente",
      "Do you want to recompute it anyway?" : "Voulez-vous quand même recalculer l'optimisation ?",
      "Cancel" : "Annuler",
      "Yes, recompute it!" : "Oui, recalculer !",
      "You need to define the Current Settings to generate a report" : "Vous devez définir les paramètres actuels pour générer un rapport.",
      "OK" : "OK",
      "Total guns": "Total des pistolets",
      "Total guns per column": "Total des pistolets par colonne",
      "Gun movement range": "Portée de mouvement des pistolets",
      "Gun Movement Speed": "Vitesse de mouvement du pistolet",
      "Gun Movement Period": "Période de mouvement du pistolet",
      "Save Optimization to Library": "Sauvegarde de l'optimisation dans la bibliothèque",
      "Optimization Saved to Library": "Optimisation sauvegardée dans la bibliothèque",
      "optional": "facultatif",
      "Static Optimization Name": "Nom de l'optimisation statique",
      "New Optimization for line": "Nouvelle optimisation pour la ligne",
      "Status": "Statut",
      "Optimum found": "Optimum trouvé",
      "Ready to find optimum": "Prêt à trouver l'optimum",
      "Static Optimization details": "Détails de l'optimisation statique",
      "Name": "Nom de la ligne",
      "Line": "Ligne",
      "Optimization type": "Type d'optimisation",
      "Select": "Sélectionner",
      "Powder Models": "Modèles de poudres",
      "Calibrated Powder Optimization": "Optimisation de la poudre calibrée",
      "Pre-Calibrated Powder Optimization": "Optimisation des poudres pré-calibrées",
      "Generalistic Optimization for any powder": "Optimisation généraliste pour n'importe quelle poudre",
      "Select Powder Model": "Sélectionner un modèle de poudre",
      "No Powder Models Created. To create one go to": "Aucun modèle de poudre n'a été créé. Pour en créer un, allez à",
      "Optimization parameters": "Paramètres d'optimisation",
      "Line Speed": "Vitesse de la ligne",
      "Distance": "Distance",
      "Covered height": "Hauteur couverte",
      "Simulation width": "Largeur de la simulation",
      "Continue": "Continuer",
      "Compare with Current Settings": "Comparer avec les paramètres actuels",
      "Number of active guns 1st column": "Nombre de pistolets actifs 1ère colonne",
      "Please select one": "Veuillez sélectionner un",
      "Number of active guns 2nd column": "Nombre de pistolets actifs 2ème colonne",
      "Number of active guns 3rd column": "Nombre de pistolets actifs 3ème colonne",
      "Number of active guns 4th column": "Nombre de pistolets actifs 4ème colonne",
      "Number of active guns 5th column": "Nombre de pistolets actifs 5ème colonne",
      "Guns movement range": "Portée du mouvement des pistolets",
      "Guns movement period time": "Durée de la période de mouvement des pistolets",
      "Guns movement speed": "Vitesse de mouvement des pistolets",
      "Compute": "Calculer",
      "Optimal machine settings": "Paramètres optimaux de la machine",
      "Simulation results": "Résultats de la simulation",
      "Current Settings": "Réglages actuels",
      "Overspray": "Surpulvérisation",
      "Optimal Settings": "Réglages optimaux",
      "Uniformity": "Uniformité",
      "Median uniformity": "Uniformité médiane",
      "Overspray": "Pulvérisation",
      "Visualize graphs": "Visualiser les graphiques",
      "Calculation running": "Calculs en cours",
      "Uniformity comparison": "Comparaison de l'uniformité",
      "Applied spray": "Pulvérisation appliquée",
      "Movement chart": "Graphique de mouvement",
      "Applied spray animation": "Animation de la pulvérisation appliquée",
      "Current Settings Coated Area": "Réglages actuels Surface enrobée",
      "Optimal Settings Coated Area": "Réglages optimaux Surface enrobée",
      "Current Settings Guns Movement": "Réglages actuels Mouvement des pistolets",
      "Optimal Settings Guns Movement": "Réglages optimaux Mouvement des pistolets",
      "Current Settings Coated Area": "Réglages actuels de la zone enduite",
      "Optimal Settings Coated Area": "Réglages optimaux de la zone enduite",
      "Comments section for the generated report": "Section des commentaires pour le rapport généré",
      "Hide Optimal Settings in the report": "Cacher les paramètres optimaux dans le rapport",
      "Save Edited Comments": "Sauvegarder les commentaires édités",
      "Generate Report": "Générer le rapport",
      "Generating optimization report...": "Générer un rapport d'optimisation...",
      "To use a different optimization type create a new Optimization": "Pour utiliser un type d'optimisation différent, créez une nouvelle optimisation.",
      "If you want to use a different set of Powder Models, create a new Optimization.": "Si vous souhaitez utiliser un ensemble différent de modèles de poudre, créez une nouvelle optimisation.",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "Sélectionnez l'option Optimisation spécifique à la poudre pour utiliser un ou plusieurs modèles de poudre afin de calculer les paramètres optimaux. Sélectionnez l'option Optimisation généraliste pour obtenir une solution d'optimisation indépendante des poudres.",
      "Select the Powder Models to be used during the computation of optimal settings.": "Sélectionnez les modèles de poudre à utiliser lors du calcul des paramètres optimaux.",
      "Line velocity value in [meters/minute] units.": "Valeur de la vitesse de la ligne en unités [mètres/minute].",
      "Distance from the gun nozzle to the substrate.": "Distance entre la buse du pistolet et le substrat.",
      "Vertical height for which the uniformity will be optimized.": "Hauteur verticale pour laquelle l'uniformité sera optimisée.",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "La largeur horizontale de l'image de simulation affichée dans la section des résultats n'est utile qu'à des fins de visualisation.",
      "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": "Remplissez cette section avec les paramètres de vos pistolets pour visualiser leur comparaison avec les paramètres optimaux.",
      "Clear current settings": "Effacer les paramètres actuels",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "Nombre de pistolets actifs dans la première colonne de pistolets. Le minimum est 0 et le maximum est",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "Nombre de pistolets actifs dans la deuxième colonne de pistolets. Le minimum est 0 et le maximum est",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "Nombre de pistolets actifs sur la troisième colonne de pistolets. Le minimum est 0 et le maximum est",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "Nombre de pistolets actifs sur la quatrième colonne de pistolets. Le minimum est 0 et le maximum est",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "Nombre de pistolets actifs sur la cinquième colonne de pistolets. Le minimum est 0 et le maximum est",
      "Number of active guns on one side of the booth": "Nombre de pistolets actifs sur un côté de la cabine",
      "Activate Toggle to visualize simulation images": "Activer la bascule pour visualiser les images de simulation",
      "Gun to gun distance": "Distance entre pistolets",
      "Optimal Settings Nozzle Angle": "Paramètres optimaux Angle de la buse",
      "Current Settings Nozzle Angle": "Paramètres actuels Angle de la buse",
      "guns": "pistolets",
      "Powder Usage Pie Chart": "Diagramme à secteurs de l'utilisation de la poudre",
      "Optimal Settings Pie Chart": "Diagramme à secteurs des réglages optimaux",
      "Current Settings Pie Chart": "Diagramme à secteurs des réglages actuels",
      "Powder Waste": "Déchets de poudre",
    },
    "IT": {
      "": "",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "La quantità di polvere extra applicata a causa di una distribuzione non uniforme della polvere sul substrato.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "La polvere che non aderisce al pezzo e che non viene recuperata dal sistema di riciclaggio.",
      "2nd column height offset": "Sfalsamento dell'altezza della 2a colonna",
      "3rd column height offset": "Sfalsamento dell'altezza della 3a colonna",
      "4th column height offset": "Sfalsamento dell'altezza della 4a colonna",
      "5th column height offset": "Sfalsamento dell'altezza della 5a colonna",
      "gun": "pistola",
      "Gun to Gun distance must be smaller than": "La distanza pistola-pistola deve essere inferiore a",
      "Gun to Gun distance must be greater than": "La distanza pistola-pistola deve essere superiore a",
      "Gun to gun distance cannot be 0": "La distanza da pistola a pistola non può essere 0",
      "degrees": "gradi",
      "Gun column height offsets": "Disassamento dell'altezza della colonna della pistola",
      "Nozzle rotation angle": "Angolo di rotazione dell'ugello",
      "The covered height, for the number of guns available, must be smaller than ": "L'altezza coperta, per il numero di pistole disponibili, deve essere inferiore a ",
      "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": "La portata di movimento delle pistole non può essere superiore alla portata meccanica massima possibile della linea:",
      "Loading optimization type...": "Caricamento del tipo di ottimizzazione...",
      "Clear & start new optimization for line": "Cancella e avvia una nuova ottimizzazione per la linea",
      "Please fill the following fields: ": "Compilare i seguenti campi: ",
      "Line speed": "Velocità della linea",
      "Coated height": "Altezza del rivestimento",
      "Powder model": "Modello di polvere",
      "Specific Powder Optimization": "Ottimizzazione di polveri specifiche",
      "Input value out of range" : "Valore di ingresso fuori dall'intervallo",
      "was saved successfully." : "è stato salvato con successo.",
      "Missing Required Fields" : "Campi obbligatori mancanti",
      "Guns movement range cannot be 0" : "Il raggio di movimento delle pistole non può essere 0",
      "Line speed cannot be 0" : "La velocità della linea non può essere 0",
      "Line speed must be greater than" : "La velocità della linea deve essere maggiore di",
      "Line speed must be less than" : "La velocità della linea deve essere inferiore a",
      "Covered height must be greater than" : "L'altezza del rivestimento deve essere maggiore di",
      "Guns movement speed cannot be slower than" : "La velocità di movimento delle pistole non può essere inferiore a",
      "Use a faster guns velocity or a different movement range & period time combination." : "Utilizzare una velocità di movimento della pistola più elevata o una diversa combinazione di intervallo di movimento e di tempo.",
      "Guns movement speed cannot be faster than" : "La velocità di movimento delle pistole non può essere superiore a",
      "Use a slower guns velocity or a different movement range & period time combination." : "Utilizzare una velocità della pistola più lenta o una diversa combinazione di intervallo di movimento e periodo.",
      "Guns movement period cannot be longer than" : "Il periodo di movimento delle pistole non può essere più lungo di",
      "Use a shorter guns period or a different movement range & period time combination." : "Utilizzare un periodo di movimento della pistola più breve o una diversa combinazione di intervallo di movimento e periodo di tempo.",
      "Guns movement period must be greater than" : "Il periodo di movimento delle pistole deve essere maggiore di",
      "No fields were changed from the previous optimization" : "Nessun campo è stato modificato rispetto all'ottimizzazione precedente",
      "Do you want to recompute it anyway?" : "Volete comunque ricalcolarla?",
      "Cancel" : "Annulla",
      "Yes, recompute it!" : "Sì, ricalcolo!",
      "You need to define the Current Settings to generate a report" : "È necessario definire le Impostazioni correnti per generare un rapporto",
      "OK" : "OK",
      "Total guns": "Totale pistole",
      "Total guns per column": "Totale pistole per colonna",
      "Gun movement range": "Rango di movimento delle pistole",
      "Gun Movement Speed": "Velocità di movimento della pistola",
      "Gun Movement Period": "Periodo di movimento della pistola",
      "Save Optimization to Library": "Salvare l'ottimizzazione nella libreria",
      "Optimization Saved to Library": "Ottimizzazione salvata nella libreria",
      "optional": "opzionale",
      "Static Optimization Name": "Nome dell'ottimizzazione statica",
      "New Optimization for line": "Nuova ottimizzazione per la linea",
      "Status": "Stato",
      "Optimum found": "Ottimale trovato",
      "Ready to find optimum": "Pronto per l'ottimizzazione",
      "Static Optimization details": "Dettagli dell'ottimizzazione statica",
      "Name": "Nome",
      "Line": "Linea",
      "Optimization type": "Tipo di ottimizzazione",
      "Select": "Selezionare",
      "Powder Models": "Modelli di polvere",
      "Calibrated Powder Optimization": "Ottimizzazione con modello di polvere calibrato",
      "Pre-Calibrated Powder Optimization": "Ottimizzazione con modello di polvere pre-calibrato",
      "Generalistic Optimization for any powder": "Ottimizzazione generalista per qualsiasi polvere",
      "Select Powder Model": "Selezionare il modello di polvere",
      "No Powder Models Created. To create one go to": "Non sono stati creati modelli di polvere. Per crearne uno, andare su",
      "Optimization parameters": "Parametri di ottimizzazione",
      "Line Speed": "Velocità della linea",
      "Distance": "Distanza",
      "Covered height": "Altezza coperta",
      "Simulation width": "Larghezza di simulazione",
      "Continue": "Continua",
      "Compare with Current Settings": "Confronta con le impostazioni correnti",
      "Number of active guns 1st column": "Numero di pistole attive 1º colonna",
      "Please select one": "Selezionare una",
      "Number of active guns 2nd column": "Numero di pistole attive nella 2º colonna",
      "Number of active guns 3rd column": "Numero di pistole attive nella 3º colonna",
      "Number of active guns 4th column": "Numero di pistole attive nella 4º colonna",
      "Number of active guns 5th column": "Numero di pistole attive nella 5º colonna",
      "Guns movement range": "Rango di movimento delle pistole",
      "Guns movement period time": "Tempo di movimento delle pistole",
      "Guns movement speed": "Velocità di movimento delle pistole",
      "Compute": "Calcola",
      "Optimal machine settings": "Impostazioni ottimali della macchina",
      "Simulation results": "Risultati della simulazione",
      "Current Settings": "Impostazioni attuali",
      "Overspray": "Polvere Persa",
      "Optimal Settings": "Impostazioni ottimali",
      "Uniformity": "Uniformità",
      "Median uniformity": "Uniformità mediana",
      "Overspray": "Polvere Persa",
      "Visualize graphs": "Visualizzazione dei grafici",
      "Calculation running": "Esecuzione del calcolo",
      "Uniformity comparison": "Confronto dell'uniformità",
      "Applied spray": "Spruzzo applicato",
      "Movement chart": "Grafico del movimento",
      "Applied spray animation": "Animazione dello spruzzo applicato",
      "Current Settings Coated Area": "Impostazioni attuali Area rivestita",
      "Optimal Settings Coated Area": "Impostazioni ottimali Area rivestita",
      "Current Settings Guns Movement": "Impostazioni attuali Movimento delle pistole",
      "Optimal Settings Guns Movement": "Impostazioni ottimali Movimento pistole",
      "Current Settings Coated Area": "Impostazioni attuali Area rivestita",
      "Optimal Settings Coated Area": "Impostazioni ottimali Area rivestita",
      "Comments section for the generated report": "Sezione commenti per il rapporto generato",
      "Hide Optimal Settings in the report": "Nascondi le impostazioni ottimali nel rapporto",
      "Save Edited Comments": "Salva i commenti modificati",
      "Generate Report": "Genera rapporto",
      "Generating optimization report...": "Generazione del rapporto di ottimizzazione...",
      "To use a different optimization type create a new Optimization": "Per utilizzare un tipo di ottimizzazione diverso, creare una nuova Ottimizzazione",
      "If you want to use a different set of Powder Models, create a new Optimization.": "Se si desidera utilizzare una serie diversa di modelli di polvere, creare una nuova ottimizzazione.",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "Selezionare l'ottimizzazione Polvere specifica per utilizzare uno o più modelli di polvere per calcolare le impostazioni ottimali. Selezionare l'opzione Ottimizzazione generalista per ottenere una soluzione di ottimizzazione agnostica della polvere.",
      "Select the Powder Models to be used during the computation of optimal settings.": "Selezionare i modelli di polvere da utilizzare durante il calcolo delle impostazioni ottimali.",
      "Line velocity value in [meters/minute] units.": "Valore della velocità di linea in unità [metri/minuto].",
      "Distance from the gun nozzle to the substrate.": "Distanza dall'ugello della pistola al substrato.",
      "Vertical height for which the uniformity will be optimized.": "Altezza verticale per la quale verrà ottimizzata l'uniformità.",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "Rilevante solo ai fini della visualizzazione, determina la larghezza orizzontale dell'immagine di simulazione mostrata nella sezione dei risultati.",
      "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": "Riempire questa sezione con le impostazioni della pistola per visualizzare il confronto con le impostazioni ottimali.",
      "Clear current settings": "Cancella le impostazioni correnti",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "Numero di pistole attive nella prima colonna di pistole. Il minimo è 0 e il massimo è",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "Numero di pistole attive nella seconda colonna di pistole. Il minimo è 0 e il massimo è",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "Numero di pistole attive nella terza colonna di pistole. Il minimo è 0 e il massimo è",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "Numero di pistole attive nella quarta colonna di pistole. Il minimo è 0 e il massimo è",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "Numero di pistole attive nella quinta colonna di pistole. Il minimo è 0 e il massimo è",
      "Number of active guns on one side of the booth": "Numero di pistole attive su un lato della cabina",
      "Activate Toggle to visualize simulation images": "Attivare la funzione Toggle per visualizzare le immagini della simulazione.",
      "Gun to gun distance": "Distanza da pistola a pistola",
      "Optimal Settings Nozzle Angle": "Impostazioni ottimali Angolo dell'ugello",
      "Current Settings Nozzle Angle": "Impostazioni correnti Angolo dell'ugello",
      "guns": "pistole",
      "Powder Usage Pie Chart": "Grafico dell'uso della polvere",
      "Optimal Settings Pie Chart": "Grafico a torta delle impostazioni ottimali",
      "Current Settings Pie Chart": "Grafico a torta delle impostazioni correnti",
      "Powder Waste": "Spreco di polvere",
    },
    "PL": {
      "": "",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "Ilość dodatkowego proszku nałożonego z powodu nierównomiernego rozprowadzenia proszku na podłożu.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "Proszek, który nie przylega do części i nie jest odzyskiwany przez system recyklingu.",
      "2nd column height offset": "Przesunięcie wysokości 2. kolumny",
      "3rd column height offset": "Przesunięcie wysokości 3. kolumny",
      "4th column height offset": "Przesunięcie wysokości 4. kolumny",
      "5th column height offset": "Przesunięcie wysokości 5. kolumny",
      "gun": "pistolet",
      "Gun to Gun distance must be smaller than": "Odległość między pistoletami musi być mniejsza niż",
      "Gun to Gun distance must be greater than": "Odległość między pistoletami musi być większa niż",
      "Gun to gun distance cannot be 0": "Odległość między pistoletami nie może wynosić 0",
      "degrees": "stopni",
      "Gun column height offsets": "Przesunięcie wysokości kolumny pistoletu",
      "Nozzle rotation angle": "Kąt obrotu dyszy",
      "The covered height, for the number of guns available, must be smaller than ": "Pokryta wysokość, dla liczby dostępnych pistoletów, musi być mniejsza niż ",
      "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": "Zakres ruchu pistoletów nie może być większy niż maksymalny możliwy zakres mechaniczny linii:",
      "Loading optimization type...": "Ładowanie typu optymalizacji...",
      "Clear & start new optimization for line": "Wyczyść i rozpocznij nową optymalizację dla linii",
      "Please fill the following fields: ": "Wypełnij następujące pola: ",
      "Line speed": "Prędkość linii",
      "Coated height": "Wysokość powłoki",
      "Powder model": "Model proszku",
      "Specific Powder Optimization": "Optymalizacja konkretnego proszku",
      "Input value out of range" : "Wartość wejściowa poza zakresem",
      "was saved successfully." : "została pomyślnie zapisana.",
      "Missing Required Fields" : "Brakujące wymagane pola",
      "Guns movement range cannot be 0" : "Zakres ruchu pistoletów nie może wynosić 0",
      "Line speed cannot be 0" : "Prędkość liniowa nie może wynosić 0",
      "Line speed must be greater than" : "Prędkość liniowa musi być większa niż",
      "Line speed must be less than" : "Prędkość linii musi być mniejsza niż",
      "Covered height must be greater than" : "Wysokość powłoki musi być większa niż",
      "Guns movement speed cannot be slower than" : "Prędkość ruchu pistoletów nie może być mniejsza niż",
      "Use a faster guns velocity or a different movement range & period time combination." : "Użyj większej prędkości pistoletów lub innej kombinacji zakresu ruchu i czasu.",
      "Guns movement speed cannot be faster than" : "Prędkość ruchu pistoletów nie może być większa niż",
      "Use a slower guns velocity or a different movement range & period time combination." : "Użyj wolniejszej prędkości pistoletów lub innej kombinacji zakresu ruchu i czasu.",
      "Guns movement period cannot be longer than" : "Okres ruchu pistoletów nie może być dłuższy niż",
      "Use a shorter guns period or a different movement range & period time combination." : "Użyj krótszego okresu ruchu pistoletów lub innej kombinacji zakresu ruchu i czasu.",
      "Guns movement period must be greater than" : "Okres ruchu pistoletów musi być większy niż",
      "No fields were changed from the previous optimization" : "Żadne pola nie zostały zmienione w stosunku do poprzedniej optymalizacji",
      "Do you want to recompute it anyway?" : "Czy mimo to chcesz przeprowadzić ponowne obliczenia?",
      "Cancel" : "Anuluj",
      "Yes, recompute it!" : "Tak, oblicz ponownie!",
      "You need to define the Current Settings to generate a report" : "Musisz zdefiniować bieżące ustawienia, aby wygenerować raport",
      "OK" : "OK",
      "Total guns": "Pistolety ogółem",
      "Total guns per column": "Łączna liczba pistoletów na kolumnę",
      "Gun movement range": "Zasięg ruchu pistoletu",
      "Gun Movement Speed": "Prędkość ruchu pistoletu",
      "Gun Movement Period": "Okres ruchu pistoletu",
      "Save Optimization to Library": "Zapisywanie optymalizacji w bibliotece",
      "Optimization Saved to Library": "Optymalizacja zapisana w bibliotece",
      "optional": "opcjonalny",
      "Static Optimization Name": "Statyczna nazwa optymalizacji",
      "New Optimization for line": "Nowa optymalizacja dla linii",
      "Status": "Status",
      "Optimum found": "Znaleziono optimum",
      "Ready to find optimum": "Gotowy do znalezienia optimum",
      "Static Optimization details": "Szczegóły optymalizacji statycznej",
      "Name": "Nazwa",
      "Line": "Linia",
      "Optimization type": "Typ optymalizacji",
      "Select": "Wybór",
      "Powder Models": "Modele proszków",
      "Calibrated Powder Optimization": "Skalibrowana optymalizacja proszku",
      "Pre-Calibrated Powder Optimization": "Wstępnie skalibrowana optymalizacja proszku",
      "Generalistic Optimization for any powder": "Optymalizacja ogólna dla dowolnego proszku",
      "Select Powder Model": "Wybierz model proszku",
      "No Powder Models Created. To create one go to": "Nie utworzono modeli proszku. Aby go utworzyć, przejdź do",
      "Optimization parameters": "Parametry optymalizacji",
      "Line Speed": "Prędkość linii",
      "Distance": "Odległość",
      "Covered height": "Pokryta wysokość",
      "Simulation width": "Szerokość symulacji",
      "Continue": "Kontynuuj",
      "Compare with Current Settings": "Porównaj z bieżącymi ustawieniami",
      "Number of active guns 1st column": "Liczba aktywnych pistoletów 1. kolumna",
      "Please select one": "Wybierz jeden",
      "Number of active guns 2nd column": "Liczba aktywnych pistoletów 2. kolumna",
      "Number of active guns 3rd column": "Liczba aktywnych pistoletów 3. kolumna",
      "Number of active guns 4th column": "Liczba aktywnych pistoletów 4. kolumna",
      "Number of active guns 5th column": "Liczba aktywnych pistoletów 5. kolumna",
      "Guns movement range": "Zasięg ruchu pistoletów",
      "Guns movement period time": "Czas ruchu pistoletów",
      "Guns movement speed": "Prędkość ruchu pistoletów",
      "Compute": "Oblicz",
      "Optimal machine settings": "Optymalne ustawienia maszyny",
      "Simulation results": "Wyniki symulacji",
      "Current Settings": "Bieżące ustawienia",
      "Uniformity": "Jednorodność",
      "Median uniformity": "Jednorodność mediany",
      "Overspray": "Nadmierny natrysk",
      "Optimal Settings": "Optymalne ustawienia",
      "Overspray": "Nadmierny natrysk",
      "Visualize graphs": "Wizualizacja wykresów",
      "Calculation running": "Uruchamianie obliczeń",
      "Uniformity comparison": "Porównanie jednorodności",
      "Applied spray": "Zastosowany natrysk",
      "Movement chart": "Wykres ruchu",
      "Applied spray animation": "Animacja natrysku",
      "Current Settings Coated Area": "Bieżące ustawienia Powlekany obszar",
      "Optimal Settings Coated Area": "Ustawienia optymalne Obszar powlekany",
      "Current Settings Guns Movement": "Bieżące ustawienia Ruch pistoletów",
      "Optimal Settings Guns Movement": "Optymalne ustawienia ruchu pistoletów",
      "Current Settings Coated Area": "Bieżące ustawienia Pokryty obszar",
      "Optimal Settings Coated Area": "Ustawienia optymalne Obszar pokryty",
      "Comments section for the generated report": "Sekcja komentarzy do wygenerowanego raportu",
      "Hide Optimal Settings in the report": "Ukryj ustawienia optymalne w raporcie",
      "Save Edited Comments": "Zapisz edytowane komentarze",
      "Generate Report": "Generowanie raportu",
      "Generating optimization report...": "Generowanie raportów optymalizacji...",
      "To use a different optimization type create a new Optimization": "Aby użyć innego typu optymalizacji, utwórz nową optymalizację",
      "If you want to use a different set of Powder Models, create a new Optimization.": "Jeśli chcesz użyć innego zestawu modeli proszku, utwórz nową optymalizację.",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "Wybierz opcję Specific Powder optimization, aby użyć jednego lub więcej modeli proszku do obliczenia optymalnych ustawień. Wybierz opcję Generalistic Optimization, aby uzyskać rozwiązanie optymalizacyjne niezależne od proszku.",
      "Select the Powder Models to be used during the computation of optimal settings.": "Wybierz modele proszku, które mają być używane podczas obliczania optymalnych ustawień.",
      "Line velocity value in [meters/minute] units.": "Wartość prędkości liniowej w jednostkach [metry/minutę].",
      "Distance from the gun nozzle to the substrate.": "Odległość od dyszy pistoletu do podłoża.",
      "Vertical height for which the uniformity will be optimized.": "Wysokość pionowa, dla której zostanie zoptymalizowana jednorodność.",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "Ma znaczenie tylko dla celów wizualizacji, określa szerokość poziomą obrazu symulacji wyświetlanego w sekcji wyników.",
      "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": "Wypełnij tę sekcję ustawieniami broni, aby zobaczyć, jak wypadają one w porównaniu z ustawieniami optymalnymi.",
      "Clear current settings": "Wyczyść bieżące ustawienia",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "Liczba aktywnych pistoletów w pierwszej kolumnie pistoletów. Minimum to 0, a maksimum to",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "Liczba aktywnych pistoletów w drugiej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "Liczba aktywnych pistoletów w trzeciej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "Liczba aktywnych pistoletów w czwartej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "Liczba aktywnych pistoletów w piątej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to",
      "Number of active guns on one side of the booth": "Liczba aktywnych pistoletów po jednej stronie kabiny",
      "Activate Toggle to visualize simulation images": "Aktywuj przełącznik, aby wyświetlić obrazy symulacji",
      "Gun to gun distance": "Odległość między Działami",
      "Optimal Settings Nozzle Angle": "Optymalne ustawienia kąta dyszy",
      "Current Settings Nozzle Angle": "Bieżące ustawienia kąta dyszy",
      "guns": "pistolety",
      "Powder Usage Pie Chart": "Wykres kołowy zużycia proszku",
      "Optimal Settings Pie Chart": "Wykres kołowy optymalnych ustawień",
      "Current Settings Pie Chart": "Wykres kołowy bieżących ustawień",
      "Powder Waste": "Odpady proszkowe",
    },
    "TR": {
      "": "",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "Alt tabaka üzerinde eşit olmayan toz dağılımı nedeniyle uygulanan ekstra toz miktarı.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "Parçaya yapışmayan ve daha sonra geri dönüşüm sistemi tarafından geri kazanılmayan toz.",
      "Gun to Gun distance must be smaller than": "Tabanca-Tabanca mesafesi aşağıdakilerden daha küçük olmalıdır",
      "Gun to Gun distance must be greater than": "Tabanca-Tabanca mesafesi aşağıdakilerden daha büyük olmalıdır",
      "Gun to gun distance cannot be 0": "Tabancadan tabancaya mesafe 0 olamaz",
      "gun": "tabanca",
      "degrees": "dereceydi",
      "Gun column height offsets": "Tabanca kolon yüksekliği ofsetleri",
      "Nozzle rotation angle": "Nozul dönüş açısı",
      "The covered height, for the number of guns available, must be smaller than ": "Mevcut tabanca sayısı için kapalı yükseklik, aşağıdakilerden daha küçük olmalıdır ",
      "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": "Tabancaların hareket menzili, hattın mümkün olan maksimum mekanik menzilinden daha büyük olamaz:",
      "Loading optimization type...": "Optimizasyon türü yükleniyor...",
      "Clear & start new optimization for line": "Hat için yeni optimizasyonu temizleyin ve başlatın",
      "Please fill the following fields: ": "Lütfen aşağıdaki alanları doldurunuz: ",
      "Line speed": "Hat hızı",
      "Coated height": "Kaplamalı yükseklik",
      "Powder model": "Toz modeli",
      "Specific Powder Optimization": "Spesifik Toz Optimizasyonu",
      "Input value out of range" : "Giriş değeri aralık dışında",
      "was saved successfully." : "başarıyla kaydedildi.",
      "Missing Required Fields" : "Eksik Gerekli Alanlar",
      "Guns movement range cannot be 0" : "Tabancaların hareket menzili 0 olamaz",
      "Line speed cannot be 0" : "Hat hızı 0 olamaz",
      "Line speed must be greater than" : "Hat hızı aşağıdakilerden daha yüksek olmalıdır",
      "Line speed must be less than" : "Hat hızı aşağıdakilerden daha az olmalıdır",
      "Covered height must be greater than" : "Kaplanmış yükseklik aşağıdakilerden daha büyük olmalıdır",
      "Guns movement speed cannot be slower than" : "Tabancaların hareket hızı aşağıdakilerden daha yavaş olamaz",
      "Use a faster guns velocity or a different movement range & period time combination." : "Daha hızlı bir tabanca hızı veya farklı bir hareket aralığı ve süre kombinasyonu kullanın.",
      "Guns movement speed cannot be faster than" : "Tabanca hareket hızı aşağıdakilerden daha hızlı olamaz",
      "Use a slower guns velocity or a different movement range & period time combination." : "Daha yavaş bir tabanca hızı veya farklı bir hareket aralığı ve süre kombinasyonu kullanın.",
      "Guns movement period cannot be longer than" : "Tabancanın hareket süresi şundan daha uzun olamaz",
      "Use a shorter guns period or a different movement range & period time combination." : "Daha kısa bir tabanca süresi veya farklı bir hareket aralığı ve süre kombinasyonu kullanın.",
      "Guns movement period must be greater than" : "Tabanca hareket süresi aşağıdakilerden daha büyük olmalıdır",
      "No fields were changed from the previous optimization" : "Önceki optimizasyona göre hiçbir alan değiştirilmedi",
      "Do you want to recompute it anyway?" : "Yine de yeniden hesaplamak ister misiniz?",
      "Cancel" : "İptal et",
      "Yes, recompute it!" : "Evet, yeniden hesaplayın!",
      "You need to define the Current Settings to generate a report" : "Bir rapor oluşturmak için Geçerli Ayarları tanımlamanız gerekir",
      "OK" : "TAMAM",
      "Total guns": "Toplam tabanca",
      "Total guns per column": "Sütun başına toplam tabanca",
      "Gun movement range": "Tabanca hareket aralığı",
      "Gun Movement Speed": "Tabanca Hareket Hızı",
      "Gun Movement Period": "Tabanca Hareket Dönemi",
      "Save Optimization to Library": "Optimizasyonu Kütüphaneye Kaydet",
      "Optimization Saved to Library": "Kütüphaneye Kaydedilen Optimizasyon",
      "optional": "isteğe bağlı",
      "Static Optimization Name": "Statik Optimizasyon Adı",
      "New Optimization for line": "Hat için Yeni Optimizasyon",
      "Status": "Durum",
      "Optimum found": "Optimum bulundu",
      "Ready to find optimum": "Optimum bulmaya hazır",
      "Static Optimization details": "Statik Optimizasyon ayrıntıları",
      "Name": "İsim",
      "Line": "Hat",
      "Optimization type": "Optimizasyon türü",
      "Select": "Seçiniz",
      "Powder Models": "Toz Modelleri",
      "Calibrated Powder Optimization": "Kalibre Edilmiş Toz Optimizasyonu",
      "Pre-Calibrated Powder Optimization": "Önceden Kalibre Edilmiş Toz Optimizasyonu",
      "Generalistic Optimization for any powder": "Herhangi bir toz için Genelistik Optimizasyon",
      "Select Powder Model": "Toz Modelini Seçin",
      "No Powder Models Created. To create one go to": "Oluşturulmuş Toz Modeli Yok. Bir tane oluşturmak için şu adrese gidin",
      "Optimization parameters": "Optimizasyon parametreleri",
      "Line Speed": "Hat Hızı",
      "Distance": "Mesafe",
      "Covered height": "Kapalı yükseklik",
      "Simulation width": "Simülasyon genişliği",
      "Continue": "Devam et",
      "Compare with Current Settings": "Mevcut Ayarlarla Karşılaştırın",
      "Number of active guns 1st column": "Aktif tabanca sayısı 1. sütun",
      "Please select one": "Lütfen birini seçin",
      "Number of active guns 2nd column": "Aktif tabanca sayısı 2. sütun",
      "Number of active guns 3rd column": "Aktif tabanca sayısı 3. sütun",
      "Number of active guns 4th column": "Aktif tabanca sayısı 4. sütun",
      "Number of active guns 5th column": "Aktif tabanca sayısı 5. sütun",
      "Guns movement range": "Tabancaların hareket aralığı",
      "Guns movement period time": "Tabancaların hareket süresi",
      "Guns movement speed": "Tabancaların hareket hızı",
      "Compute": "Hesaplama",
      "Optimal machine settings": "Optimum makine ayarları",
      "Simulation results": "Simülasyon sonuçları",
      "Current Settings": "Geçerli Ayarlar",
      "Overspray": "Aşırı püskürtme",
      "Optimal Settings": "Optimal Ayarlar",
      "Uniformity": "Tekdüzelik",
      "Median uniformity": "Medyan tekdüzeliği",
      "Overspray": "Aşırı püskürtme",
      "Visualize graphs": "Grafikleri görselleştirin",
      "Calculation running": "Çalışan hesaplama",
      "Uniformity comparison": "Tekdüzelik karşılaştırması",
      "Applied spray": "Uygulanan sprey",
      "Movement chart": "Hareket tablosu",
      "Applied spray animation": "Uygulamalı sprey animasyonu",
      "Current Settings Coated Area": "Mevcut Ayarlar Kaplamalı Alan",
      "Optimal Settings Coated Area": "Optimum Ayarlar Kaplanmış Alan",
      "Current Settings Guns Movement": "Mevcut Ayarlar Tabanca Hareketi",
      "Optimal Settings Guns Movement": "Optimal Ayarlar Tabanca Hareketi",
      "Current Settings Coated Area": "Mevcut Ayarlar Kaplamalı Alan",
      "Optimal Settings Coated Area": "Optimum Ayarlar Kaplanmış Alan",
      "Comments section for the generated report": "Oluşturulan rapor için yorumlar bölümü",
      "Hide Optimal Settings in the report": "Raporda Optimal Ayarları Gizle",
      "Save Edited Comments": "Düzenlenmiş Yorumları Kaydet",
      "Generate Report": "Rapor Oluştur",
      "Generating optimization report...": "Optimizasyon raporu oluşturma...",
      "To use a different optimization type create a new Optimization": "Farklı bir optimizasyon türü kullanmak için yeni bir Optimizasyon oluşturun",
      "If you want to use a different set of Powder Models, create a new Optimization.": "Farklı bir Toz Modeli seti kullanmak istiyorsanız, yeni bir Optimizasyon oluşturun.",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "Optimum ayarları hesaplamak üzere bir veya daha fazla toz modeli kullanmak için Spesifik Toz optimizasyonunu seçin. Tozdan bağımsız bir optimizasyon çözümü elde etmek için Genelistik Optimizasyon seçeneğini seçin.",
      "Select the Powder Models to be used during the computation of optimal settings.": "Optimum ayarların hesaplanması sırasında kullanılacak Toz Modellerini seçin.",
      "Line velocity value in [meters/minute] units.": "Metre/dakika] biriminde hat hızı değeri.",
      "Distance from the gun nozzle to the substrate.": "Tabanca nozülünden alt tabakaya olan mesafe.",
      "Vertical height for which the uniformity will be optimized.": "Homojenliğin optimize edileceği dikey yükseklik.",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "Sadece görselleştirme amacıyla ilgili olup, sonuçlar bölümünde gösterilen simülasyon görüntüsünün yatay genişliğini belirler.",
      "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": "Optimum ayarlarla nasıl karşılaştırıldıklarını görselleştirmek için bu bölümü Tabanca Ayarlarınızla doldurun.",
      "Clear current settings": "Geçerli ayarları temizle",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "Tabancaların ilk sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "Tabancaların ikinci sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "Tabancaların üçüncü sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "Tabancaların dördüncü sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "Tabancaların beşinci sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum",
      "Number of active guns on one side of the booth": "Kabinin bir tarafındaki aktif tabanca sayısı",
      "Activate Toggle to visualize simulation images": "Simülasyon görüntülerini görselleştirmek için Toggle özelliğini etkinleştirin",
      "Gun to gun distance": "Tabancadan tabancaya mesafe",
      "Optimal Settings Nozzle Angle": "Optimal Ayarlar Nozül Açısı",
      "Current Settings Nozzle Angle": "Geçerli Ayarlar Nozül Açısı",
      "guns": "tabancalar",
      "Powder Usage Pie Chart": "Toz Kullanımı Pasta Grafiği",
      "Optimal Settings Pie Chart": "Optimal Ayarlar Pasta Grafiği",
      "Current Settings Pie Chart": "Mevcut Ayarlar Pasta Grafiği",
      "Powder Waste": "Toz Atık",
    },
    "ZH": {
      "": "",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "由于粉末在基材上分布不均匀而多喷涂的粉末量。",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "未粘在部件上的粉末，回收系统无法回收。",
      "2nd column height offset": "第 2 栏高度偏移",
      "3rd column height offset": "第 3 栏高度偏移",
      "4th column height offset": "第 4 栏高度偏移",
      "5th column height offset": "第 5 栏高度偏移",
      "Gun to Gun distance must be smaller than": "手枪与手枪之间的距离必须小于",
      "Gun to Gun distance must be greater than": "手枪到手枪的距离必须大于",
      "Gun to gun distance cannot be 0": "手枪到手枪的距离不能为 0",
      "gun": "枪",
      "degrees": "度",
      "Gun column height offsets": "枪柱高度偏移",
      "Nozzle rotation angle": "喷嘴旋转角度",
      "The covered height, for the number of guns available, must be smaller than ": "就现有手枪数量而言，覆盖高度必须小于 ",
      "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": "手枪的移动范围不能大于生产线的最大机械范围。:",
      "Loading optimization type...": "加载优化类型...",
      "Clear & start new optimization for line": "清除并重新优化生产线",
      "Please fill the following fields: ": "请填写以下字段： ",
      "Line speed": "生产线速度",
      "Coated height": "涂层高度",
      "Powder model": "粉末型号",
      "Specific Powder Optimization": "特定粉末优化",
      "Input value out of range" : "输入值超出范围",
      "was saved successfully." : "已成功保存。",
      "Missing Required Fields" : "缺少必填字段",
      "Guns movement range cannot be 0" : "手枪移动范围不能为 0",
      "Line speed cannot be 0" : "线路速度不能为 0",
      "Line speed must be greater than" : "行进速度必须大于",
      "Line speed must be less than" : "线速度必须小于",
      "Covered height must be greater than" : "涂层高度必须大于",
      "Guns movement speed cannot be slower than" : "手枪移动速度不能低于",
      "Use a faster guns velocity or a different movement range & period time combination." : "使用更快的手枪速度或不同的移动范围和时间组合。",
      "Guns movement speed cannot be faster than" : "手枪移动速度不能快于",
      "Use a slower guns velocity or a different movement range & period time combination." : "使用较慢的手枪速度或不同的移动范围和周期组合。",
      "Guns movement period cannot be longer than" : "手枪移动时间不能长于",
      "Use a shorter guns period or a different movement range & period time combination." : "使用较短的手枪移动周期或不同的移动范围和周期组合。",
      "Guns movement period must be greater than" : "手枪移动周期必须大于",
      "No fields were changed from the previous optimization" : "与上次优化相比，没有更改字段",
      "Do you want to recompute it anyway?" : "还是要重新计算？",
      "Cancel" : "取消",
      "Yes, recompute it!" : "是的，重新计算！",
      "You need to define the Current Settings to generate a report" : "您需要定义当前设置以生成报告",
      "OK" : "确定",
      "Total guns": "手枪总数",
      "Total guns per column": "每列手枪总数",
      "Gun movement range": "手枪移动范围",
      "Gun Movement Speed": "手枪移动速度",
      "Gun Movement Period": "手枪移动周期",
      "Save Optimization to Library": "将优化保存到资料库",
      "Optimization Saved to Library": "保存到库的优化",
      "optional": "可选的",
      "Static Optimization Name": "静态优化名称",
      "New Optimization for line": "生产线的新优化",
      "Status": "状态",
      "Optimum found": "找到最佳状态",
      "Ready to find optimum": "准备查找最佳值",
      "Static Optimization details": "静态优化详细信息",
      "Name": "优化名称",
      "Line": "线路",
      "Optimization type": "优化类型",
      "Select": "选择",
      "Powder Models": "粉末模型",
      "Calibrated Powder Optimization": "校准粉末优化",
      "Pre-Calibrated Powder Optimization": "预校准粉末优化",
      "Generalistic Optimization for any powder": "针对任何粉末的通用优化",
      "Select Powder Model": "选择粉末模型",
      "No Powder Models Created. To create one go to": "未创建粉末模型。要创建粉末模型，请转到",
      "Optimization parameters": "优化参数",
      "Line Speed": "线速度",
      "Distance": "距离",
      "Covered height": "覆盖高度",
      "Simulation width": "模拟宽度",
      "Continue": "继续",
      "Compare with Current Settings": "与当前设置比较",
      "Number of active guns 1st column": "活动手枪数量 第 1 栏",
      "Please select one": "请选择一个",
      "Number of active guns 2nd column": "活动手枪数量 第 2 栏",
      "Number of active guns 3rd column": "活动手枪数量 第三栏",
      "Number of active guns 4th column": "现役手枪数量 第 4 栏",
      "Number of active guns 5th column": "现役手枪数量 第 5 栏",
      "Guns movement range": "手枪移动范围",
      "Guns movement period time": "手枪移动时间",
      "Guns movement speed": "手枪移动速度",
      "Compute": "计算",
      "Optimal machine settings": "最佳机器设置",
      "Simulation results": "模拟结果",
      "Current Settings": "当前设置",
      "Overspray": "过喷",
      "Optimal Settings": "最佳设置",
      "Visualize graphs": "可视化图表",
      "Calculation running": "计算运行",
      "Uniformity comparison": "均匀度比较",
      "Median uniformity": "中位均匀性",
      "Applied spray": "应用喷雾",
      "Movement chart": "运动图表",
      "Applied spray animation": "喷涂动画",
      "Current Settings Coated Area": "当前设置 涂层面积",
      "Optimal Settings Coated Area": "最佳设置喷涂面积",
      "Current Settings Guns Movement": "当前设置手枪移动",
      "Optimal Settings Guns Movement": "最佳设置手枪移动",
      "Current Settings Coated Area": "当前设置涂装区域",
      "Optimal Settings Coated Area": "最佳设置涂层区域",
      "Comments section for the generated report": "生成报告的评论部分",
      "Hide Optimal Settings in the report": "在报告中隐藏最佳设置",
      "Save Edited Comments": "保存已编辑的注释",
      "Generate Report": "生成报告",
      "Generating optimization report...": "生成优化报告...",
      "To use a different optimization type create a new Optimization": "要使用不同的优化类型，请创建新优化",
      "If you want to use a different set of Powder Models, create a new Optimization.": "如果要使用一组不同的粉末模型，请创建一个新的优化。",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "选择特定粉末优化，使用一个或多个粉末模型来计算最佳设置。选择通用优化（Generalistic Optimization）选项，获得与粉末无关的优化解决方案。",
      "Select the Powder Models to be used during the computation of optimal settings.": "选择计算优化设置时要使用的粉末模型。",
      "Line velocity value in [meters/minute] units.": "线速度值，单位为 [米/分钟]。",
      "Distance from the gun nozzle to the substrate.": "喷枪喷嘴到基底的距离。",
      "Vertical height for which the uniformity will be optimized.": "将优化均匀性的垂直高度。",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "仅用于可视化目的，决定显示在结果部分的模拟图像的水平宽度。",
      "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": "在此部分填写您的喷枪设置，以便直观地显示它们与最佳设置的比较。",
      "Clear current settings": "清除当前设置",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "第一列手枪中的活动手枪数量。最小值为 0，最大值为",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "第二列手枪的活动手枪数量。最小值为 0，最大值为",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "第三列已激活手枪的数量。最小值为 0，最大值为",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "第四列手枪中的现役手枪数量。最小值为 0，最大值为",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "第五列手枪中的现役手枪数量。最小值为 0，最大值为",
      "Number of active guns on one side of the booth": "展台一侧的活动手枪数量",
      "Activate Toggle to visualize simulation images": "激活 切换 可视化模拟图像",
      "Gun to gun distance": "手枪到手枪的距离",
      "Optimal Settings Nozzle Angle": "最佳设置喷嘴角度",
      "Current Settings Nozzle Angle": "当前设置喷嘴角度",
      "guns": "枪",
      "Powder Usage Pie Chart": "粉末用量饼图",
      "Optimal Settings Pie Chart": "最佳设置饼图",
      "Current Settings Pie Chart": "当前设置饼图",
      "Powder Waste": "粉末废料",
    }
  }
</i18n>
