<template>
  <div class="py-5 container-fluid">
    <div id="topinfo">
      <div class="row mt-4 justify-content-center">
        <div class="card">
          <div class="card-header mb-4 text-center">
            <h4 class="font-weight-bolder">Powder Output</h4>
          </div>
          <div class="card-body" style="padding-top: 0.5rem">
            <div class="row">
              <div class="row mb-3">
                <h5>Powder Amount Setting</h5>
                <div class="row mb-3">
                  <div class="col-3">
                    <label> Min </label>
                    <input
                      v-model="minPowderOutput"
                      type="number"
                      class="form-control my-2"
                      placeholder="MIN"
                      @keydown="numericOnly"
                      @change="saveFormProgress"
                    />
                  </div>
                  <div class="col-3">
                    <label> Max </label>
                    <input
                      v-model="maxPowderOutput"
                      type="number"
                      class="form-control my-2"
                      placeholder="MAX"
                      @keydown="numericOnly"
                      @change="saveFormProgress"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <h5 class="text-start">Powder Output Measurement</h5>
                <div style="overflow-x: auto">
                  <div class="table-responsive">
                    <table class="table table-sm text-start" style="border-width: 0 !important">
                      <thead>
                        <tr>
                          <th>Gun</th>
                          <th v-for="(pair, index) in maxPairs" :key="'header-' + index">
                            Setting {{ index + 1 }} Weight {{ index + 1 }}
                          </th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(gun, gunIndex) in powderoutput_measurements" :key="gunIndex">
                          <td>{{ gunIndex + 1 }}</td>
                          <td v-for="(pair, pairIndex) in maxPairs" :key="'pair-' + gunIndex + '-' + pairIndex">
                            <div v-if="gun.gun_measurements[pairIndex]" class="d-flex">
                              <input
                                v-model="gun.gun_measurements[pairIndex].setting"
                                type="number"
                                class="form-control form-control-sm me-1"
                                placeholder="Setting"
                                style="width: 60px"
                                @change="saveFormProgress"
                              />
                              <input
                                v-model="gun.gun_measurements[pairIndex].weight"
                                type="number"
                                class="form-control form-control-sm me-1"
                                placeholder="Weight"
                                style="width: 60px"
                                @change="saveFormProgress"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="d-flex">
                              <button class="btn btn-success" @click="addPair(gunIndex)">Add</button>
                              <div class="col-1"></div>
                              <button
                                v-if="gun.gun_measurements.length > 2"
                                class="btn btn-danger"
                                @click="deletePair(gunIndex)"
                              >
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                        <td :colspan="maxPairs.length + 2">
                          <div class="text-center mt-0 mb-0">
                            <button class="btn btn-primary" @click="showPowderOutputGraph">
                              Show Powder Output Chart
                            </button>
                          </div>
                        </td>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <powder-output-chart title="Powder Output Chart" />
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <h5 class="text-start">Powder Amount Setting</h5>
                <div class="table-responsive"></div>
                <table class="table table-border table-bordered">
                  <thead>
                    <tr>
                      <th>
                        Expected throughput [{{
                          $store.state.units_system[$store.state.user_data.unit_system].grams
                        }}/min]
                      </th>
                      <th>Powder Amount Parameters</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="mt-2 col-6 mb-2">
                          <input
                            v-model="gunExpectedOutput"
                            :class="gunExpectedOutput < 0 ? 'form-control is-invalid' : 'form-control'"
                            type="number"
                            min="0"
                            :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                            :disabled="false"
                            @change="getPowderAmountParametersFromExpectedOutput()"
                            @blur="saveFormProgress()"
                          />
                        </div>
                      </td>
                      <td>
                        <div v-for="(n, index) in received_data.total_pistols" :key="n" class="row mb-3">
                          <div class="text-start">
                            <label class="mt-2 mb-2"> Powder Amount Parameter Gun {{ index + 1 }} </label>
                            <div class="col-3 mt-2 mb-2">
                              <input
                                v-model="powder_amount_parameters[index]"
                                :class="
                                  powder_amount_parameters[index] > maxPowderOutput ||
                                  powder_amount_parameters[index] < minPowderOutput
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                                "
                                type="number"
                                :min="minPowderOutput"
                                :max="maxPowderOutput"
                                :step="maxPowderOutput > 10 ? 1 : 0.1"
                                @change="getPowderAmountParametersFromParameter(n - 1, powder_amount_parameters[n - 1])"
                                @blur="saveFormProgress()"
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-lg-6">
                <h5>
                  Thickness Measurements [{{
                    this.$store.state.units_system[$store.state.user_data.unit_system].thickness
                  }}]
                </h5>
                <div class="table-responsive">
                  <table class="table table-sm text-center text-xs">
                    <thead>
                      <tr>
                        <th>Measurement</th>
                        <th>Thickness</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(_, index) in thickness_measurements" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>
                          <input
                            v-model="thickness_measurements[index]"
                            type="number"
                            class="form-control text-center"
                            style="width: 50%; margin: 0 auto"
                            min="0"
                            @change="
                              checkLastRow(index);
                              saveFormProgress();
                            "
                            @keydown="numericOnly"
                          />
                        </td>
                      </tr>
                      <td colspan="2">
                          <div class="text-center mt-0 mb-0">
                            <button class="btn btn-primary" @click="showThicknessGraph()">
                              Show thickness distribution chart
                            </button>
                        </div>
                      </td>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-lg-6">
                <gaussian-chart title="Thickness Distribution Chart" chart-name="gaussianPowderOutputChart" />
                <div class="row justify-content-between mt-2">
                  <div v-if="showStatistics(thickness_measurements)" class="card col text-center m-1 mt-2 ">
                    <h6 class="font-weight-bolder">Powder Output Optimization</h6>
                    <h6>Mean: {{ getThicknessMeasurementsMean(thickness_measurements) }}</h6>
                    <h6>Std: {{ getThicknessMeasurementsStd(thickness_measurements) }}</h6>
                    <h6>Min: {{ measurementsMin(thickness_measurements) }}</h6>
                    <h6>Max: {{ measurementsMax(thickness_measurements) }}</h6>
                  </div>
                  <div v-if="showStatistics(received_data.measurements)" class="card col text-center m-1 mt-2">
                    <h6 class="font-weight-bolder">Initial Benchmark</h6>
                    <h6>Mean: {{ getThicknessMeasurementsMean(received_data.measurements) }}</h6>
                    <h6>Std: {{ getThicknessMeasurementsStd(received_data.measurements) }}</h6>
                    <h6>Min: {{ measurementsMin(received_data.measurements) }}</h6>
                    <h6>Max: {{ measurementsMax(received_data.measurements) }}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-center mt-4 mb-4">
              <button
                type="button"
                class="mt-4 mb-2 text-center btn"
                :class="valuesUpdated ? 'bg-gradient-success' : 'bg-gradient-secondary'"
                style="width: 90%"
                @click="savePowderOutputData"
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <stepper-line-visit :line-id="lineId" :visit="visit" />
      </div>
    </div>
  </div>
</template>

<script>
import StepperLineVisit from "./components/StepperLineVisit.vue";
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import {
  calculateMu,
  calculateSigma,
  gaussian,
  generateX,
  generateXforMultipleMuSigma,
  generateY,
  generateYwithXvalues,
} from "./utils/gaussianchart";

import GaussianChart from "./components/GaussianChart.vue";
import eventBus from "./utils/eventBus";
import {
  useGetVisit,
  useGetBenchmark,
  getThicknessMeasurementsMean,
  getThicknessMeasurementsStd,
  measurementsMax,
  measurementsMin,
  numericOnly,
  showStatistics,
} from "./utils/utils";
import PowderOutputChart from './components/PowderOutputChart.vue';
import { useLineData } from "@/views/composables.js";

export default {
  name: "PowderOutput",
  components: {
    StepperLineVisit,
    GaussianChart,
    PowderOutputChart,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    visitId: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { line, getLine } = useLineData();
    const { visit, getVisit } = useGetVisit();
    const { received_data, getBenchmarkData } = useGetBenchmark();
    return { visit, getVisit, received_data, getBenchmarkData, line, getLine };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;

    return {
      powderoutput_measurements: [
        {
          gun_measurements: [
            {
              setting: "",
              weight: "",
            },
            {
              setting: "",
              weight: "",
            },
          ],
        },
      ],
      minPowderOutput: null,
      maxPowderOutput: null,
      thickness_measurements: [null, null, null, null, null],
      desired_output: "",

      received_powderoutput_data: {
        min_powder_output: null,
        max_powder_output: null,
        powderoutput_measurements: null,
        thickness_measurements: Array(5).fill(null),
        desired_output: null,
      },

      max_metric_decimals: 0,
      max_imperial_decimals: 2,

      // Powder Amount Calibration variables
      powder_amount_calibrations: [],
      powder_amount_calibration_measures: null,
      reference_gun_index: null,
      selected_guns_for_powder_amount: [],
      expected_powder_per_minute: null,
      powder_amount_parameters: [],
      selected_powder_amount_calibration: null,
      dropdownVisible: false,
      searchQuery: "",
    };
  },
  computed: {
    valuesUpdated() {
      const isThicknessNotEqual =
        JSON.stringify(this.thickness_measurements) !==
        JSON.stringify(this.received_powderoutput_data.thickness_measurements);
      const isPowderOutputNotEqual =
        JSON.stringify(this.powderoutput_measurements) !==
        JSON.stringify(this.received_powderoutput_data.powderoutput_measurements);
      const isDesiredOutputNotEqual =
        this.expected_powder_per_minute !== this.received_powderoutput_data.desired_output;
      const isMaxPowderOutputNotEqual = this.maxPowderOutput !== this.received_powderoutput_data.max_powder_output;
      const isMinPowderOutputNotEqual = this.minPowderOutput !== this.received_powderoutput_data.min_powder_output;

      return (
        isThicknessNotEqual ||
        isPowderOutputNotEqual ||
        isDesiredOutputNotEqual ||
        isMaxPowderOutputNotEqual ||
        isMinPowderOutputNotEqual
      );
    },
    gunExpectedOutput: {
      get() {
        if (this.expected_powder_per_minute == null || this.expected_powder_per_minute == "") {
          return this.expected_powder_per_minute;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.expected_powder_per_minute * this.$store.state.conversion_factors.g_to_oz).toFixed(2),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.expected_powder_per_minute.toFixed(0));
        } else {
          return this.expected_powder_per_minute;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.expected_powder_per_minute = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.expected_powder_per_minute = value / this.$store.state.conversion_factors.g_to_oz;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.expected_powder_per_minute = value;
          }
        }
      },
    },
    maxPairs() {
      let maxLength = 0;
      this.powderoutput_measurements.forEach(gun => {
        if (gun.gun_measurements.length > maxLength) {
          maxLength = gun.gun_measurements.length;
        }
      });
      return Array.from({ length: maxLength });
    },
    selectedPowderAmountCalibrationName() {
      if (this.selected_powder_amount_calibration === null) {
        return this.$t("Please select one");
      }

      const selectedPowderAmountCalibration = this.powder_amount_calibrations.find(
        model => model.id === this.selected_powder_amount_calibration,
      );

      return selectedPowderAmountCalibration.name;
    },
    filteredPowderAmountCalibrations() {
      return this.powder_amount_calibrations.filter(powder_amount_calibration =>
        powder_amount_calibration.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
      );
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    this.getData();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    generateX,
    gaussian,
    generateY,
    calculateMu,
    calculateSigma,
    generateXforMultipleMuSigma,
    generateYwithXvalues,
    getThicknessMeasurementsMean,
    getThicknessMeasurementsStd,
    measurementsMin,
    measurementsMax,
    showStatistics,
    numericOnly,
    async getData() {
      await this.getVisit(this.visitId);
      await this.getLine(this.lineId);
      await this.getBenchmarkData(this.visitId, this.line.total_pistols[0]);

      this.received_data.total_pistols = this.line.total_pistols[0];
      this.powderoutput_measurements = this.generateGuns(this.received_data.total_pistols);

      await this.getPowderOutputData();
      this.thicknessMeasurementsUpdateChartData();
    },
    generateGuns(total_guns) {
      return Array.from({ length: total_guns }, () => ({
        gun_measurements: [
          {
            setting: "",
            weight: "",
          },
          {
            setting: "",
            weight: "",
          },
        ],
      }));
    },
    toggleDropdown() {
      if (this.powder_amount_calibrations.length == 1) {
        return;
      }
      this.dropdownVisible = !this.dropdownVisible;
    },
    generateMeasurementList(powderoutput_measurements) {
      const results = [];

      powderoutput_measurements.map(gun => {
        const validPairs = gun.gun_measurements.filter(pair => {
          return pair.setting !== "" && pair.weight !== "";
        });

        const result = {
          powderAmount: validPairs.map(pair => parseFloat(pair.setting)),
          emptyBagWeight: Array(validPairs.length).fill(0),
          fullBagWeight: validPairs.map(pair => parseFloat(pair.weight)),
        };

        if (result.powderAmount.length > 0 || result.fullBagWeight.length > 0) {
          results.push(result);
        }
      });

      return JSON.stringify(results);
    },
    async getPowderOutputChartDataset() {
      let body = {
        min_powder_output: this.minPowderOutput,
        max_powder_output: this.maxPowderOutput,
        powderoutput_measurements: JSON.stringify(this.powderoutput_measurements),
      };

      let response = await axios.post("/api/v1/onsite/powderoutputchart/", body);

      this.$store.state.powderOutputChart = response.data.powderoutput_measurements;
    },
    getPowderAmountParametersFromParameter(gun_index, powder_amount_parameter) {
      if (powder_amount_parameter == null || powder_amount_parameter == "") {
        this.clearPowderAmountParameters();
        return;
      }

      if (powder_amount_parameter < this.minPowderOutput || powder_amount_parameter > this.maxPowderOutput) {
        this.powder_amount_parameters[gun_index] = null;
        this.$swal({
          title: this.$t("Invalid Powder Amount Parameter input"),
          text:
            this.$t("The powder amount parameter is out of range.") +
            " " +
            "Min: " +
            this.minPowderOutput +
            ", " +
            "Max: " +
            this.maxPowderOutput,
          icon: "error",
          confirmButtonText: "OK",
        });
      }

      let body = {
        line: this.lineId,
        time_interval: 60,
        measures_list: this.generateMeasurementList(this.powderoutput_measurements),
        powder_per_minute: null,
        gun_idx: gun_index,
        powder_amount_param: powder_amount_parameter,
      };

      axios.post("/api/v1/fp/computepowderamountcalibration/", body).then(response => {
        this.powder_amount_parameters = response.data.powder_amount_params;
        this.roundPowderAmountParameters();
        this.checkPowderAmountResultsRangeAlert();
        this.expected_powder_per_minute = parseFloat(response.data.powder_per_minute.toFixed(1));
        this.checkPowderThroughputAlert();
        this.saveFormProgress();
      });
    },
    roundPowderAmountParameters() {
      this.powder_amount_parameters = this.powder_amount_parameters.map(param => {
        return parseFloat(param.toFixed(1));
      });
    },
    clearPowderAmountParameters() {
      this.powder_amount_parameters = Array.from({ length: this.received_data.total_pistols }, () => null);
      this.expected_powder_per_minute = null;
    },
    checkPowderThroughputAlert() {
      if (this.expected_powder_per_minute <= 0) {
        this.$swal({
          title: "Invalid Expected Throughput",
          text: "The expected throughput must be greater than 0.",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.clearPowderAmountParameters();
      }
    },
    async getPowderAmountParametersFromExpectedOutput() {
      if (
        this.expected_powder_per_minute == null ||
        this.expected_powder_per_minute == "" ||
        this.expected_powder_per_minute <= 0
      ) {
        if (this.expected_powder_per_minute <= 0) {
          this.$swal({
            title: "Invalid Expected Throughput",
            text: "The expected throughput must be greater than 0.",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.expected_powder_per_minute = null;
        }
        return;
      }
      let body = {
        line: this.lineId,
        time_interval: 60,
        measures_list: this.generateMeasurementList(this.powderoutput_measurements),
        powder_per_minute: this.expected_powder_per_minute,
        gun_idx: null,
        powder_amount_param: null,
      };

      let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);

      this.powder_amount_parameters = response.data.powder_amount_params;

      this.checkPowderAmountResultsRangeAlert();
    },
    checkPowderAmountResultsRangeAlert() {
      let calibration_out_of_range_popup_shown = false;
      for (let index = 0; index < this.powder_amount_parameters.length; index++) {
        if (
          this.powder_amount_parameters[index] !== null
          && this.powder_amount_parameters[index] !== ""
          && (this.powder_amount_parameters[index] < this.minPowderOutput || this.powder_amount_parameters[index] > this.maxPowderOutput)
        ) {
          if (!calibration_out_of_range_popup_shown) {
            this.$swal({
              title: "Calibration Out of Range",
              text: `${"The resulting Powder Amount setting is out of range for Gun"} ${
                index + 1
              }.\n Min: ${this.minPowderOutput}, Max: ${this.maxPowderOutput}.
              ${"Obtained Parameter"}: ${this.powder_amount_parameters[index]}`,
              icon: "error",
              confirmButtonText: "OK",
            });
            calibration_out_of_range_popup_shown = true;
          }
        }
      }
    },
    async getPowderOutputData() {
      try {
        let response = await axios.get("/api/v1/onsite/powderoutput/" + this.visitId);

        if (response.data !== null) {
          this.received_powderoutput_data.min_powder_output = response.data.min_powder_output;
          this.received_powderoutput_data.max_powder_output = response.data.max_powder_output;

          this.received_powderoutput_data.powderoutput_measurements = JSON.parse(
            response.data.powder_amount_measurements,
          );

          this.received_powderoutput_data.thickness_measurements = JSON.parse(response.data.thickness_measurements);

          this.received_powderoutput_data.desired_output = response.data.desired_output;

          // update local state with received data
          this.minPowderOutput = response.data.min_powder_output;
          this.maxPowderOutput = response.data.max_powder_output;

          // fix expected_powder_per_minute rename?
          if (response.data.desired_output !== null) {
            this.expected_powder_per_minute = response.data.desired_output;

            try {
              let body = {
                line: this.lineId,
                time_interval: 60,
                measures_list: this.generateMeasurementList(this.received_powderoutput_data.powderoutput_measurements),
                powder_per_minute: this.expected_powder_per_minute,
                gun_idx: null,
                powder_amount_param: null,
              };

              let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);
              this.powder_amount_parameters = response.data.powder_amount_params;
              this.roundPowderAmountParameters();
            } catch (error) {
              console.error(error);
            }
          }
          if (response.data.powder_amount_measurements !== null) {
            this.powderoutput_measurements = JSON.parse(response.data.powder_amount_measurements);
          }
          if (response.data.thickness_measurements !== null) {
            this.thickness_measurements = JSON.parse(response.data.thickness_measurements);
          }
        }

        this.received_data = JSON.parse(JSON.stringify(this.received_data));

        if (this.received_powderoutput_data.thickness_measurements !== null) {
          this.showThicknessGraph();
        }

        if (response.data.powder_amount_measurements !== null && this.isPowderOutputMeasurementsFilled()) {
          this.showPowderOutputGraph();
        }
      } catch (error) {
        console.error(error);
      }
      this.$store.state.isLoaded = false;
    },
    isPowderOutputMeasurementsFilled() {
      let total_empty_measures = this.powderoutput_measurements
        .map(powderoutput_measurement => powderoutput_measurement.gun_measurements)
        .filter(gun_measurment =>
          gun_measurment.some(
            gun_measurment =>
              gun_measurment.setting == "" ||
              gun_measurment.setting == null ||
              gun_measurment.weight == "" ||
              gun_measurment.weight == null,
          ),
        ).length;

      let is_powder_output_measurements_filled = total_empty_measures == 0;

      return is_powder_output_measurements_filled;
    },
    getFormProgressStatus() {
      const PROGRESS_STATUS_PENDING = "Pending";
      const PROGRESS_STATUS_IN_PROGRESS = "In Progress";
      const PROGRESS_STATUS_COMPLETED = "Completed";

      if (
        this.powderoutput_measurements
          .map(gun => gun.gun_measurements)
          .filter(gun_measurment =>
            gun_measurment.some(gun_measurment => gun_measurment.setting !== "" && gun_measurment.weight !== ""),
          ) &&
        this.thickness_measurements.filter(m => m !== null && m !== "") &&
        this.expected_powder_per_minute !== null
      ) {
        return PROGRESS_STATUS_COMPLETED;
      } else if (
        this.powderoutput_measurements
          .map(gun => gun.gun_measurements)
          .filter(gun_measurment =>
            gun_measurment.some(gun_measurment => gun_measurment.setting === "" && gun_measurment.weight === ""),
          ) ||
        this.thickness_measurements.filter(m => m === null && m === "") ||
        this.expected_powder_per_minute === null
      ) {
        return PROGRESS_STATUS_IN_PROGRESS;
      } else {
        return PROGRESS_STATUS_PENDING;
      }
    },
    saveFormProgress() {
      const formData = {
        powder_amount_measurements: JSON.stringify(this.powderoutput_measurements),
        thickness_measurements: JSON.stringify(this.thickness_measurements),
        desired_output: this.expected_powder_per_minute,
        min_powder_output: this.minPowderOutput ? this.minPowderOutput : null,
        max_powder_output: this.maxPowderOutput ? this.maxPowderOutput : null,
        progress_status: this.getFormProgressStatus(),
      };

      try {
        axios.patch("/api/v1/onsite/powderoutput/" + this.visitId + "/", formData);
      } catch (error) {
        console.error(error);
      }
    },
    async savePowderOutputData() {
      if (
        this.powderoutput_measurements.some(gun =>
          gun.gun_measurements.some(pair => pair.setting !== "" && pair.setting < this.minPowderOutput),
        )
      ) {
        this.$swal({
          title: "Invalid Powder Output Setting Range",
          text: "Setting must be greater than the minimum allowed powder amount setting.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else if (
        this.powderoutput_measurements.some(gun =>
          gun.gun_measurements.some(pair => pair.setting !== "" && pair.setting > this.maxPowderOutput),
        )
      ) {
        this.$swal({
          title: "Invalid Powder Output Setting Range",
          text: "Setting must be less than the maximum allowed powder amount setting.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        const formData = {
          powder_amount_measurements: JSON.stringify(this.powderoutput_measurements),
          thickness_measurements: JSON.stringify(this.thickness_measurements),
          desired_output: this.expected_powder_per_minute,
          min_powder_output: this.minPowderOutput,
          max_powder_output: this.maxPowderOutput,
          progress_status: this.getFormProgressStatus(),
        };
        try {
          await axios.patch("/api/v1/onsite/powderoutput/" + this.visitId + "/", formData);
          await this.getVisit(this.visitId);

          await this.$swal({
            title: "Changes saved",
            text: "Powder Output was saved successfully",
            icon: "success",
            confirmButtonText: "OK",
          })
          await this.getBenchmarkData(this.visitId, this.line.total_pistols);
        } catch (error) {
          console.error(error);
        }
      }
    },
    checkLastRow(index) {
      let notEmptyMeasurements = this.thickness_measurements.filter(m => m !== null && m !== "");
      if (
        index === this.thickness_measurements.length - 1 &&
        this.thickness_measurements[index] !== "" &&
        notEmptyMeasurements.length === this.thickness_measurements.length
      ) {
        this.thickness_measurements.push("");
      }
    },
    thicknessMeasurementsUpdateChartData() {
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      this.$store.state.gaussianPowderOutputChart.labels  = this.generateX(mu, sigma);


      const arr =  this.$store.state.gaussianPowderOutputChart.labels.map(num => parseFloat(num));

      let index = arr.findIndex(num => num > this.received_data.minimum_target_thickness);
      let index_max = arr.findIndex(num => num > this.received_data.maximum_target_thickness);

      this.$store.state.minTargetThickness = index > 0 ? index - 1 : index;
      this.$store.state.maxTargetThickness = index_max > 0 ? index_max - 1 : arr.length - 1; // 

      this.$store.state.gaussianPowderOutputChart.datasets[0] = {
        label: "Powder Output Optimization",
        data: this.generateY(sigma, mu),
      };

      if (this.received_data.measurements !== null) {
        const benchmarkMu = this.calculateMu(this.received_data.measurements);
        const benchmarkSigma = this.calculateSigma(this.received_data.measurements, benchmarkMu);

        this.$store.state.gaussianPowderOutputChart.labels = this.generateXforMultipleMuSigma([
          [mu, sigma],
          [benchmarkMu, benchmarkSigma],
        ]);

        this.$store.state.gaussianPowderOutputChart.datasets[0] = {
          label: "Powder Output Optimization",
          data: this.generateYwithXvalues(sigma, mu, this.$store.state.gaussianPowderOutputChart.labels),
        };

        this.$store.state.gaussianPowderOutputChart.datasets[1] = {
          label: "Initial Benchmark",
          data: this.generateYwithXvalues(
            benchmarkSigma,
            benchmarkMu,
            this.$store.state.gaussianPowderOutputChart.labels,
          ),
        };
      }
    },
    addPair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.push({ setting: "", weight: "" });
    },
    deletePair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.pop();
    },
    async showPowderOutputGraph() {
      if (this.isPowderOutputMeasurementsFilled()) {
        await this.getPowderOutputChartDataset();

        eventBus.emit('draw-powderoutput-chart');
      }
    },
    showThicknessGraph() {
      this.thicknessMeasurementsUpdateChartData();
      eventBus.emit('draw-gaussian-chart');
    },
  },
};
</script>
<style scoped>
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-border tbody tr:last-child td {
  border-width: 1px;
}

.container-fluid {
  padding-top: 20px;
}
</style>
